import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationModalOptions } from '../../../interfaces/notification-modal-options';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent {
  @Input() options: NotificationModalOptions;

  errorCollapsed: boolean = true;

  constructor(private activeModal: NgbActiveModal) { }

  abort(): void {
    this.activeModal.close(false);
  }

  confirm(): void {
    this.activeModal.close(true);
  }

}
