import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AdminService } from '../../../services/admin.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit, OnDestroy {
  @Input() userId: number;
  @Output() onSaveUser = new EventEmitter<any>();
  @ViewChild('userForm', { static: true }) private userForm: NgForm;

  subscriptions: Object = {};

  user: any = {
    enabled: true
  };
  userRoles: Array<string> = [];

  constructor(private adminService: AdminService, private notificationService: NotificationService, public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.userId) this.getUser(this.userId);
    this.getUserRoles();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getUser(userId: number) {
    this.subscriptions['GetUser'] = this.adminService.getIndividualUser(userId).subscribe((response: any) => {
      this.user = {...response,
        authorities: response.authorities.toString()
      };
    });
  }

  getUserRoles() {
    this.subscriptions['UserRoles'] = this.adminService.getUserRoles().subscribe((response: Array<string>) => {
      this.userRoles = response;
    });
  }

  saveUser() {
    if (this.userForm.invalid) return;

    this.user.authorities = [this.user.authorities];

    if (this.user.id) {
      this.subscriptions['SaveUser'] = this.adminService.updateUser(this.user).subscribe((response: any) => {
        this.activeModal.dismiss();
        this.onSaveUser.emit();
        this.notificationService.showToast(`User with E-Mail <strong>${this.user.email}</strong> correctly updated`, { type: 'success' });
      });
    } else {
      this.subscriptions['SaveUser'] = this.adminService.createUser(this.user).subscribe((response: any) => {
        this.activeModal.dismiss();
        this.onSaveUser.emit();
        this.notificationService.showToast('User correctly created', { type: 'success' });
      });
    }
  }

}
