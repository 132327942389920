import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tutorials } from 'src/app/enums/tutorial.enum';

@Component({
  selector: 'app-intent-settings',
  templateUrl: './intent-settings.component.html',
  styleUrls: ['./intent-settings.component.scss'],
})
export class IntentSettingsComponent implements OnDestroy {
  subscriptions: Object = {};
  activeTab;
  constructor(public router: Router, private route: ActivatedRoute) {
    this.subscriptions['tutorialSubscription'] = this.route.queryParams.subscribe(({ tutorial, tab }) => {
      if (tutorial === tutorials.CREATE_INTENTS && tab) {
        this.activeTab = this.route.snapshot.queryParams.tab;
      }
    });
  }
  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }
}
