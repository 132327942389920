import { AfterViewInit, Component, OnInit, ViewChildren, QueryList, ElementRef, Input, ViewEncapsulation, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { IntentParametersService } from '../../../services/intent-parameters.service';
import { NotificationService } from '../../../services/notification.service';
import { IntentService } from '../../../services/intent.service';
import { DesignService } from '../../../services/design.service';
import { ActivatedRoute } from '@angular/router';
import { tutorials } from 'src/app/enums/tutorial.enum';
import { CreateIntnentsTutorialParametmersResponseFormService } from 'src/app/services/tutorials/create-intnents-tutorial.parametmers-response-form.service';

@Component({
  selector: 'parameters-response-form',
  templateUrl: './parameters-response-form.component.html',
  styleUrls: ['./parameters-response-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ParametersResponseFormComponent implements OnInit, AfterViewInit {
  @ViewChildren('contextInput') contextInput: QueryList<ElementRef>;
  @ViewChild('combinationDrop', { static: true }) combinationDrop: NgbDropdown;
  @ViewChild('responseDropdown', { static: false }) responseDropdown: NgbDropdown;


  
  @Input() intentResponses: any;
  @Output() onReturn = new EventEmitter<any>();

  currentResponseIndex: number;
  currentIntent: any = {};
  parametersActive: boolean = false;
  responseType: 'response' | 'event';
  parametersContexts: any = { 'param1Name': {}, 'param2Name': {}, 'param3Name': {} };
  enableCustomPayload: boolean = false;

  subscriptions: Object = {};

  responseSubmitted: boolean = false;
  
  get patternAviability(): boolean | null {
    if ( this.designService.isProductConfigured([{ type: 'ES', fulfillment: false }]) ) return null
    else return true;
  }
  get responseForm(): FormGroup { return this.intentParametersService.responseForm }
  get textResponses(): FormArray { return this.intentParametersService.textResponses }
  get graphics(): FormArray { return this.intentParametersService.graphics }
  get context(): FormArray { return this.intentParametersService.context }

  intentsSuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.intentService.getIntentsSuggestions(term).pipe(
          catchError(() => {
            return of([]);
          })          
        )
      )
    )

  intentSuggestionFormat = (value: any) => value.name;
  intentSuggestionSelect = (value: any) => value.name;

  chooseIntent($event: any) {
    this.responseForm.get('intentId').setValue($event.item.id);
  }

  constructor(
    private tutorial: CreateIntnentsTutorialParametmersResponseFormService,
    private intentService: IntentService,
    public intentParametersService: IntentParametersService,
    private notificationService: NotificationService,
    private designService: DesignService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.subscriptions['tutorialSubscription'] = this.route.queryParams.subscribe(({ tutorial }) => {
      if (tutorial === tutorials.CREATE_INTENTS) {
        this.tutorial.startTutorial(this);
      }
    })

    this.intentParametersService.initForm();
    if (this.intentResponses.length) {
      this.setEditingResponse(0);
    } else {
      this.setResponseType('response');
      this.responseForm.get('language').setValue(this.designService.sessionAgentLang);
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.resizeContextInput();
  }

  setEditingResponse(responseIndex: number) {
    this.parametersActive = (this.intentResponses[responseIndex].param1Name || this.intentResponses[responseIndex].param2Name || this.intentResponses[responseIndex].param3Name);
    this.currentResponseIndex = responseIndex;
    this.currentIntent = {...this.currentIntent, name: this.intentResponses[this.currentResponseIndex].intentName};

    this.intentParametersService.fillForm(this.intentResponses[this.currentResponseIndex]);
    this.processContextsParameters(this.responseForm, 'input');
    this.setResponseType( (this.responseForm.get('event') && this.responseForm.get('event').value) ? 'event' : 'response' );
    this.enableCustomPayload = (this.responseForm.get('payload') && this.responseForm.get('payload').value);
  }

  async changeResponse(target: 'prev' | 'next' | number, $event?) {
    this.combinationDrop.close();

    let confirmAction = await new Promise<boolean>((resolve) => {
      if (this.responseForm.dirty) {
        if ($event) $event.preventDefault();
        
        this.notificationService.openModal({
          title: 'Confirm change response',
          message: 'Are you sure to discard pending changes on this response?',
          choice: 'multi'
          })
          .subscribe((confirm: boolean) => (confirm ? resolve(true) : resolve(false)));
      } else {
        resolve(true);
      }
    });

    if (!confirmAction) return;
    
    const index = this.getResponseIndex(target);
    if (!Number.isInteger(index)) return;

    this.intentParametersService.initForm();
    this.setEditingResponse(index);
  }

  returnBack() {
    this.onReturn.emit();
  }

  getResponseIndex(target: 'prev' | 'next' | number): number {
    let indexToMove: number;
    if (target === 'prev') {
      if (this.currentResponseIndex === 0) return null;
      
      indexToMove = this.currentResponseIndex - 1;
    } else if (target === 'next') {
      if ((this.currentResponseIndex + 1) > (this.intentResponses.length - 1)) return null;

      indexToMove = this.currentResponseIndex + 1;
    } else if (Number.isInteger(target)) {
      indexToMove = target;
    }

    return indexToMove;
  }

  setResponseType(typeSelected: 'response' | 'event') {
    this.responseType = typeSelected;
    if (this.responseType === 'event') {
      this.responseForm.get('event').enable();
      this.responseForm.get('response').disable();
    } else if (this.responseType === 'response') {
      this.responseForm.get('response').enable();
      this.responseForm.get('event').disable();
    }
  }

  toggleCustomPaylod() {
    this.enableCustomPayload = !this.enableCustomPayload;
    if (!this.enableCustomPayload) {
      this.responseForm.get('payload').setValue('');
    }
  }

  toggleItarable(elementIndex: number, responseType: 'graphics' | 'text') {
    let formArray: FormArray;
    if (responseType === 'graphics') formArray = this.graphics;
    else if (responseType === 'text') formArray = this.textResponses;

    if (formArray.at(elementIndex).get('iterable').value) {
      formArray.at(elementIndex).get('iterable').setValue(false);
    } else {
      formArray.at(elementIndex).get('iterable').setValue(true);
    }
  }

  resizeContextInput($event?) {   
    setTimeout(() => {
      if ($event) {
        $event.srcElement.style.width = `${(($event.srcElement.value.length + 1) * 8) + 10}px`;
        return;
      }
      this.contextInput.toArray().forEach((input: ElementRef) => {
        input.nativeElement.style.width = `${((input.nativeElement.value.length + 1) * 8) + 10}px`;
      });
    });
  }
  processContextsParameters(responseForm: FormGroup, mode: 'input' | 'output'): any {
    if (mode === 'output') {
      for (const key in this.parametersContexts) {
        if (this.parametersContexts.hasOwnProperty(key) && this.parametersContexts[key].value) {
          responseForm.get(key).setValue(`${this.parametersContexts[key].value}.${this.responseForm.get(key).value}`);
        }
      }
      return responseForm.value;
    } else if (mode === 'input') {
      this.parametersContexts = { 'param1Name': {}, 'param2Name': {}, 'param3Name': {} };
      for (const key in this.intentResponses[this.currentResponseIndex]) {
        if (this.intentResponses[this.currentResponseIndex].hasOwnProperty(key) &&
        ['param1Name', 'param2Name', 'param3Name'].indexOf(key) !== -1 && 
        this.intentResponses[this.currentResponseIndex][key] &&
        this.intentResponses[this.currentResponseIndex][key].indexOf('.') !== -1) {
          const parameters = this.intentResponses[this.currentResponseIndex][key].split('.');
          responseForm.get(key).setValue(parameters[1]);
          this.parametersContexts[key].value = parameters[0];
          this.parametersContexts[key].active = true;
        }
      }
    }
  }

  /* SAVE RESPONSE */
  saveResponse() {
    this.responseSubmitted = true;

    if (this.responseForm.invalid) return;

    if (this.responseType === 'response') { 
      this.responseForm.removeControl('event');
    } else if (this.responseType === 'event') {
      this.responseForm.removeControl('response');
    }

    if (this.intentResponses.length && this.responseForm.get('intentId').value === this.intentResponses[this.currentResponseIndex].intentId) {
      const data = {...this.processContextsParameters(this.responseForm, 'output'), id: this.intentResponses[this.currentResponseIndex].id};

      this.subscriptions['SaveResponse'] = this.intentService.updateIntentParameters(data).subscribe((response: any) => {
        this.returnBack();
        this.notificationService.showToast('Response successful updated', { type: 'success' });
      });
    } else {
      const data = this.processContextsParameters(this.responseForm, 'output');

      this.subscriptions['SaveResponse'] = this.intentService.createIntentParameters(data).subscribe((response: any) => {
        this.returnBack();
        this.notificationService.showToast('Response successful created', { type: 'success' });

        if (this.intentResponses.length && this.responseForm.get('intentId').value !== this.intentResponses[this.currentResponseIndex].intentId) {
          this.deleteResponse(this.intentResponses[this.currentResponseIndex].id, false);
        }
      });
    }
  }

  /* DELETE RESPONSE */
  async deleteResponse(id: number, confirmModal: boolean) {
    let confirmAction = await new Promise<boolean>((resolve) => {
      if (confirmModal) {
        this.notificationService.openModal({
          title: 'Confirm delete response',
          message: 'Are you sure to delete this response?',
          choice: 'multi'
        }).subscribe((confirm: boolean) => (confirm ? resolve(true) : resolve(false)));
      } else {
        resolve(true);
      }
    });

    if (!confirmAction) return;

    this.subscriptions['DeleteResponse'] = this.intentService.deleteIntentParameters(id).subscribe((response) => {
      if (confirmModal) {
        this.returnBack();
        this.notificationService.showToast('Response successful deleted', { type: 'success' });
      }
    });
  }

  deleteAllResponses() {
    this.subscriptions['ConfirmModalRemove'] = this.notificationService
      .openModal({
        title: 'Confirm delete responses',
        message: `Are you sure to delete all the current responses?`,
        choice: 'multi',
      })
      .subscribe((confirm: boolean) => {
        if (!confirm) return;

         
        this.subscriptions['DeleteResponse'] = this.intentService.deleteAllResponses(this.intentResponses[this.currentResponseIndex].intentId, this.designService.sessionAgentLang)
        .subscribe(() => this.successCallback('Response successful deleted'));
        
      });
  }

  private successCallback(textFeedback: string) {
    this.responseSubmitted = false;
    this.returnBack();
    this.notificationService.showToast(textFeedback, { type: 'success' });
  }

}
