import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { DesignService } from '../services/design.service';

const interceptHandlers: Array<string> = [
    `${environment.API.orchestrator_configurator}/intents`,
    `${environment.API.orchestrator_configurator}/intents/parameters`,
    `${environment.API.orchestrator_configurator}/intents/flow`,
    `${environment.API.orchestrator_analysis}/conversations/view`,
    `${environment.API.orchestrator_analysis}/messages/view/history`,
    `${environment.API.orchestrator_analysis}/health/intent`,
    `${environment.API.orchestrator_analysis}/health/entity`,
    `${environment.API.orchestrator_analysis}/health/agent`,
    `${environment.API.orchestrator_analysis}/analytics/graphics/trend/fallbacks/total`,
    `${environment.API.orchestrator_analysis}/analytics/graphics/percente/fallback/messages`,
    `${environment.API.orchestrator_analysis}/analytics/graphics/requestsForAnOperator`,
    `${environment.API.orchestrator_analysis}/analytics/graphics/trend/intents/total`,
    `${environment.API.orchestrator_analysis}/analytics/graphics/agentAutonomy`,
    `${environment.API.orchestrator_analysis}/analytics/graphics/trustedIntents`,
    `${environment.API.orchestrator_analysis}/analytics/graphics/media/dialogflow/confidence`,
    `${environment.API.orchestrator_analysis}/analytics/average/feedback`,
    `${environment.API.orchestrator_analysis}/analytics/percent/precision`,
    `${environment.API.orchestrator_analysis}/analytics/percent/recall`,
    `${environment.API.orchestrator_analysis}/analytics/graphics/precisionAndRecall`,
    `${environment.API.orchestrator_analysis}/analytics/graphics/precisionAndRecallForTable`,
    `${environment.API.orchestrator_configurator}/entities/getAll`,
    `${environment.API.orchestrator_configurator}/regression`

];

@Injectable()
export class AgentInterceptor implements HttpInterceptor {

    constructor(private designService: DesignService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if ( interceptHandlers.indexOf(req.url) !== -1 && req.method === 'GET' && !req.params.has('agentLang') && this.designService.sessionAgentLang !== 'all' ) {
            return next.handle(
                req.clone({
                    params: req.params.set('agentLang', this.designService.sessionAgentLang)
                })
            );
        }

        return next.handle(req);
    }
}
