import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Subscription } from 'rxjs';

import { Languages } from '../../../enums/languages.enum';

import { DesignService } from '../../../services/design.service';

@Component({
  selector: 'agent-languages',
  templateUrl: './agent-languages.component.html',
  styleUrls: ['./agent-languages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgentLanguagesComponent implements OnInit, OnDestroy {
  @Input() position?: string = 'left';
  @Input() layoutClass?: string;
  @Input() allLanguages?: boolean = false;

  agentSubscription: Subscription;
  
  activeAgent: any = {};

  get languages() { return Languages }

  constructor(public designService: DesignService) { }

  ngOnInit() {
    this.agentSubscription = this.designService.getSessionAgent().subscribe((response: any) => {
      this.activeAgent = response;
    });
  }

  ngOnDestroy() {
    this.agentSubscription.unsubscribe();
  }

  changeAgentLang(language: string) {
    this.designService.setSessionAgentLang(language);
  }

}
