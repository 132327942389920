import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TestCase, TEST_STATUS_ENUM } from 'src/app/interfaces/testCase';
import { TestCaseModalComponent } from '../../modals/test-case-modal/test-case-modal.component';

@Component({
  selector: 'app-test-case',
  templateUrl: './test-case.component.html',
  styleUrls: ['./test-case.component.scss'],
})
export class TestCaseComponent{
  @Input() testCase: TestCase;
  @Output() onReturn: EventEmitter<any> = new EventEmitter();
  @Output() onRunTest: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateTest: EventEmitter<any> = new EventEmitter();

  statusEnum = TEST_STATUS_ENUM;

  subscriptions: Object = {};

  constructor(private modalService: NgbModal) {}

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  runTest() {
    this.onRunTest.emit();
  }

  testModal() {
    const modalRef = this.modalService.open(TestCaseModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.testCase = this.testCase;
    modalRef.componentInstance.onSaveTest.subscribe(({ testToRun }) => {
      this.onUpdateTest.emit();
      if (testToRun) {
        this.runTest();
      }
    });
  }

  returnBack() {
    this.onReturn.emit();
  }
}
