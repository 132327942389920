import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { BackButton, CompleteButton, ExitButton, NextButton } from 'src/app/classes/tutorials/buttons.tutorial';
import { Step } from 'src/app/classes/tutorials/step.tutorial';

@Injectable({
  providedIn: 'root',
})
export class PlatformOverviewTutorialService {
  constructor(private router: Router, private shepherdService: ShepherdService, @Inject(DOCUMENT) private document: Document) {
    this.shepherdService.modal = true;
    this.shepherdService.defaultStepOptions = { scrollTo: true };
  }

  startTutorial() {
    if (this.shepherdService.isActive) {
      this.shepherdService.complete();
    }
    this.shepherdService.addSteps([
      new Step({
        padding: 5,
        title: `Platform overview`,
        text: 'Welcome to Tellya, a general tour of the platform will be presented below.',
        buttons: [new ExitButton(), new NextButton()],
      }),
      new Step({
        attachTo: {
          element: '#homeMenu',
          on: 'right',
        },
        beforeShowPromise: this.navigateTo('/dashboard'),
        title: `Home`,
        text: `This is the "Home" section, inside you will find general information on the agent's progress, costs and the status of the platform. From the Home you can also perform the Tone of Voice test.`,
      }),
      new Step({
        attachTo: {
          element: '#intentsDesignMenu',
          on: 'right',
        },
        beforeShowPromise: this.navigateTo('/conversation-flow'),
        title: `Intent design`,
        text: `The Intent Design section provides a workspace that allows you to view and modify the flow of the NLU engine and the intents that compose it.`,
        showOn: this.checkElementExistance('#intentsDesignMenu'),
      }),
      new Step({
        attachTo: {
          element: '#intentsSettingsMenu',
          on: 'right',
        },
        beforeShowPromise: this.navigateTo('/intent-settings'),
        title: `Intent settings`,
        text: `The “Intent Settings” section allows you to define and configure particular behaviors associated with individual intents.`,
        showOn: this.checkElementExistance('#intentsSettingsMenu'),
      }),
      new Step({
        attachTo: {
          element: '#entitiesMenu',
          on: 'right',
        },
        beforeShowPromise: this.navigateTo('/entities'),
        title: `Entities`,
        text: `The Entities section allows you to define and configure new custom entities or modify entities already present within the NLU engine associated with the journey.`,
        showOn: this.checkElementExistance('#entitiesMenu'),
      }),
      new Step({
        attachTo: {
          element: '#processFlowsMenu',
          on: 'right',
        },
        beforeShowPromise: this.navigateTo('/design'),

        title: `Process flows`,
        text: `The Design section allows you to define and customize the agent's behavior, configuring the different types of services and technologies to be integrated within the flow.`,
        showOn: this.checkElementExistance('#processFlowsMenu'),
      }),
      new Step({
        attachTo: {
          element: '#analyticsMenu',
          on: 'right',
        },
        beforeShowPromise: this.navigateTo('/analytics'),
        title: `Analytics`,
        text: `The Analytics section allows you to analyze statistics and performance of the virtual agent in relation to the different objectives, KPIs and peculiarities of the project.`,
        showOn: this.checkElementExistance('#analyticsMenu'),
      }),
      new Step({
        attachTo: {
          element: '#trainingMenu',
          on: 'right',
        },
        beforeShowPromise: this.navigateTo('/training'),
        title: `Training`,
        text: `The section lists the real conversations that took place between users and agents and it is therefore possible to verify and correct the associations made by the NLU platform.`,
        showOn: this.checkElementExistance('#trainingMenu'),
      }),
      new Step({
        attachTo: {
          element: '#validationMenu',
          on: 'right',
        },
        beforeShowPromise: this.navigateTo('/validation'),
        title: `Validation`,
        text: `Within the section, all the problems of the NLU platform are reported. They are collected on different levels which are: agent, intent and entity.`,
        showOn: this.checkElementExistance('#validationMenu'),
      }),
      new Step({
        attachTo: {
          element: '#regressionTestMenu',
          on: 'right',
        },
        beforeShowPromise: this.navigateTo('/regression-test'),
        title: `Regression test`,
        text: `Within the section, tests can be defined that simulate conversations, useful for monitoring the quality of the bot after making changes to the agent.`,
        showOn: this.checkElementExistance('#regressionTestMenu'),
      }),
      new Step({
        attachTo: {
          element: '#monitoringMenu',
          on: 'right',
        },
        beforeShowPromise: this.navigateTo('/monitoring'),
        title: `Monitoring`,
        text: 'In this section you can check the status of the Tellya platform and its microservices. It is also possible to check the logs collected by the platform.',
        showOn: this.checkElementExistance('#monitoringMenu'),
      }),
      new Step({
        attachTo: {
          element: '#billingMenu',
          on: 'right',
        },
        beforeShowPromise: this.navigateTo('/billing'),
        title: `Billing`,
        text: 'The Billing section allows you to monitor the use of resources, services and the various APIs and components integrated into the project (eg Dialogflow, Google Natural Language).',
        showOn: this.checkElementExistance('#billingMenu'),
      }),
      new Step({
        attachTo: {
          element: '#adminMenu',
          on: 'right',
        },
        beforeShowPromise: this.navigateTo('/admin'),
        title: `Admin`,
        text: 'The Admin section allows you to control the users who have access to the platform, give them specific permissions and check their work by analyzing the system logs.',
        showOn: this.checkElementExistance('#adminMenu'),
      }),
      new Step({
        attachTo: {
          element: '#channelsMenu',
          on: 'top',
        },
        beforeShowPromise: this.navigateTo('/channels'),
        title: `Channels`,
        text: 'The Channels section allows you to quickly integrate the journeys configured within different distribution channels that support virtual agents.',
      }),
      new Step({
        attachTo: {
          element: '#marketplaceMenu',
          on: 'top',
        },
        beforeShowPromise: this.navigateTo('/marketplace'),
        title: `Market place`,
        text: 'In this section you can buy new channels, connectors and modules.',
      }),
      new Step({
        attachTo: {
          element: '#chatButton',
          on: 'bottom',
        },
        beforeShowPromise: () => {
          this.router.navigate(['/dashboard']);
          return new Promise((resolve) => {
            setTimeout(() => {
              window.scrollTo(0, 0);
              this.document.querySelector<any>('#chatButton').click();
              resolve(true);
            }, 500);
          });
        },
        title: `Test chat`,
        text: 'Clicking here opens the test chat where you can try the bot you have configured or consult the support bot.',
      }),
      new Step({
        attachTo: {
          element: '#tutorialButton',
          on: 'bottom',
        },
        beforeShowPromise: this.navigateTo('/dashboard'),
        title: `Tour`,
        text: 'You can run this tour or another at any time by clicking on this icon.',
        buttons: [new ExitButton(), new BackButton(), new CompleteButton()],
      }),
    ]);

    this.shepherdService.start();
  }

  checkElementExistance(element) {
    return () => {
      const elem = this.document.querySelector(element);
      return elem ? true : false;
    };
  }

  navigateTo(uri) {
    return () => {
      this.router.navigate([uri]);
      return new Promise(function (resolve) {
        setTimeout(function () {
          resolve(true);
        }, 500);
      });
    };
  }
}
