import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  onChangeLicense: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isLoading = false;

  private licenseItems: Object;

  constructor(private http: HttpClient) { }

  getSessionLicense(): Observable<any> {
    return this.onChangeLicense.asObservable().pipe(
      filter(license => license != null)
    );
  }

  setSessionLicense(license: any) {
    this.licenseItems = license;
    this.onChangeLicense.next(license);
  }

  getLicense(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/license`);
  }

  getLicenseItems(): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.API.orchestrator_configurator}/license/getAll`).pipe(
      tap((response: any) => {
        this.setSessionLicense(response);
        this.isLoading = false;
      })
    );
  }

  isPurchased(itemCode: string): boolean {
    return this.licenseItems['Module'].find(item => item.code === itemCode).purchased;
  }

}
