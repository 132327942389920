import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Pagination } from 'src/app/classes/pagination';
import { TableSortDirective } from 'src/app/directives/table-sort.directive';

import { Entity } from 'src/app/interfaces/entity';
import { DesignService } from 'src/app/services/design.service';
import { NotificationService } from 'src/app/services/notification.service';
import { EntityModalComponent } from '../../modals/entity-modal/entity-modal.component';
enum ENTITY_KIND_ENUM {
  KIND_REGEXP = 'Regex',
  KIND_MAP = 'List',
  KIND_LIST = 'Map',
}

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss'],
})
export class EntitiesComponent implements OnInit, OnDestroy {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;

  subscriptions: Object = {};

  entities: Array<Entity> = [];
  kindEnum = ENTITY_KIND_ENUM;
  filter: any = {};
  entitiesPagination: Pagination = new Pagination();

  constructor(private modalService: NgbModal, private designService: DesignService, private notificationService: NotificationService) {}

  ngOnInit() {
    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      if (this.subscriptions['AgentLanguage'] instanceof Subscription) this.subscriptions['AgentLanguage'].unsubscribe();

      this.subscriptions['AgentLanguage'] = this.designService.getSessionAgentLang().subscribe(() => {
        this.getEntities(1);
      });
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getEntities(pageSelected: number) {
    this.entitiesPagination.onSelectPage(pageSelected);

    const params = {
      name: this.filter.name || null,
      sortBy: this.filter.sortBy || null,
    };

    this.subscriptions['EntitiesSubscription'] = this.designService.getEntitiesList(params, this.entitiesPagination.getPageIndex(), this.entitiesPagination.pageSize).subscribe((response: any) => {
      this.entities = response.content;
      this.entitiesPagination.updateTotals(response.totalElements);
    });
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.getEntities(this.entitiesPagination.currentPage);
  }

  entityModal(entity?: Entity) {
    const modalRef = this.modalService.open(EntityModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.entity = entity || null;
    modalRef.componentInstance.onSaveEntity.subscribe(() => this.getEntities(this.entitiesPagination.currentPage));
  }

  deleteEntity(entity: Entity) {
    this.notificationService
      .openModal({
        title: 'Confirm delete entity',
        message: 'Are you sure to remove the entity?',
        choice: 'multi',
      })
      .subscribe((confirm: boolean) => {
        if (!confirm) return;
        this.subscriptions['DeleteEntity'] = this.designService.removeEntity(entity.id).subscribe(() => this.getEntities(this.entitiesPagination.currentPage));
      });
  }

  synchEntities() {
    this.notificationService
      .openModal({
        title: 'Data update',
        message: 'The data update may take few seconds, during which it will not be possible to use the application. Do you want to continue?',
        choice: 'multi',
      })
      .subscribe((confirm: boolean) => {
        if (!confirm) return;

        this.subscriptions['SynchEntities'] = this.designService.synchEntities({}, { journeyApiKey: this.designService.sessionJourney.journey.apiKey }).subscribe(() => {
          this.getEntities(1);
        });
      });
  }
}
