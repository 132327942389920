import { Component, OnDestroy, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CommonService } from 'src/app/services/common.service';
import { LicenseService } from 'src/app/services/license.service';
import { FacebookMessengerItemModalComponent } from './items/facebook-messenger-item-modal/facebook-messenger-item-modal.component';
import { SupportModalComponent } from '../modals/support-modal/support-modal.component';
import { ChatWidgetItemModalComponent } from './items/chat-widget-item-modal/chat-widget-item-modal.component';
import { DesignService } from 'src/app/services/design.service';
import { ChannelsService } from 'src/app/services/channels.service';
import { VivochaItemModalComponent } from './items/vivocha-item-modal/vivocha-item-modal.component';
import { WhatsappItemModalComponent } from './items/whatsapp-item-modal/whatsapp-item-modal.component';
import { RccItemModalComponent } from './items/rcc-item-modal/rcc-item-modal.component';
import { LivepersonItemModalComponent } from './items/liveperson-item-modal/liveperson-item-modal.component';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss']
})
export class ChannelsComponent implements OnInit, OnDestroy {

  subscriptions = {};
  products: any = {};

  constructor(public licenseService: LicenseService, private commonService: CommonService, private designService: DesignService, private modalService: NgbModal, private channelsService: ChannelsService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.designService.getSessionJourney().subscribe(() => {
      this.getProducts();
    })
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach(key => {
      this.subscriptions[key].unsubscribe();
    })
  }

  getProducts() {
    this.subscriptions['Channels'] = this.channelsService.getChannels(this.designService.sessionJourney.journey.id).subscribe((response: any) => {
      response.forEach(el => {
        el.category = el.product.category;
      })
      this.products = {
        Channels: this.commonService.groupBy(response, 'category')
      };
    });
  }

  modalSupport() {
    const modalRef = this.modalService.open(SupportModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.support = { requestType: 'Quota Increase' };
  }

  enableDisableChannel(item: any) {
    if (item.enabled) {
      this.disableChannel(item);
    } else {
      this.openChannelModal(item);
    }
  }

  openChannelModal(item: any) {
    let modRef;
    switch(item.product.code) {
      case 'C_F':
        modRef = this.modalService.open(FacebookMessengerItemModalComponent, {size: 'xl'});
        break;
      case 'C_V':
        modRef = this.modalService.open(VivochaItemModalComponent, {size: 'xl'});
        break;
      case 'C_W':
        modRef = this.modalService.open(WhatsappItemModalComponent, {size: 'xl'});
        break;
      case 'C_T':
        modRef = this.modalService.open(RccItemModalComponent, {size: 'xl'});
        break;
      case 'C_L':
        modRef = this.modalService.open(LivepersonItemModalComponent, {size: 'xl'});
        break;
      default:
        return;
    }
    modRef.componentInstance.channel = item;
    modRef.componentInstance.onSuccessSaved.subscribe((data) => {
      Object.keys(data).forEach(key => item[key] = data[key])
    })
  }

  chatWidgetModal() {
    this.modalService.open(ChatWidgetItemModalComponent, {
      size: 'lg'
    });
  }

  private disableChannel(item: any) {
    let channelPath = this.getChannelPath(item.product.code);
    this.subscriptions[item.product.code] = this.channelsService.updateChannel(channelPath, {...item, ...{enabled: false}}).subscribe((data: any) => {
      item.enabled = data.enabled;
    },
    error => {
      if (channelPath === 'liveperson' && error.status === 502) {
        this.notificationService.openModal({
          title: 'ERROR',
          message: 'The configuration is switched off, but the agent is still running. Try re-active and switch off the channel again',
          type: 'danger',
          choice: 'support',
        }).subscribe(async (confirm: boolean) => {
          if (!confirm) return;
          
          this.modalService.open(SupportModalComponent, {
            size: 'lg'
          });
        });
        item.enabled = error.error.enabled;
      }
    })
  }

  private getChannelPath(productCode) {
    let channelPath = '';
    switch(productCode) {
      case 'C_F':
        channelPath = 'messenger';
        break;
      case 'C_V':
        channelPath = 'vivocha';
        break;
      case 'C_W':
        channelPath = 'whatsapp';
        break;
      case 'C_T':
        channelPath = 'timrcc';
        break;
      case 'C_L':
        channelPath = 'liveperson';
        break;
      default:
        break;
    }
    return channelPath;
  }

}
