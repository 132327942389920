import { Component, OnInit, Input, ViewChild, OnDestroy, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DesignService } from '../../../../services/design.service';

@Component({
  selector: 'app-translate-connector-modal',
  templateUrl: './translate-connector-modal.component.html',
  styleUrls: ['./translate-connector-modal.component.scss']
})
export class TranslateConnectorModalComponent implements OnInit, OnDestroy {
  @Input() private journey: any;
  @Input() connector: any;
  @Input() isBeforeNlu: boolean;
  @Input() translateBeforeNlu: boolean;
  @Output() private onSaveConnector = new EventEmitter<any>();
  @ViewChild('translateForm', { static: true }) translateForm: NgForm;
  
  subscriptions: Object = {};

  languageSuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.designService.getTranslateLanguages().pipe(
          map(languages => languages.filter(language => language.toLowerCase().indexOf(term.toLocaleLowerCase()) > -1)),
          catchError(() => {
            return of([])
          })
        )
      )
    )

  constructor(public activeModal: NgbActiveModal, private designService: DesignService) { }

  ngOnInit() {
    if (!this.connector.id) {
      this.connector = {...this.connector,
        isDetectLanguage: true,
        onlyFirstInteraction: false
      };
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  switchTranslateType() {
    delete this.connector.minChars;
    delete this.connector.languages;
  }

  saveConnector() {
    if (this.translateForm.invalid) return;

    const data = {...this.connector, journeyId: this.journey.id };
    
    if (this.connector.id) {
      this.subscriptions['Connector'] = this.designService.editConnector('translate', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    } else {
      this.subscriptions['Connector'] = this.designService.addConnector('translate', data).subscribe((response: any) => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    }
  }

}
