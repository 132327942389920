import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  getUsers(pageNumber: number, pageSize: number, accountId: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('accountId', accountId.toString())
    };

    return this.http.get(`${environment.API.orchestrator_configurator}/users`, httpOptions);
  }

  getIndividualUser(userId: number) {
    return this.http.get(`${environment.API.orchestrator_configurator}/users/${userId}`);
  }

  createUser(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/users`, data);
  }

  updateUser(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_configurator}/users`, data);
  }

  deleteUser(userId: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('id', userId.toString())
    };

    return this.http.delete(`${environment.API.orchestrator_configurator}/users`, httpOptions);
  }

  getUserRoles(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/users/authorities`);
  }

  getComponentsTypes(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/audits/getComponentTypes`);
  }

  getAuditLog(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    };
    
    
    if (params.activityType) httpOptions.params = httpOptions.params.append('activityType', params.activityType);
    if (params.journeyName) httpOptions.params = httpOptions.params.append('journeyName', params.journeyName);

    if (params.component) httpOptions.params = httpOptions.params.append('component', params.component);
    if (params.eventType) httpOptions.params = httpOptions.params.append('eventType', params.eventType);
    if (params.principal) httpOptions.params = httpOptions.params.append('principal', params.principal);
    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);

    return this.http.get(`${environment.API.orchestrator_configurator}/audits`, httpOptions);
  }

  getAuditTypes(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/audits/types`);
  }

  getActivityTypes(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/audits/getActivityTypes`);
  }

  sendSupportRequest(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/support`, data);
  }

  getTrainerTags(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/tagUser`);
  }

  getTrainerTagsTree(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/tagUser/getTagTree`);
  }

  updateTrainerTags(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_configurator}/tagUser`, data);
  }

}
