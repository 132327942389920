import { Component } from '@angular/core';

import { AuthenticationService } from '../../../../services/authentication.service';

@Component({
  selector: 'setting-profile',
  templateUrl: './setting-profile.component.html',
  styleUrls: ['./setting-profile.component.scss'],
})
export class SettingProfileComponent {
  get user() {
    return this.authenticationService.user;
  }

  constructor(private authenticationService: AuthenticationService) {}
}
