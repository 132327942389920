import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Entity } from 'src/app/interfaces/entity';
import { DesignService } from 'src/app/services/design.service';

@Component({
  selector: 'app-entity-modal',
  templateUrl: './entity-modal.component.html',
  styleUrls: ['./entity-modal.component.scss'],
})
export class EntityModalComponent implements OnInit, OnDestroy {
  @Input() entity?: Entity;
  @Output() private onSaveEntity = new EventEmitter<any>();

  subscriptions: Object = {};

  entriesMode: { synonyms: boolean; regEx: boolean } = {
    synonyms: true,
    regEx: false,
  };

  entityForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    kind: ['KIND_MAP'],
    autoExpansionMode: ['AUTO_EXPANSION_MODE_UNSPECIFIED'],
    entities: this.fb.array([]),
    enableFuzzyExtraction: [false],
    language: [''],
  });
  entitySubmitted = false;

  get entityValues() {
    return this.entityForm.get('entities') as FormArray;
  }
  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private designService: DesignService) {}

  ngOnInit() {
    if (this.entity) {
      this.entityForm.addControl('id', this.fb.control(''));
      this.entityForm.addControl('dialogflowId', this.fb.control(''));
      this.entityForm.patchValue(this.entity);
      this.processEntryMode(this.entity.kind);
      this.processEntitiesArray(this.entityValues, this.entity.entities);
    } else {
      this.addValue();
      this.entityForm.get('language').setValue(this.designService.sessionAgentLang);
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  processEntryMode(kind: string) {
    switch (kind) {
      case 'KIND_REGEXP':
        this.entriesMode.regEx = true;
        this.entriesMode.synonyms = false;
        break;
      case 'KIND_MAP':
        this.entriesMode.regEx = false;
        this.entriesMode.synonyms = true;
        break;
      default:
        this.entriesMode.regEx = false;
        this.entriesMode.synonyms = false;
        break;
    }
  }

  processEntitiesArray(formArray: FormArray, arrayData: Array<any>): void {
    if (arrayData.length) {
      arrayData.forEach((element) => {
        formArray.push(
          this.fb.group({
            value: [element.value, Validators.required],
            synonyms: [element.synonyms.join(', '), Validators.required],
          })
        );
      });
    }
  }

  addValue() {
    this.entityValues.push(
      this.fb.group({
        value: ['', Validators.required],
        synonyms: ['', Validators.required],
      })
    );
  }

  updateSynonymsValidation() {
    this.entityValues.controls.forEach((element) =>
      this.entityForm.value['kind'] === 'KIND_MAP' ? element.get('synonyms').enable() : element.get('synonyms').disable()
    );
  }

  setExpansionMode($event) {
    if ($event.target.checked) this.entityForm.get('autoExpansionMode').setValue('AUTO_EXPANSION_MODE_DEFAULT');
    else this.entityForm.get('autoExpansionMode').setValue('AUTO_EXPANSION_MODE_UNSPECIFIED');
  }

  setEntriesMode(mode: 'synonyms' | 'regEx', $event) {
    if (mode === 'synonyms' && $event.target.checked) {
      this.entriesMode.regEx = false;
      this.entityForm.get('kind').setValue('KIND_MAP');
    } else if (mode === 'regEx' && $event.target.checked) {
      this.entriesMode.synonyms = false;
      this.entityForm.get('kind').setValue('KIND_REGEXP');
    } else {
      this.entityForm.get('kind').setValue('KIND_LIST');
    }
    this.updateSynonymsValidation();
  }

  saveEntity() {
    this.entitySubmitted = true;
    if (this.entityForm.invalid) return;

    const entities = this.entityForm.value.entities.map(({ value, synonyms }) => {
      if (this.entityForm.value['kind'] === 'KIND_MAP') {
        return {
          value,
          synonyms: synonyms.split(',').map((s) => s.trim()),
        };
      } else {
        return {
          value,
          synonyms: [value],
        };
      }
    });

    const data = { ...this.entityForm.value, entities };

    if (this.entityForm.value.id) {
      this.subscriptions['EntitySubscription'] = this.designService.editEntity(data).subscribe(() => {
        this.activeModal.close();
        this.onSaveEntity.emit();
      });
    } else {
      this.subscriptions['EntitySubscription'] = this.designService.addEntity(data).subscribe(() => {
        this.activeModal.close();
        this.onSaveEntity.emit();
      });
    }
  }
}
