import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { ExitButton, NextButton, BackButton } from 'src/app/classes/tutorials/buttons.tutorial';
import { Step } from 'src/app/classes/tutorials/step.tutorial';
import { IntentParametersComponent } from 'src/app/components/intent-parameters/intent-parameters.component';

@Injectable({
  providedIn: 'root',
})
export class CreateIntnentsTutorialIntentParametersService {
  private component: IntentParametersComponent;
  private exitCallback;
  constructor(private shepherdService: ShepherdService, private router: Router) {
    this.shepherdService.modal = true;
    this.exitCallback = () => {
      this.router.navigate(['/intent-settings']);
      this.shepherdService.cancel();
    };
  }
  startTutorial(component) {
    this.component = component;
    if (this.shepherdService.isActive) {
      this.shepherdService.complete();
    }
    this.shepherdService.addSteps([
      new Step({
        attachTo: {
          element: '#responseTab',
          on: 'bottom',
        },
        title: `Responses`,
        text: 'From the "responses" section you manage intent responses.',
        buttons: [new ExitButton(this.exitCallback), new NextButton()],
      }),
      new Step({
        padding: 5,
        attachTo: {
          element: '#addResponseButton',
          on: 'left',
        },
        advanceOn: { selector: '#addResponseButton', event: 'click' },
        title: `Add response`,
        text: 'Click "Add response" to create a response to an intent.',
        buttons: [
          new ExitButton(this.exitCallback),
          new BackButton(),
          new NextButton(() => {
            this.component.responsesSetting();
            this.shepherdService.next();
          }),
        ],
      }),
    ]);

    this.shepherdService.start();
  }
}
