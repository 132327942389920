import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

import { NgbModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { DatepickerAdapter } from './classes/datepicker-adapter';
import { DateParserFormatter } from './classes/date-parser-formatter';

import { FullTextSearchPipe } from './pipes/full-text-search.pipe';

import { httpInterceptorProviders } from './http-interceptors';

import { Ng5SliderModule } from 'ng5-slider';
import { AngularResizedEventModule } from 'angular-resize-event';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory, } from '@stomp/ng2-stompjs';
import { wsRxStompConfig } from './stomp-configs/ws-rx-stomp.config';

import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { ConsoleHeaderComponent } from './components/console-header/console-header.component';
import { ConsoleMenuComponent } from './components/console-menu/console-menu.component';
import { NotificationModalComponent } from './components/modals/notification-modal/notification-modal.component';
import { RatingModalComponent } from './components/modals/rating-modal/rating-modal.component';
import { ToastNotificationComponent } from './components/utility/toast-notification/toast-notification.component';
import { DatepickerRangeComponent } from './components/utility/datepicker-range/datepicker-range.component';
import { LoginComponent } from './components/login/login.component';
import { TrainingComponent } from './components/training/training.component';
import { TrainingModalComponent } from './components/modals/training-modal/training-modal.component';
import { AdminComponent } from './components/admin/admin.component';
import { UserModalComponent } from './components/modals/user-modal/user-modal.component';
import { AuditLogComponent } from './components/admin/audit-log/audit-log.component';
import { SpinnerLoaderDirective } from './directives/spinner-loader.directive';
import { SpinnerLoaderComponent } from './components/utility/spinner-loader/spinner-loader.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { AnalyticsFilterComponent } from './components/analytics/analytics-filter/analytics-filter.component';
import { AnalyticSessionsComponent } from './components/analytics/analytic-sessions/analytic-sessions.component';
import { ConversationalHistoryComponent } from './components/analytics/conversational-history/conversational-history.component';
import { AnalyticProficiencyComponent } from './components/analytics/analytic-proficiency/analytic-proficiency.component';
import { DesignComponent } from './components/design/design.component';
import { ChatComponent } from './components/chat/chat.component';
import { ChatDebugModalComponent } from './components/modals/chat-debug-modal/chat-debug-modal.component';
import { EntitySelectionDirective } from './directives/entity-selection.directive';
import { IntentsReliabilityComponent } from './components/intents-reliability/intents-reliability.component';
import { StarRatingComponent } from './components/utility/star-rating/star-rating.component';
import { AccessManagementComponent } from './components/admin/access-management/access-management.component';
import { TableSortDirective } from './directives/table-sort.directive';
import { IntentParametersComponent } from './components/intent-parameters/intent-parameters.component';
import { IntentTagsComponent } from './components/intent-tags/intent-tags.component';
import { IntentSettingsComponent } from './components/intent-settings/intent-settings.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RoleRestrictionDirective } from './directives/role-restriction.directive';
import { SmtpConnectorModalComponent } from './components/design/connectors/smtp-connector-modal/smtp-connector-modal.component';
import { StepsCompletionComponent } from './components/utility/steps-completion/steps-completion.component';
import { JourneyModalComponent } from './components/modals/journey-modal/journey-modal.component';
import { HttpConnectorModalComponent } from './components/design/connectors/http-connector-modal/http-connector-modal.component';
import { NluConnectorModalComponent } from './components/design/connectors/nlu-connector-modal/nlu-connector-modal.component';
import { GenericRuleModalComponent } from './components/design/connectors/generic-rule-modal/generic-rule-modal.component';
import { SupportModalComponent } from './components/modals/support-modal/support-modal.component';
import { DialogflowConnectorModalComponent } from './components/design/connectors/dialogflow-connector-modal/dialogflow-connector-modal.component';
import { ChatSettingsModalComponent } from './components/modals/chat-settings-modal/chat-settings-modal.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TrainingTeamComponent } from './components/admin/training-team/training-team.component';
import { TopicsModalComponent } from './components/modals/topics-modal/topics-modal.component';
import { OverviewCardComponent } from './components/dashboard/overview-card/overview-card.component';
import { AnalyticsCardComponent } from './components/dashboard/analytics-card/analytics-card.component';
import { TrainingCardComponent } from './components/dashboard/training-card/training-card.component';
import { EventsCardComponent } from './components/dashboard/events-card/events-card.component';
import { StatusCardComponent } from './components/dashboard/status-card/status-card.component';
import { BillingComponent } from './components/billing/billing.component';
import { RequestsCardComponent } from './components/billing/requests-card/requests-card.component';
import { ConsumptionCardComponent } from './components/billing/consumption-card/consumption-card.component';
import { DlpConnectorModalComponent } from './components/design/connectors/dlp-connector-modal/dlp-connector-modal.component';
import { TranslateConnectorModalComponent } from './components/design/connectors/translate-connector-modal/translate-connector-modal.component';
import { UserSettingsModalComponent } from './components/modals/user-settings-modal/user-settings-modal.component';
import { SettingAlertsComponent } from './components/modals/user-settings-modal/setting-alerts/setting-alerts.component';
import { SettingProfileComponent } from './components/modals/user-settings-modal/setting-profile/setting-profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SettingLicenseComponent } from './components/modals/user-settings-modal/setting-license/setting-license.component';
import { TrooveConnectorModalComponent } from './components/design/connectors/troove-connector-modal/troove-connector-modal.component';
import { ButtonItemComponent } from './components/intent-parameters/response-items/button-item/button-item.component';
import { ListItemComponent } from './components/intent-parameters/response-items/list-item/list-item.component';
import { CarouselCardItemComponent } from './components/intent-parameters/response-items/carousel-card-item/carousel-card-item.component';
import { ChipsItemComponent } from './components/intent-parameters/response-items/chips-item/chips-item.component';
import { ParametersResponseFormComponent } from './components/intent-parameters/parameters-response-form/parameters-response-form.component';
import { PaginationComponent } from './components/utility/pagination/pagination.component';
import { DataTransferConnectorModalComponent } from './components/design/connectors/data-transfer-connector-modal/data-transfer-connector-modal.component';
import { MarketplaceComponent } from './components/marketplace/marketplace.component';
import { MarketConnectorsComponent } from './components/marketplace/market-connectors/market-connectors.component';
import { MarketTemplatesComponent } from './components/marketplace/market-templates/market-templates.component';
import { MarketModulesComponent } from './components/marketplace/market-modules/market-modules.component';
import { PasswordRequirementsComponent } from './components/utility/password-requirements/password-requirements.component';
import { DeleteDialogflowConnectorModalComponent } from './components/design/connectors/delete-dialogflow-connector-modal/delete-dialogflow-connector-modal.component';
import { ConversationFlowComponent } from './components/design/conversation-flow/conversation-flow.component';
import { UrlSanitizerPipe } from './pipes/url-sanitizer.pipe';
import { DataProcessingConnectorModalComponent } from './components/design/connectors/data-processing-connector-modal/data-processing-connector-modal.component';
import { AnalyticReportsComponent } from './components/analytics/analytic-reports/analytic-reports.component';
import { CustomReportModalComponent } from './components/modals/custom-report-modal/custom-report-modal.component';
import { ReportChartModalComponent } from './components/modals/report-chart-modal/report-chart-modal.component';
import { CustomReportDetailsModalComponent } from './components/modals/custom-report-details-modal/custom-report-details-modal.component';
import { LicenseRestrictionDirective } from './directives/license-restriction.directive';
import { IntentModalComponent } from './components/modals/intent-modal/intent-modal.component';
import { InputContextFieldComponent } from './components/design/input-context-field/input-context-field.component';
import { OutputContextFieldComponent } from './components/design/output-context-field/output-context-field.component';
import { RestrictCharDirective } from './directives/restrict-char.directive';
import { ZendeskConnectorModalComponent } from './components/design/connectors/zendesk-connector-modal/zendesk-connector-modal.component';
import { ChannelsComponent } from './components/channels/channels.component';
import { FacebookMessengerItemModalComponent } from './components/channels/items/facebook-messenger-item-modal/facebook-messenger-item-modal.component';
import { ChatWidgetItemModalComponent } from './components/channels/items/chat-widget-item-modal/chat-widget-item-modal.component';
import { PhrasePartsDirective } from './directives/phrase-parts.directive';
import { TrainingPrhasesFieldComponent } from './components/design/training-prhases-field/training-prhases-field.component';
import { VivochaItemModalComponent } from './components/channels/items/vivocha-item-modal/vivocha-item-modal.component';
import { FaqAnalysisComponent } from './components/faq-analysis/faq-analysis.component';
import { FaqAnalysisIntentModalComponent } from './components/modals/faq-analysis-intent-modal/faq-analysis-intent-modal.component';
import { ToneOfVoiceSurveyComponent } from './components/tone-of-voice-survey/tone-of-voice-survey.component';
import { ToneOfVoiceCardComponent } from './components/dashboard/tone-of-voice-card/tone-of-voice-card.component';
import { FaqProcessesComponent } from './components/faq-processes/faq-processes.component';
import { WhatsappItemModalComponent } from './components/channels/items/whatsapp-item-modal/whatsapp-item-modal.component';
import { ValidationComponent } from './components/validation/validation.component';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { ToneOfVoiceSupportModalComponent } from './components/modals/tone-of-voice-support-modal/tone-of-voice-support-modal.component';
import { AgentLanguagesComponent } from './components/utility/agent-languages/agent-languages.component';
import { MarketChannelsComponent } from './components/marketplace/market-channels/market-channels.component';
import { MarketDetailModalComponent } from './components/marketplace/market-detail-modal/market-detail-modal.component';
import { RccItemModalComponent } from './components/channels/items/rcc-item-modal/rcc-item-modal.component';
import { EntitiesComponent } from './components/design/entities/entities.component';
import { EntityModalComponent } from './components/modals/entity-modal/entity-modal.component';
import { JourneyRestrictionDirective } from './directives/journey-restriction.directive';
import { DialogflowCxConnectorModalComponent } from './components/design/connectors/dialogflow-cx-connector-modal/dialogflow-cx-connector-modal.component';
import { RegressionTestComponent } from './components/regression-test/regression-test.component';
import { TestCaseModalComponent } from './components/modals/test-case-modal/test-case-modal.component';
import { TestProcessesComponent } from './components/regression-test/test-processes/test-processes.component';
import { TestCaseComponent } from './components/regression-test/test-case/test-case.component';
import { LivepersonItemModalComponent } from './components/channels/items/liveperson-item-modal/liveperson-item-modal.component';
import { MonitoringComponent } from './components/monitoring/monitoring.component';
import { HealthStatusComponent } from './components/monitoring/health-status/health-status.component';
import { HealthStatusModalComponent } from './components/modals/health-status-modal/health-status-modal.component';
import { ComponentStatusCardComponent } from './components/monitoring/component-status-card/component-status-card.component';
import { LogsComponent } from './components/monitoring/logs/logs.component';
import { MonitoringCardComponent } from './components/dashboard/monitoring-card/monitoring-card.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ShepherdService } from 'angular-shepherd';
import { CreateIntentsTutorialConversationFlowService } from './services/tutorials/create-intents-tutorial.conversation-flow.service';
import { CreateIntentsTutorialIntentModalService } from './services/tutorials/create-intents-tutorial.intent-modal.service';
import { CreateIntnentsTutorialIntentParametersService } from './services/tutorials/create-intnents-tutorial.intent-parameters.service';
import { CreateIntnentsTutorialParametmersResponseFormService } from './services/tutorials/create-intnents-tutorial.parametmers-response-form.service';
import { ExportLogsModalComponent } from './components/modals/export-logs-modal/export-logs-modal.component';
import { LivepersonAgentStatusCardComponent } from './components/monitoring/liveperson-agent-status-card/liveperson-agent-status-card.component';
import { AnalyticEngagementComponent } from './components/analytics/analytic-engagement/analytic-engagement.component';
import { ChatInteractionComponent } from './components/chat-interaction/chat-interaction.component';

registerLocaleData(localeIt, 'it');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ToastNotificationComponent,
    DatepickerRangeComponent,
    SpinnerLoaderDirective,
    FullTextSearchPipe,
    ConsoleHeaderComponent,
    ConsoleMenuComponent,
    SpinnerLoaderComponent,
    LoginComponent,
    NotificationModalComponent,
    TrainingComponent,
    TrainingModalComponent,
    AdminComponent,
    UserModalComponent,
    AuditLogComponent,
    AnalyticsComponent,
    AnalyticsFilterComponent,
    AnalyticSessionsComponent,
    ConversationalHistoryComponent,
    AnalyticProficiencyComponent,
    DesignComponent,
    RatingModalComponent,
    ChatComponent,
    ChatInteractionComponent,
    ChatDebugModalComponent,
    EntitySelectionDirective,
    IntentsReliabilityComponent,
    StarRatingComponent,
    AccessManagementComponent,
    TableSortDirective,
    IntentParametersComponent,
    IntentTagsComponent,
    IntentSettingsComponent,
    ResetPasswordComponent,
    RoleRestrictionDirective,
    SmtpConnectorModalComponent,
    StepsCompletionComponent,
    JourneyModalComponent,
    HttpConnectorModalComponent,
    NluConnectorModalComponent,
    GenericRuleModalComponent,
    SupportModalComponent,
    DialogflowConnectorModalComponent,
    ChatSettingsModalComponent,
    DashboardComponent,
    TrainingTeamComponent,
    TopicsModalComponent,
    OverviewCardComponent,
    AnalyticsCardComponent,
    TrainingCardComponent,
    EventsCardComponent,
    StatusCardComponent,
    BillingComponent,
    RequestsCardComponent,
    ConsumptionCardComponent,
    DlpConnectorModalComponent,
    TranslateConnectorModalComponent,
    UserSettingsModalComponent,
    SettingAlertsComponent,
    SettingProfileComponent,
    ChangePasswordComponent,
    SettingLicenseComponent,
    TrooveConnectorModalComponent,
    ButtonItemComponent,
    ListItemComponent,
    CarouselCardItemComponent,
    ChipsItemComponent,
    ParametersResponseFormComponent,
    PaginationComponent,
    DataTransferConnectorModalComponent,
    MarketplaceComponent,
    MarketConnectorsComponent,
    MarketTemplatesComponent,
    MarketModulesComponent,
    PasswordRequirementsComponent,
    DeleteDialogflowConnectorModalComponent,
    ConversationFlowComponent,
    UrlSanitizerPipe,
    DataProcessingConnectorModalComponent,
    AnalyticReportsComponent,
    CustomReportModalComponent,
    ReportChartModalComponent,
    CustomReportDetailsModalComponent,
    LicenseRestrictionDirective,
    IntentModalComponent,
    InputContextFieldComponent,
    OutputContextFieldComponent,
    RestrictCharDirective,
    ZendeskConnectorModalComponent,
    ChannelsComponent,
    FacebookMessengerItemModalComponent,
    ChatWidgetItemModalComponent,
    PhrasePartsDirective,
    TrainingPrhasesFieldComponent,
    VivochaItemModalComponent,
    FaqAnalysisComponent,
    FaqAnalysisIntentModalComponent,
    ToneOfVoiceSurveyComponent,
    ToneOfVoiceCardComponent,
    FaqProcessesComponent,
    WhatsappItemModalComponent,
    ValidationComponent,
    SafeStylePipe,
    ToneOfVoiceSupportModalComponent,
    AgentLanguagesComponent,
    MarketChannelsComponent,
    MarketDetailModalComponent,
    RccItemModalComponent,
    EntitiesComponent,
    EntityModalComponent,
    JourneyRestrictionDirective,
    DialogflowCxConnectorModalComponent,
    RegressionTestComponent,
    TestCaseModalComponent,
    TestProcessesComponent,
    TestCaseComponent,
    LivepersonItemModalComponent,
    MonitoringComponent,
    HealthStatusComponent,
    HealthStatusModalComponent,
    ComponentStatusCardComponent,
    LogsComponent,
    MonitoringCardComponent,
    ClickOutsideDirective,
    ExportLogsModalComponent,
    LivepersonAgentStatusCardComponent,
    AnalyticEngagementComponent
  ],
  imports: [
    Ng5SliderModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    CarouselModule,
    AngularResizedEventModule,
    NgxGraphModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
  })
  ],
  providers: [
    httpInterceptorProviders,
        DecimalPipe,
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: NgbDateAdapter, useClass: DatepickerAdapter },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
    { provide: InjectableRxStompConfig, useValue: wsRxStompConfig, },
    { provide: RxStompService, useFactory: rxStompServiceFactory, deps: [ InjectableRxStompConfig ] },
    ShepherdService,
    CreateIntentsTutorialConversationFlowService,
    CreateIntentsTutorialIntentModalService,
    CreateIntnentsTutorialIntentParametersService,
    CreateIntnentsTutorialParametmersResponseFormService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NotificationModalComponent,
    SpinnerLoaderComponent,
    TrainingModalComponent,
    UserModalComponent,
    RatingModalComponent,
    ChatDebugModalComponent,
    SmtpConnectorModalComponent,
    JourneyModalComponent,
    HttpConnectorModalComponent,
    NluConnectorModalComponent,
    GenericRuleModalComponent,
    SupportModalComponent,
    TopicsModalComponent,
    DialogflowConnectorModalComponent,
    ChatSettingsModalComponent,
    DlpConnectorModalComponent,
    TranslateConnectorModalComponent,
    UserSettingsModalComponent,
    TrooveConnectorModalComponent,
    DataTransferConnectorModalComponent,
    DeleteDialogflowConnectorModalComponent,
    DataProcessingConnectorModalComponent,
    CustomReportModalComponent,
    ReportChartModalComponent,
    CustomReportDetailsModalComponent,
    IntentModalComponent,
    ZendeskConnectorModalComponent,
    FacebookMessengerItemModalComponent,
    ChatWidgetItemModalComponent,
    VivochaItemModalComponent,
    FaqAnalysisIntentModalComponent,
    WhatsappItemModalComponent,
    ToneOfVoiceSupportModalComponent, 
    MarketDetailModalComponent,
    RccItemModalComponent,
    EntityModalComponent,
    ToneOfVoiceSupportModalComponent,
    DialogflowCxConnectorModalComponent,
    TestCaseModalComponent,
    LivepersonItemModalComponent,
    HealthStatusModalComponent,
    ExportLogsModalComponent
  ]
})
export class AppModule { }
