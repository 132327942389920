import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewEncapsulation, ViewChildren, QueryList, AfterViewInit } from '@angular/core';

import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

import { DesignService } from '../../services/design.service';
import { LicenseService } from '../../services/license.service';
import { NotificationService } from '../../services/notification.service';
import { IntentService } from '../../services/intent.service';
import { AuthenticationService } from '../../services/authentication.service';
import { JourneyModalComponent } from '../modals/journey-modal/journey-modal.component';
import { CommonService } from '../../services/common.service';
import { SmtpConnectorModalComponent } from './connectors/smtp-connector-modal/smtp-connector-modal.component';
import { HttpConnectorModalComponent } from './connectors/http-connector-modal/http-connector-modal.component';
import { NluConnectorModalComponent } from './connectors/nlu-connector-modal/nlu-connector-modal.component';
import { GenericRuleModalComponent } from './connectors/generic-rule-modal/generic-rule-modal.component';
import { DialogflowConnectorModalComponent } from './connectors/dialogflow-connector-modal/dialogflow-connector-modal.component';
import { DlpConnectorModalComponent } from './connectors/dlp-connector-modal/dlp-connector-modal.component';
import { TranslateConnectorModalComponent } from './connectors/translate-connector-modal/translate-connector-modal.component';
import { TrooveConnectorModalComponent } from './connectors/troove-connector-modal/troove-connector-modal.component';
import { DataTransferConnectorModalComponent } from './connectors/data-transfer-connector-modal/data-transfer-connector-modal.component';
import { DeleteDialogflowConnectorModalComponent } from './connectors/delete-dialogflow-connector-modal/delete-dialogflow-connector-modal.component';
import { DataProcessingConnectorModalComponent } from './connectors/data-processing-connector-modal/data-processing-connector-modal.component';
import { SupportModalComponent } from '../modals/support-modal/support-modal.component';
import { ZendeskConnectorModalComponent } from './connectors/zendesk-connector-modal/zendesk-connector-modal.component';
import { DialogflowCxConnectorModalComponent } from './connectors/dialogflow-cx-connector-modal/dialogflow-cx-connector-modal.component';

@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DesignComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('drawBoard', { static: false }) private drawBoard: ElementRef;
  @ViewChildren('popRuleConnector') private popRuleConnector: QueryList<NgbPopover>;
  @ViewChildren('connectorName') connectorName: QueryList<ElementRef>;

  subscriptions: Object = {};
  connectors: Array<any> = [];
  boards: Array<any> = [];
  activeBoard: any = {};
  journeyLimit: number;

  synchFinished: boolean;

  constructor(private modalService: NgbModal, private licenseService: LicenseService, private designService: DesignService, private intentService: IntentService, private notificationService: NotificationService, private authenticationService: AuthenticationService, private commonService: CommonService) { }

  ngOnInit() {
    this.getJourneysOnSessionReady();
    this.getLicenseGroup();
    this.getLicense();

    this.subscriptions['Account'] = this.authenticationService.onChangeSessionAccount().subscribe(() => {
      this.activeBoard = {};
      this.getJourneysOnSessionReady();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.subscriptions['EllipsisName'] = this.connectorName.changes.subscribe((elements: QueryList<ElementRef>) => {
      elements.forEach((element: ElementRef) => {
        if (element.nativeElement.offsetWidth < element.nativeElement.scrollWidth) {
          element.nativeElement.classList.add('ellipses');
        }
      })
    })
  }

  getJourneys() {
    this.subscriptions['Board'] = this.designService.getJourneysList().subscribe((response: any) => {
      this.boards = response;
      if (Object.keys(this.activeBoard).length === 0) {
        const sessionJourney = this.boards.find(board => this.designService.sessionJourney && board.journey.apiKey === this.designService.sessionJourney.journey.apiKey);
        this.changeBoard(sessionJourney ? sessionJourney : this.boards[0])
      } else {
        this.activeBoard = this.boards.find(board => board.journey.id === this.activeBoard.journey.id);
        this.sortJourney();
      }
    });
  }

  getLicenseGroup() {
    this.subscriptions['LicenseItems'] = this.licenseService.getSessionLicense().subscribe((response) => {
      this.connectors = this.commonService.groupBy(response.Connector, 'category');
    });
  }

  getLicense() {
    this.subscriptions['License'] = this.licenseService.getLicense().subscribe((response) => {
      this.journeyLimit = response.journeyLimit;
    });
  }

  changeBoard(board: any) {
    this.activeBoard = board;
    this.sortJourney();
  }

  sortJourney() {
    if (this.activeBoard) {
      this.activeBoard.connectors.sort((a, b) => {
        if (a.journeyOrder < b.journeyOrder) {
          return -1
        } else if (a.journeyOrder > b.journeyOrder) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  modalJourney(journey?: any, activeTab?: string) {
    if ( journey || this.journeyLimit == null || this.boards.length < this.journeyLimit) {
      const modalRef = this.modalService.open(JourneyModalComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.journey = journey ? {...journey} : null;
      modalRef.componentInstance.activeTab = activeTab || null;
      modalRef.componentInstance.onSaveJourney.subscribe($event => this.getJourneys());
      modalRef.componentInstance.onDeleteJourney.subscribe($event => {
        this.activeBoard = {};
        this.getJourneys();
      });
    } else {
      this.notificationService.openModal({
        title: 'WARNING',
        message: 'You have reached the maximum number of journeys under your licence. Contact support for quota increase.',
        choice: 'support',
        type: 'warning'
      }).subscribe(async (confirm: boolean) => {
        if (!confirm) return;
        
        this.modalService.open(SupportModalComponent, {
          size: 'lg'
        });
    })
    }
  }

  addToBoard(connector: any, parentIndex?: number) {
    if ( !connector.purchased || (connector.code === 'DF' && this.activeBoard.journey.fulfillment) ) return;

    if (parentIndex != null) {
      if (connector.code === 'RL') return;

      if (!this.activeBoard.connectors[parentIndex].connectors) this.activeBoard.connectors[parentIndex].connectors = [];
      
      this.activeBoard.connectors[parentIndex].connectors.push({
        productCode: connector.code,
        productName: connector.name,
        purchased: connector.purchased,
        parentId: this.activeBoard.connectors[parentIndex].id
      });
    } else {
      this.activeBoard.connectors.push({
        productCode: connector.code,
        productName: connector.name,
        purchased: connector.purchased
      });
    }

    setTimeout(() => {
      this.drawBoard.nativeElement.scrollTop = this.drawBoard.nativeElement.scrollHeight;
    }, 100);
  }

  deleteConnector(connector: any, parentIndex: number, childIndex?: number) {
    if (!connector.id && !Number.isInteger(childIndex)) {
      this.activeBoard.connectors.splice(parentIndex, 1);
      return;
    } else if (!connector.id && Number.isInteger(childIndex)) {
      this.activeBoard.connectors[parentIndex].connectors.splice(childIndex, 1);
      if (this.activeBoard.connectors[parentIndex].connectors.length === 0) this.activeBoard.connectors[parentIndex].connectors = null;
      return;
    }

    let connectorPath: string;
    switch (connector.productCode) {
      case 'E':
        connectorPath = 'email';
        break;
      case 'R':
        connectorPath = 'restHttp';
        break;
      case 'NL':
        connectorPath = 'naturalLanguage';
        break;
      case 'DLP':
        connectorPath = 'dlp';
        break;
      case 'RL':
        connectorPath = 'rule';
        break;
      case 'DF':
        this.deleteDialogflowModal(connector.id);
        return;
      case 'CX':
        connectorPath = 'dialogFlow';
        return;
      case 'T':
        connectorPath = 'translate';
        break;
      case 'Q':
        connectorPath = 'troove';
        break;
      case 'DT':
        connectorPath = 'dataTransfer';
        break;
      case 'DP':
        connectorPath = 'dataProcessing';
        break;
      case 'ZD':
        connectorPath = 'zendesk';
        break;
      default:
        return;
    }
    
    this.notificationService.openModal({
      title: 'Confirm delete connector',
      message: 'Are you sure to remove connector from the Journey?',
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;
      this.subscriptions['RemoveConnector'] = this.designService.removeConnector(connectorPath, connector.id).subscribe(() => {
        this.getJourneys();
      });
    });
  }

  setupConnector(connector: any, parentIndex?: number) {
    let modalRef;
    switch (connector.productCode) {
      case 'E':
        modalRef = this.modalService.open(SmtpConnectorModalComponent, { size: 'lg' });
        break;
      case 'R':
        modalRef = this.modalService.open(HttpConnectorModalComponent, { size: 'lg' });
        break;
      case 'NL':
        modalRef = this.modalService.open(NluConnectorModalComponent, { size: 'lg' });
        break;
      case 'RL':
        modalRef = this.modalService.open(GenericRuleModalComponent, { size: 'xl' });
        modalRef.componentInstance.connectors = this.connectors;
        break;
      case 'DF':
        modalRef = this.modalService.open(DialogflowConnectorModalComponent, { size: 'lg' });
        break;
      case 'CX':
        modalRef = this.modalService.open(DialogflowCxConnectorModalComponent, { size: 'lg' });
        break;
      case 'DLP':
        modalRef = this.modalService.open(DlpConnectorModalComponent, { size: 'lg' });
        break;
      case 'T':
        modalRef = this.modalService.open(TranslateConnectorModalComponent, { size: 'lg' });
        modalRef.componentInstance.isBeforeNlu = this.isBeforeNlu(parentIndex);
        modalRef.componentInstance.translateBeforeNlu = this.alreadyTranslateBeforeNlu(parentIndex);
        break;
      case 'Q':
        modalRef = this.modalService.open(TrooveConnectorModalComponent, { size: 'lg' });
        break;
      case 'DT':
        modalRef = this.modalService.open(DataTransferConnectorModalComponent, { size: 'lg' });
        break;
      case 'DP':
        modalRef = this.modalService.open(DataProcessingConnectorModalComponent, { size: 'lg' });
        break;
      case 'ZD':
        modalRef = this.modalService.open(ZendeskConnectorModalComponent, { size: 'lg' });
        break;
      default:
        return;
    }

    modalRef.componentInstance.journey = this.activeBoard.journey;
    modalRef.componentInstance.connector = connector || null;
    modalRef.componentInstance.onSaveConnector.subscribe($event => {
      this.getJourneys();
      if ($event) {
        this.setupConnector($event.connector);
      }
    });
  }

  togglePopRules(parentRule: any, parentIndex: number) {
    if (this.popRuleConnector.toArray()[this.getPopRuleIndex(parentIndex)].isOpen()) {
      this.popRuleConnector.toArray()[this.getPopRuleIndex(parentIndex)].close();
    } else {
      this.popRuleConnector.toArray()[this.getPopRuleIndex(parentIndex)].open({ parentRule, parentIndex });
    }
  }

  getPopRuleIndex(parentIndex: number) {
    let ruleCount: number;
    let popIndex: number;

    this.activeBoard.connectors.forEach((connector: any, index: number) => {
      if (connector.productCode === 'RL') {
        ruleCount = (typeof ruleCount === 'undefined') ? 0 : ruleCount + 1;
      }
      if (connector.productCode === 'RL' && parentIndex === index) {
        popIndex = ruleCount
      }
    });
    return popIndex;
  }

  synchIntents() {
    if (this.activeBoard.connectors.some(connector => connector.productCode === 'DF' && connector.id)) {
      this.notificationService.openModal({
        title: 'Data update',
        message: 'The data update may take few seconds, during which it will not be possible to use the application. Do you want to continue?',
        choice: 'multi'
      }).subscribe(async (confirm: boolean) => {
        if (!confirm) return;

        this.synchFinished = false;
        await this.intentService.synchIntents({}, { journeyApiKey: this.activeBoard.journey.apiKey }).toPromise().then(() => this.synchFinished = true);
        this.getJourneys();
      });
    } else {
      this.getJourneys();
    }
  }

  deleteDialogflowModal(connectorId: number) {
    let modalRef = this.modalService.open(DeleteDialogflowConnectorModalComponent, { size: 'lg' });
    modalRef.componentInstance.journeyApiKey = this.activeBoard.journey.apiKey;
    modalRef.componentInstance.connectorId = connectorId;
    modalRef.componentInstance.onDeleteConnector.subscribe($event => {
      this.getJourneys();
    });
  }

  getJourneysOnSessionReady() {
    this.subscriptions['Journey'] = this.designService.getSessionJourney().pipe(first()).subscribe(() => {
      this.getJourneys();
    })
  }

  isBeforeNlu(connectorIndex: any): boolean {
    let nluIndex: number;
    this.activeBoard.connectors.forEach((connector: any, index: number) => {
      if (connector.productCode === 'DF') nluIndex = index;
    });

    if (!nluIndex) return true;

    return (connectorIndex < nluIndex);
  }

  alreadyTranslateBeforeNlu(translateIndex: number): boolean {
    let tranlsateBeforeNluIndex: number;

    for (let index = 0; index < this.activeBoard.connectors.length; index++) {
      const connector = this.activeBoard.connectors[index];
      if (connector.productCode === 'T' && index < translateIndex && this.isBeforeNlu(index)) {
        tranlsateBeforeNluIndex = index;
        break;
      }
    }

    return Number.isInteger(tranlsateBeforeNluIndex);
  }
}
