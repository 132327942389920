import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';

import { Chatbot } from '../../classes/chatbot';
import { ChatDebugModalComponent } from '../../components/modals/chat-debug-modal/chat-debug-modal.component';
import { AuthenticationService } from '../../services/authentication.service';
import { ChatService } from '../../services/chat.service';
import { DesignService } from '../../services/design.service';
import { ChatSettingsModalComponent } from '../modals/chat-settings-modal/chat-settings-modal.component';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatComponent implements OnInit, OnDestroy, OnChanges {
  @Output() switchChat = new EventEmitter<any>();
  @Input() openChat: boolean;

  subscriptions: Object = {};

  journeys: Array<any>;
  supportJourney;
  activeJourney: any;
  journeyType: 'stage' | 'support' = 'stage';
  botSession: Chatbot;

  cardCarouselOptions: OwlOptions = {
    items: 2,
    dots: false,
    margin: 10,
    autoWidth: true,
  };

  chipsCarouselOptions: OwlOptions = {
    items: 2,
    dots: true,
    margin: 10,
    mouseDrag: false,
  };

  defaultLanguage;

  constructor(private chatService: ChatService, private modalService: NgbModal, private designService: DesignService, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.getJourneys();
    this.getSupportJourney();

    this.subscriptions['SessionJourney'] = this.designService.getSessionJourney().subscribe((response) => {
      if (this.subscriptions['AgentLanguage'] instanceof Subscription) this.subscriptions['AgentLanguage'].unsubscribe();

      this.subscriptions['AgentLanguage'] = this.designService.getSessionAgentLang().subscribe((lang) => {
        this.defaultLanguage = lang;
        this.activeJourney = response.journey;
        if (this.openChat) {
          this.initChatSession();
        } else if (this.botSession instanceof Chatbot && this.botSession.sessionKey !== this.activeJourney.apiKey) {
          this.botSession = null;
        }
      });
    });
    this.authenticationService.onChangeAccount.subscribe(() => {
      this.getSupportJourney();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngOnChanges(changes: any) {
    if (changes.openChat.currentValue && !(this.botSession instanceof Chatbot)) this.initChatSession();
  }

  initChatSession(sessionSettings?: any) {
    this.botSession = new Chatbot(new Date().getTime(), this.activeJourney.apiKey, this.defaultLanguage, sessionSettings);
    this.botSession.inputEvent = 'Welcome';
    this.submitEvent();
    this.subscriptions['BotRestore'] = this.botSession.onRestoreSession.subscribe((response) => {
      this.initChatSession(response);
    });
    this.subscriptions['BotRecording'] = this.botSession.onStopRecording.subscribe((response) => {
      this.sendMessage({ audio: response.audioBase64 });
    });
  }

  getJourneys() {
    this.subscriptions['JourneySubscription'] = this.designService.getJourneysList().subscribe((response: any) => {
      this.journeys = response;
    });
  }

  getSupportJourney() {
    this.subscriptions['JourneySubscription'] = this.designService.getJourney('support').subscribe(
      (response: any) => {
        this.supportJourney = response;
      },
      (error) => {
        this.supportJourney = null;
      }
    );
  }

  toggleChat(openStatus: boolean) {
    this.openChat = !openStatus;
    this.switchChat.emit(this.openChat);
  }

  switchJourneyType() {
    if (this.journeyType === 'support') {
      this.activeJourney = this.supportJourney;
    } else {
      this.activeJourney = this.designService.sessionJourney.journey;
    }
    this.initChatSession();
  }

  submitSpeech() {
    this.botSession.updateDialog('user', this.botSession.inputMessage);
    this.sendMessage({ userMessage: this.botSession.inputMessage });
  }

  submitEvent() {
    this.botSession.updateDialog('user', [{ type: 'event', event: this.botSession.inputEvent }]);
    this.sendMessage({
      event: { name: this.botSession.inputEvent },
    });
  }

  sendMessage(params: any) {
    const data = {
      lang: this.botSession.sessionLang,
      sessionId: this.botSession.sessionId,
      audioOutputRequired: this.botSession.outputAudio,
      query: params.userMessage || null,
      event: params.event ? this.botSession.formattedEvent : null,
      audio: params.audio || null,
      userData: Object.keys(this.botSession.sessionUserData).length ? this.botSession.sessionUserData : null,
    };

    if (this.activeJourney.fulfillment) {
      this.subscriptions['ChatSubscription'] = this.chatService.sendFulfillmentMessage(this.botSession.sessionKey, data).subscribe((response: Object) => {
        this.botSession.processFulfillmentResponse(response);
      });
    } else {
      this.subscriptions['ChatSubscription'] = this.chatService.sendMessage(this.botSession.sessionKey, data).subscribe((response: any) => {
        this.botSession.processOrchestrateResponse(response, !!params.audio);
      });
    }
  }

  speechRecording() {
    if (this.botSession.recorder) {
      this.botSession.stopRecording();
      return;
    }

    this.botSession.startRecording();
  }

  viewDebug() {
    const modalRef = this.modalService.open(ChatDebugModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.sessionDebug = this.botSession.sessionDebug;
  }

  editCustomData() {
    if (this.activeJourney.fulfillment) return;
    const modalRef = this.modalService.open(ChatSettingsModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.botSession = this.botSession;
  }

  selectGraphicElement(graphicItem: any, graphicElements: any) {
    this.botSession.inputMessage = graphicItem.value;

    switch (graphicElements.type) {
      case 'suggestions':
        delete graphicElements.items;
        break;
      case 'card':
      case 'list':
        if (graphicItem.url) {
          window.open(graphicItem.url);
          return;
        }
        break;
    }

    this.submitSpeech();
  }
}
