import { Component, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from '../../services/authentication.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnDestroy {
  @ViewChild('resetPasswordForm', { static: true }) private resetPasswordForm: NgForm;

  newPassowordSubscription: Subscription;
  user: any = {};

  get regExPattern() { return this.authenticationService.passwordRequirements.regExPattern; }

  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, private notificationService: NotificationService) { }

  ngOnDestroy() {
    if (this.newPassowordSubscription instanceof Subscription) this.newPassowordSubscription.unsubscribe();
  }

  setNewPassword() {
    if (this.resetPasswordForm.invalid || this.user.password !== this.user.confirmPassword) return;

    const data = {
      key: this.route.snapshot.queryParams.key,
      newPassword: this.user.password
    };
    this.newPassowordSubscription = this.authenticationService.newPassword(data).subscribe((response: any) => {
      this.notificationService.showToast('New Password was correctly set', { type: 'success' });
      this.router.navigate(['/login']);
    });
  }

  onTypePassword(popPassword: NgbPopover) {
    if (!(this.resetPasswordForm.controls.newPassword.errors || {}).pattern) {
      popPassword.close();
    }
  }

}
