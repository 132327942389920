import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { DesignService } from '../services/design.service';
import { NotificationService } from '../services/notification.service';


@Injectable({
  providedIn: 'root'
})
export class JourneyRestrictionGuard implements CanActivate {
  journeySubscription: Subscription;

  constructor(private router: Router, private designService: DesignService, private notificationService: NotificationService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve, reject) => {
      this.journeySubscription = this.designService.getSessionJourney().subscribe(() => {
        if (this.designService.isProductConfigured(next.data.journeyRestriction)) {
          resolve(true);
        } else {
          this.router.navigate(['']);
          this.notificationService.showToast('Your Journey is not configured for access this section', { type: 'warning' });
          reject(false);
        }
        if (this.journeySubscription instanceof Subscription) this.journeySubscription.unsubscribe();
      });
    });
  }
  
}
