import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { Entity } from '../interfaces/entity';

enum nluProducts {
  'DF' = 'Dialogflow ES',
  'CX' = 'Dialogflow CX',
}

@Injectable({
  providedIn: 'root'
})
export class DesignService {
  onChangeJourney: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  onUpdateJourneys: Subject<any> = new Subject<any>();
  onChangeAgent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  onChangeAgentLang: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public accountJourneys: any;
  public sessionJourney: any;
  public sessionAgent: any;
  public sessionAgentLang: string;

  constructor(private http: HttpClient, private commonService: CommonService) { }

  getSessionJourney(): Observable<any> {
    return this.onChangeJourney.asObservable().pipe(
      filter(journey => journey != null)
    );
  }

  getSessionAgent(): Observable<any> {
    return this.onChangeAgent.asObservable().pipe(
      filter(agent => agent != null)
    );
  }

  getSessionAgentLang(): Observable<any> {
    return this.onChangeAgentLang.asObservable().pipe(
      filter(language => language != null)
    );
  }

  setSessionJourney(journey: any) {
    this.sessionJourney = journey;
    this.onChangeAgent.next(null);
    this.onChangeAgentLang.next(null);
    this.onChangeJourney.next(journey);
    this.getAgentBotJourney().toPromise().then();
  }

  setSessionAgent(agent: any) {
    this.sessionAgent = agent;
    this.onChangeAgent.next(agent);
    this.setSessionAgentLang(agent.supportedLang[0]);
  }

  setSessionAgentLang(language: string) {
    this.sessionAgentLang = language;
    this.onChangeAgentLang.next(language);
  }

  updateJourneys(): Observable<any> {
    return this.onUpdateJourneys.asObservable();
  }

  changesJourneys() {
    this.onUpdateJourneys.next();
  }

  getJourneysList(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/journey`).pipe(
      tap((response: Array<any>) => {
        this.accountJourneys = response;
      })
    );
  }

  getJourney(journeyId: any): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/journey/${journeyId}`);
  }

  createJourney(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/journey`, data);
  }

  editJourney(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_configurator}/journey`, data);
  }

  deleteJourney(journeyId: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('journeyId', journeyId.toString())
    };
    return this.http.delete(`${environment.API.orchestrator_configurator}/journey`, httpOptions);
  }

  updateEmbeddedConfiguration(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_configurator}/journey/embeddedConfiguration`, data);
  }

  getConnectorsList(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/`);
  }

  getConnector(connectorName: string, connectorId: number): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/${connectorName}/${connectorId}`);
  }

  addConnector(connectorName: string, data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/${connectorName}`, data);
  }

  removeConnector(connectorName: string, connectorId: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('connectorId', connectorId.toString())
    };
    return this.http.delete(`${environment.API.orchestrator_configurator}/${connectorName}`, httpOptions);
  }

  editConnector(connectorName: string, data: any, params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
    };

    if (params && params.clearAnalytics) httpOptions.params = httpOptions.params.append('clearAnalytics', params.clearAnalytics);

    return this.http.put(`${environment.API.orchestrator_configurator}/${connectorName}`, data, httpOptions);
  }

  getAgentsBot(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/agentbot`);
  }

  getAgentsBotTypes(type: 'DIALOGFLOW_ES' | 'DIALOGFLOW_CX'): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('type', type)
    };

    return this.http.get(`${environment.API.orchestrator_configurator}/agentbot`, httpOptions);
  }

  getAgentBotJourney(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/agentbot/getByJourneyApiKey`).pipe(
      tap((response: any) => {
        this.setSessionAgent(response);
      })
    );
  }

  changeFulfilmentAgent(data: any, params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('agentId', params.agentId)
        .set('journeyId', params.journeyId)
    }
    return this.http.put(`${environment.API.orchestrator_configurator}/journey/changeAgent`, data, httpOptions);
  }
  
  getDlpInfoTypes(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/dlp/getAllInfoType`);
  }

  getTranslateLanguages(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/translate/languages`);
  }

  getFaqAnalyzes(params: any, pageNumber: number, pageSize: number) {
    const httpOptions = {
      params: new HttpParams()
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    };
    if (params.url) httpOptions.params = httpOptions.params.append('url', params.url);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);

    return this.http.get(`${environment.API.orchestrator_configurator}/faq`, httpOptions);
  }

  getFaqAnalysis(analysisId: number) {
    return this.http.get(`${environment.API.orchestrator_configurator}/faq/${analysisId}`);
  }
  
  getToneOfVoiceQuestions(lang: string): Observable<any> {
    return this.http.get(`./assets/tone-of-voice/i18n/questions_${lang}.json`);
  }

  getToneOfVoiceResults(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/personality/result`, data);
  }

  importToneOfVoiceResults(files: FileList): Observable<any> {
    let data = new FormData();
    data.append('file', files[0], files[0].name);

    return this.http.post(`${environment.API.orchestrator_configurator}/personality/result/upload`, data);
  }

  exportToneOfVoiceResults(data: any): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'blob'
    };

    return this.http.post(`${environment.API.orchestrator_configurator}/personality/result/download`, data, httpOptions).pipe(
      tap((response) => {
        this.commonService.downloadFile(response, 'blob', 'Tone of Voice Results.xlsx');
      })
    );
  }

  getEntityNames(params: any): Observable<Array<string>> {
    const httpOptions = {
      params: new HttpParams().set('agentLang', params.agentLang)
    };
    return this.http.get<Array<string>>(`${environment.API.orchestrator_configurator}/entities/getAllNames`, httpOptions);
  }

  getEntitiesList(params: any, pageNumber: number, pageSize: number): Observable<Array<Entity>> {
    const httpOptions = {
      params: new HttpParams()
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    };

    if (params.name) httpOptions.params = httpOptions.params.append('name', params.name);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    
    return this.http.get<Entity[]>(`${environment.API.orchestrator_configurator}/entities/getAll`, httpOptions);
  }

  addEntity(data: Entity): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/entities`, data);
  }

  removeEntity(entityId: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('id', entityId.toString()),
    };
    return this.http.delete(`${environment.API.orchestrator_configurator}/entities`, httpOptions);
  }

  editEntity(data: Entity): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_configurator}/entities`, data);
  }

  synchEntities(data: any, params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
    };

    if (params && params.journeyApiKey) httpOptions.params = httpOptions.params.append('journeyApiKey', params.journeyApiKey);
    return this.http.put(`${environment.API.orchestrator_configurator}/entities/synchronize`, data, httpOptions);
  }

  isProductConfigured(configuration: Array<any> | Array<string> | string, journeyId?: number | null): boolean {
    let journey = journeyId ? this.accountJourneys.find((item) => item.journey.id === journeyId) : this.sessionJourney;

    if (!Array.isArray(configuration)) configuration = [configuration];
    if (typeof configuration[0] !== 'string') return this.validateNluProduct(configuration, journey);

    if (journey.journey.fulfillment && (configuration.indexOf('CX') || configuration.indexOf('DF'))) {
      return configuration.some((pc) => journey.journey.fulfillmentAgent.agentType.toLowerCase() === nluProducts[pc].toLowerCase());
    } else {
      return journey.connectors.some((connector: any) => configuration.includes(connector.productCode));
    }
  }

  private validateNluProduct(settings: Array<any>, journey: any): boolean {
    let validation: boolean = true;

    settings.forEach((restriction: any) => {
      if ((restriction.product && this.isProductConfigured(restriction.product, journey.journey.id)) || !restriction.product) {
        for (const key in restriction) {
          if (Object.prototype.hasOwnProperty.call(restriction, key) && typeof restriction[key] === 'boolean') {
            if (restriction[key] !== journey.journey[key]) {
              validation = false;
              break;
            }
          }
        }
      }
    });

    return validation;
  }
}
