import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { LicenseService } from './license.service';
import { DesignService } from './design.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  onChangeAccount: Subject<any> = new Subject<any>();

  public user: any;
  private loggedIn: boolean = false;

  public passwordRequirements = {
    uppercaseMatch: /.*[A-Z].*/,
    lowercaseMatch: /.*[a-z].*/,
    numberOrSymbolMatch: /.*[0-9`~!@#$%^&*()_\-+={}[\]|:;"'<>,.?/].*/,
    lengthMatch: /^.{8,}$/,
    regExPattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9`~!@#$%^&*()_\-+={}[\]|:;"'<>,.?/])[A-Za-z0-9`~!@#$%^&*()_\-+={}[\]|:;"'<>,.?/]{8,}$/
  };

  constructor(private http: HttpClient, private router: Router, private modalService: NgbModal, private licenseService: LicenseService, private designService: DesignService) { }

  login(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/auth/login`, data);
  }

  logout(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/auth/logout`, data).pipe(
      tap(() => {
        this.eraseSession();
      })
    );
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/auth`).pipe(
      tap((response: any) => {
        this.setUser(response);
        this.licenseService.getLicenseItems().toPromise().then();
        this.designService.getJourneysList().toPromise().then((journeys: Array<any>) => {
          this.designService.setSessionJourney(journeys[0]);
        });
      })
    );
  }

  changeUserAccount(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_configurator}/accounts/default`, data).pipe(
      tap((response: any) => {
        this.licenseService.setSessionLicense(null);
        this.getCurrentUser().toPromise().then(() => {
          this.onChangeAccount.next(response);
        });
      })
    );
  }

  getAccountsUser(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/accounts`);
  }

  onChangeSessionAccount(): Observable<any> {
    return this.onChangeAccount.asObservable();
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/auth/reset-password/start`, data);
  }

  newPassword(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/auth/reset-password/complete`, data);
  }

  changePassword(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_configurator}/auth/verify`, data).pipe(
      tap((response: any) => {
        this.setUser(response);
      })
    );
  }

  refreshToken(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/auth/token`);
  }

  setUser(user: any) {
    this.user = user;
    this.loggedIn = (typeof user !== 'undefined' && user != null);
  }

  isAuthorized(restrictedRole: Array<string> | string): boolean {
    if (!Array.isArray(restrictedRole)) restrictedRole = [restrictedRole];

    return restrictedRole.some(restriction => this.user.authorities.includes(restriction));
  }

  isLogged(): boolean {
    return this.loggedIn;
  }

  eraseSession() {
    if (this.modalService.hasOpenModals()) this.modalService.dismissAll();

    delete this.user;
    this.loggedIn = false;
    this.router.navigate(['/login']);
  }

}
