import { Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { IntentModalComponent } from '../modals/intent-modal/intent-modal.component';
import { DesignService } from '../../services/design.service';
import { Pagination } from '../../classes/pagination';

@Component({
  selector: 'app-faq-processes',
  templateUrl: './faq-processes.component.html',
  styleUrls: ['./faq-processes.component.scss']
})
export class FaqProcessesComponent implements OnInit {
  processesSubscription: Subscription;
  processes: Array<any>;

  faqProcessesPagination: Pagination = new Pagination();

  filter: any = {
    sortBy: 'createdDate:desc'
  };

  constructor(private modalService: NgbModal, private designService: DesignService) { }

  ngOnInit() {
    this.getProcesses(1);
  }

  getProcesses(pageSelected: number) {
    this.faqProcessesPagination.onSelectPage(pageSelected);

    const params = {
      url: this.filter.url || null,
      sortBy: this.filter.sortBy || null
    };

    this.processesSubscription = this.designService.getFaqAnalyzes(params, this.faqProcessesPagination.getPageIndex(), this.faqProcessesPagination.pageSize).subscribe((response: any) => {
      this.processes = response.content;
      this.faqProcessesPagination.updateTotals(response.totalElements);
    })
  }

  intentModal() {
    const modalRef = this.modalService.open(IntentModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.createOption = 'importFaq';
  }

}
