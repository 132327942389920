import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DesignService } from '../../../../services/design.service';

const PARAMETER = 'P';
const PARAMETER_FROM_CONTEXT = 'PfC';
const TRIM = 'TRIM';
const REPLACE = 'REPLACE';

const requiredContextCondition: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const fieldToManipulate = control.get('fieldToManipulate').value;
  const contextName = control.get('contextName').value;
  if (fieldToManipulate === PARAMETER_FROM_CONTEXT && !contextName) {
    return {
      requiredContextName: true,
    }
  } else {
    return null
  }
};

const requiredActionCondition: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const inputValue = control.get('inputValue').value;
  const outputValue = control.get('outputValue').value;
  const actionType = control.get('actionType').value;
  if (actionType === REPLACE && (!inputValue || !outputValue)) {
    return {
      requiredInputValue: !inputValue,
      requiredOutputValue: !outputValue,
    }
  } else {
    return null
  }
};

@Component({
  selector: 'app-data-processing-connector-modal',
  templateUrl: './data-processing-connector-modal.component.html',
  styleUrls: ['./data-processing-connector-modal.component.scss']
})
export class DataProcessingConnectorModalComponent implements OnInit, OnDestroy {
  @Input() private journey: any;
  @Input() private connector: any;
  @Output() private onSaveConnector = new EventEmitter<any>();

  //FIELD TO MANIPULATE
  PARAMETER = PARAMETER;
  PARAMETER_FROM_CONTEXT = PARAMETER_FROM_CONTEXT;

  //ACTION TYPE
  TRIM = TRIM;
  REPLACE= REPLACE

  subscriptions: Object = {};
  dataprocessingForm = this.fb.group({
    fieldToManipulate: this.fb.control(this.PARAMETER, Validators.required),
    contextName: this.fb.control(''),
    parameterName: this.fb.control('', Validators.required),
    actions: this.fb.array([], [Validators.required, Validators.minLength(1)])
  }, {validators: requiredContextCondition});

  processingSubmitted: boolean = false;
  indexActionSelected;

  get actionsElements() { return this.dataprocessingForm.get('actions') as FormArray }
  get contextName() { return this.dataprocessingForm.get('contextName') as FormControl }
  get parameterName() { return this.dataprocessingForm.get('parameterName') as FormControl }
  get fieldToManipulate() { return this.dataprocessingForm.get('fieldToManipulate') as FormControl }
  get actionSelected () {return this.actionsElements.at(this.indexActionSelected) as FormGroup}

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private designService: DesignService) { }

  ngOnInit() {
    if (this.connector.id) {
      this.initForm();
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  addAction() {
    const actionGroup = this.getActionGroup();
    this.actionsElements.push(
      actionGroup
    );
    this.indexActionSelected = this.actionsElements.length - 1;
  }

  removeAction(index: number) {
    this.actionsElements.removeAt(index);
  }

  saveConnector() {
    this.processingSubmitted = true;
    if (this.dataprocessingForm.invalid) return;

    const data = { ...this.connector, ...this.generateObjectCall(), journeyId: this.journey.id };

    if (this.connector.id) {
      this.subscriptions['SaveConnector'] = this.designService.editConnector('dataProcessing', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    } else {
      this.subscriptions['SaveConnector'] = this.designService.addConnector('dataProcessing', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    let actionsArray = this.actionsElements.value;
    moveItemInArray(actionsArray, event.previousIndex, event.currentIndex);
    this.actionsElements.patchValue(actionsArray);
    this.indexActionSelected = event.currentIndex;
  }

  private initForm() {
    this.dataprocessingForm.patchValue(
      this.generateObjectForm()
    );
    this.connector.actions.forEach(action => {
      let actionForm: any = {
        actionType: action.actionType,
        inputValue: action.inputValue ? action.inputValue.join(',') : null,
        outputValue: action.outputValue
      }
      let actionGroup = this.getActionGroup();
      actionGroup.patchValue(actionForm);
      this.actionsElements.push(actionGroup);
    });
  }

  private getActionGroup() {
    return this.fb.group({
      actionType: this.fb.control(this.TRIM, Validators.required),
      inputValue: this.fb.control(''),
      outputValue: this.fb.control(''),
    }, { validators: requiredActionCondition })
  }

  private generateObjectForm() {
    const objectForm: any = {};
    if (this.connector.path.startsWith('$')) {
      objectForm.fieldToManipulate = this.PARAMETER;
      objectForm.parameterName = this.connector.path.substring(1)
    } else if (this.connector.path.startsWith('#')){
      objectForm.fieldToManipulate = this.PARAMETER_FROM_CONTEXT;
      objectForm.contextName = this.connector.path.substring(1).split('.')[0];
      objectForm.parameterName = this.connector.path.substring(1).split('.')[1];
    }
    return objectForm;
  }

  private generateObjectCall() {
    const objectCall: any = {};
    if (this.fieldToManipulate.value === this.PARAMETER) {
      objectCall.path = `$${this.parameterName.value}`;
    } else if (this.fieldToManipulate.value === this.PARAMETER_FROM_CONTEXT) {
      objectCall.path = `#${this.contextName.value}.${this.parameterName.value}`;
    }
    objectCall.actions = [];
    this.actionsElements.value.forEach(actionElement => {
      const actionCall = {
        actionType: actionElement.actionType,
        inputValue: actionElement.inputValue ? actionElement.inputValue.split(',') : undefined,
        outputValue: actionElement.outputValue
      }
      objectCall.actions.push(actionCall);
    })
    return objectCall;
  }

}
