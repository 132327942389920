import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { DecimalPipe } from '@angular/common';
import { ResizedEvent } from 'angular-resize-event';
import { AnalyticsService } from '../../../services/analytics.service';
import { DesignService } from '../../../services/design.service';

declare let google: any;

@Component({
  selector: 'analytic-sessions',
  templateUrl: './analytic-sessions.component.html',
  styleUrls: ['./analytic-sessions.component.scss']
})
export class AnalyticSessionsComponent implements OnInit, OnDestroy {
  @Input() filter?: any = {};
  @Input() processChartData: any;

  subscriptions: Object = {};

  conversationsStatistics: any;
  singleInteractionSessions: number;
  interactionsAverageSession: number;
  interactionsAverageDuration: number;
  interactionsChannel: any;
  popularTopicsAnalytics: any;
  popularLang: any;
  percentageVal: any;

  tags: Array<any> = [];

  toolTipsSession: any = {
    totalSessions: "A group of interactions (i.e. combined intents) between the chatbot and the end-user.",
    singleSession: "Sessions where users only trigger the Welcome intent.",
    messagesPerSession: "Average number of interactions (combined intents) throughout recorded sessions.",
    sessionDuration: "Average session duration (in minutes), starting from the first to the last query of the user.",
    channels: "Source channel of user's interactions.",
    popularTopics: "Most requested topics by users within conversations."
  };
  constructor(private analyticsService: AnalyticsService, private designService: DesignService, private decimalPipe: DecimalPipe) { }

  ngOnInit() {
    google.charts.load('current', { 'packages': ['corechart'] });
    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      this.filter.intentTag = '';
      this.getStatistics();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  onResized(event: ResizedEvent) {
    if (!this.conversationsStatistics) return;
    this.drawSessionsChart();
  }

  getStatistics() {
    const params = {
      startDate: this.filter.period.fromDate ? new Date(this.filter.period.fromDate.year, this.filter.period.fromDate.month - 1, this.filter.period.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.period.toDate ? new Date(this.filter.period.toDate.year, this.filter.period.toDate.month - 1, this.filter.period.toDate.day, 23, 59, 59).getTime() : null,
      tags: this.filter.intentTag || null
    };
    this.getConversationsStatistics(params);
    this.getSingleInteractionSessions(params);
    this.getInteractionsAverageSession(params);
    this.getInteractionsAverageDuration(params);
    this.getAgentChannelsStats(params);
    this.getPopularTopicsAnalytics(params);
    this.getPrecentageLang(params);
  }

  getConversationsStatistics(params: any) {
    this.subscriptions['ConversationsStatistics'] = this.analyticsService.getConversationsStatistics(params.startDate, params.endDate, params.tags).subscribe((response: any) => {
      this.conversationsStatistics = {
        total: response.multiObject.total.value,
        chartData: this.processChartData('ConversationsStatistics', response.multiObject.line.chart)
      };
      this.drawSessionsChart();
    });
  }

  getSingleInteractionSessions(params: any) {
    this.subscriptions['SingleInteractionSessions'] = this.analyticsService.getSingleInteractionSessions(params.startDate, params.endDate, params.tags).subscribe((response: any) => {
      this.singleInteractionSessions = response.singleResult.value;
    });
  }

  getInteractionsAverageSession(params: any) {
    this.subscriptions['InteractionsAverageSession'] = this.analyticsService.getInteractionsAverageSession(params.startDate, params.endDate, params.tags).subscribe((response: any) => {
      this.interactionsAverageSession = response.singleResult.value;
    });
  }

  getInteractionsAverageDuration(params: any) {
    this.subscriptions['InteractionsAverageDuration'] = this.analyticsService.getInteractionsAverageDuration(params.startDate, params.endDate, params.tags).subscribe((response: any) => {
      this.interactionsAverageDuration = response.singleResult.value * 60 * 1000;
    });
  }

  getAgentChannelsStats(params: any){
    this.subscriptions['InteractionsAgentChannel'] = this.analyticsService.getAgentChannels(params.startDate, params.endDate).subscribe((response: any) => {
      this.interactionsChannel  = {
        website: response.singleResult.find( ({ name }) => name === 'WEBSITE' ),
        app: response.singleResult.find( ({ name }) => name === 'APP' ),
        facebook: response.singleResult.find( ({ name }) => name === 'FACEBOOK' ),
        vivocha: response.singleResult.find( ({ name }) => name === 'VIVOCHA' ),
        whatsapp: response.singleResult.find( ({ name }) => name === 'WHATSAPP' ),
        test: response.singleResult.find( ({ name }) => name === 'TEST' ),
        timrcc: response.singleResult.find( ({ name }) => name === 'TIMRCC' ),
        liveperson: response.singleResult.find( ({ name }) => name === 'LIVEPERSON' ),
        undefined: response.singleResult.find( ({ name }) => name === 'UNDEFINED' )
      };
      this.drawAnalyticsChart();
    })
  }

  getPopularTopicsAnalytics(params: any) {
    this.subscriptions['PopularTopicsAnalytics'] = this.analyticsService.getPercentagePopularTags( params.startDate, params.endDate).subscribe((response: any) => {
      this.popularTopicsAnalytics = this.processChartData('PopularTopicsAnalytics', response.singleResult)
      this.drawPieChartPopular(this.popularTopicsAnalytics, 'populartopics_charts', 'popularTopics', {colors: ['#9816F4', '#1DC9B7', '#FD397A', '#00AFF0', '#FFB822', '#343A40']});
    })
  }

  getPrecentageLang(params: any) {
    this.subscriptions['PercentageLang'] = this.analyticsService.getPrecentageLang( params.startDate, params.endDate).subscribe((response: any) => {
      this.popularLang = this.processChartData('PercentageLang', response.singleResult)
      this.drawPieChartPopular(this.popularLang, 'popularlang_charts', 'popularLang');
    })
  }

  drawSessionsChart() {
    google.charts.setOnLoadCallback(() => {
      let data = google.visualization.arrayToDataTable(this.conversationsStatistics.chartData);

      let options = {
        curveType: 'none',
        legend: { position: 'none' },
        hAxis: {
          format: 'dd MMM y'
        },
        vAxis: {
          viewWindow: this.analyticsService.getAxisScale(this.conversationsStatistics.chartData)
        },
        chartArea: {
          left:35,
          'width': '95%'
        }
      };

      let chart = new google.visualization.LineChart(document.getElementById('sessions_chart'));

      chart.draw(data, options);
    });
  }

  drawAnalyticsChart() {
    google.charts.setOnLoadCallback(() => {

      this.percentageVal = {
        Website: this.interactionsChannel.website.perc,
        App: this.interactionsChannel.app.perc,
        Facebook: this.interactionsChannel.facebook.perc,
        Whatsapp: this.interactionsChannel.whatsapp.perc,
        Vivocha: this.interactionsChannel.vivocha.perc,
        'Tim Rcc': this.interactionsChannel.timrcc.perc,
        LivePerson : this.interactionsChannel.liveperson.perc,
        Test:this.interactionsChannel.test.perc,
        'Not specified': this.interactionsChannel.undefined.perc,
      }

      let data = google.visualization.arrayToDataTable([
        ['Channel', 'Value'],
        ['Website', this.interactionsChannel.website.number ],
        ['App', this.interactionsChannel.app.number ],
        ['Facebook', this.interactionsChannel.facebook.number ],
        ['Whatsapp', this.interactionsChannel.whatsapp.number ],
        ['Vivocha', this.interactionsChannel.vivocha.number ],
        ['Tim Rcc', this.interactionsChannel.timrcc.number ],
        ['LivePerson', this.interactionsChannel.liveperson.number ],
        ['Test', this.interactionsChannel.test.number ],
        ['Not specified', this.interactionsChannel.undefined.number],
      ]);

      let options = {
        pieHole: 0.8,
        legend: {
          position: 'right',
          alignment: 'center', 
          textStyle: { 
            fontSize: 11,
            fontName: 'Poppins, sans-serif'
          }
        },
        colors: ['#647DFC', '#F5008C','#33032F', '#531253', '#BDB76B', '#FF7733', '#9400D3'],
        pieSliceText: 'none',
        titlePosition: 'top',
        height: 250,
        chartArea: 
        {
          width: '85%', 
          height: '85%'
        }
      };
      let chart = new google.visualization.PieChart(document.getElementById('channel_chart'));

      google.visualization.events.addListener(chart, 'ready', () => {
        const svg = document.getElementById('channel_chart').querySelector('svg');
        const legends = svg.querySelectorAll('g')[0].querySelectorAll('text');
        legends.forEach((legend) => {
          legend.textContent = `${legend.textContent} (${this.decimalPipe.transform(this.percentageVal[legend.textContent], '1.2-2')}%)`
        })
      });

      chart.draw(data, options);
    });
  }

  drawPieChartPopular(dataChart: any[], chartName: string, chartType: 'popularTopics' | 'analyticsChart' | 'popularLang', chartOptions?: {colors?: string[]}) {
    google.charts.setOnLoadCallback(() => {
      let data;

      let options: any = {
        pieHole: 0.8,
        legend: {
          position: 'right',
          alignment: 'center', 
          textStyle: { 
            fontSize: 11,
            fontName: 'Poppins, sans-serif'
          }
        },
        pieSliceText: 'none',
        titlePosition: 'top',
        height: 250,
        chartArea: {
          width: '85%', 
          height: '85%'
        }
      };

      if (this.isEmptyChart(chartType)) {
        data = new google.visualization.DataTable();
        data.addColumn('string', 'Tags');
        data.addColumn('number', 'Value');
        data.addColumn({type: 'string', role: 'tooltip'});
        data.addRows([
          ['No data', 100, 'No data']
        ]);
        options.colors = ['#E3E3E3'];
        options.legend = 'none';
      } else {
        data = google.visualization.arrayToDataTable(dataChart.map(el => [el[0], el[1]]));
        options.colors = chartOptions ? chartOptions.colors : undefined;
      }

      let chart = new google.visualization.PieChart(document.getElementById(chartName));

      google.visualization.events.addListener(chart, 'ready', () => {
        const svg = document.getElementById(chartName).querySelector('svg');
        const legends = svg.querySelectorAll('g')[0].querySelectorAll('text');
        legends.forEach((legend, index) => {
          legend.textContent = `${legend.textContent} (${this.decimalPipe.transform(dataChart[index + 1][2], '1.2-2')}%)`
        })
      }); 

      chart.draw(data, options);
    });
  }

  isEmptyChart(chartType: 'popularTopics' | 'analyticsChart' | 'popularLang'): boolean {
    if (chartType === 'analyticsChart') {
      return !(Object.keys(this.interactionsChannel).some(channel => this.interactionsChannel[channel].number > 0));
    } else if (chartType === 'popularTopics') {
      return (this.popularTopicsAnalytics && this.popularTopicsAnalytics.length === 1);
    } else if (chartType === 'popularLang') {
      return (this.popularLang && this.popularLang.length === 1);
    }
  }

}
