import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MonitoringService } from 'src/app/services/monitoring.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'liveperson-agent-status-card',
  templateUrl: './liveperson-agent-status-card.component.html',
  styleUrls: ['./liveperson-agent-status-card.component.scss']
})
export class LivepersonAgentStatusCardComponent {

  @Input() status: string;
  @Input() name: string;

  @Output() removeAgent= new EventEmitter<any>();
  @Output() changeStatus = new EventEmitter<string>();

  isRefreshed = true;
  isRestarted = true;


  constructor(private monitoringService: MonitoringService, private notificationService: NotificationService) {}

  restartAgent() {
    this.isRestarted = false;
    this.monitoringService.restartLivepersonAgent([this.name]).subscribe(() => {
      this.isRestarted = true;
      this.notificationService.showToast('The agent was restarted. To monitor the status, press the refresh button.', { type: 'success' });
      this.refreshAgentStatus();
    }, () => {
      this.isRestarted = true;
    })
  }

  refreshAgentStatus() {
    this.isRefreshed = false;
    this.monitoringService.getAgentHealth([this.name]).subscribe(res => {
      const agent = res.agents[this.name];
      if (!agent) {
        this.removeAgent.emit();
      } else {
        if (agent.status !== this.status) {
          this.status = agent.status;
          this.changeStatus.emit(agent.status);
        }
      }
      this.isRefreshed = true
    }, () => {
      this.isRefreshed = true;
    })
  }

}
