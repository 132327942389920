import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss']
})
export class DatepickerRangeComponent {
  @Input() fromDate: NgbDate;
  @Input() toDate: NgbDate;

  @Output() onChangeDate = new EventEmitter<any>();

  hoveredDate: NgbDate;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) { }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.onChangeDate.emit({ date: date, type: 'from' });
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.onChangeDate.emit({ date: date, type: 'to' });
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.onChangeDate.emit({ date: null, type: 'to' });
      this.onChangeDate.emit({ date: date, type: 'from' });
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate, input: string, rangeType: 'from' | 'to'): NgbDate {
    const parsed = this.formatter.parse(input);
    if ( parsed && this.calendar.isValid(NgbDate.from(parsed)) ) {
      this.onChangeDate.emit({ date: NgbDate.from(parsed), type: rangeType });
      return NgbDate.from(parsed);
    }
    return null;
  }

}
