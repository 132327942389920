import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private http: HttpClient, private commonService: CommonService) { }

  getAxisScale(chartData: Array<Array<any>>): any {
    const valuesFound = (item: Array<any>, index: number) => index !== 0 && item[1] > 0;
    if (!chartData.some(valuesFound)) {
      return {
        max: 100,
        min: 0
      }
    }
    return {};
  }

  getConversationsStatistics(startDate: number, endDate: number, tags?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
        .set('fillEmptyDate', 'true')
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/generic/conversations`, httpOptions);
  }
  
  getSingleInteractionSessions(startDate: number, endDate: number, tags?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/single/interactions/total`, httpOptions);
  }

  getInteractionsAverageSession(startDate: number, endDate: number, tags?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/media/conversations/messages`, httpOptions);
  }

  getInteractionsAverageDuration(startDate: number, endDate: number, tags?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/media/duration/conversations`, httpOptions);
  }

  getTopIntentEngagement(params: any, pageNumber: number, pageSize: number): Observable<any>{
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', params.startDate)
        .set('endDate', params.endDate)
        .set('pageSize', pageSize.toString())
        .set('pageIndex', pageNumber.toString()),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    if (params.intentLike) httpOptions.params = httpOptions.params.append('intentLike', params.intentLike);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/engagement/intents`, httpOptions);
  }

  getTopFallbackEngagement(params: any, pageNumber: number, pageSize: number): Observable<any>{
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', params.startDate)
        .set('endDate', params.endDate)
        .set('pageSize', pageSize.toString())
        .set('pageIndex', pageNumber.toString())
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    if (params.intentLike) httpOptions.params = httpOptions.params.append('intentLike', params.intentLike);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/engagement/fallbacks`, httpOptions);
  }
  
  getAverageScore(params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
    };
    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/media/dialogflow/confidence`, httpOptions);
  }
  
  getFallbackPercentage(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
    };
    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.intentLike) httpOptions.params = httpOptions.params.append('intentLike', params.intentLike);
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/percente/fallback/messages`, httpOptions);
  }
  
  getAgentAutonomy(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
    };
    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/agentAutonomy`, httpOptions);
  }

  getTrustedIntents(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
    };
    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/trustedIntents`, httpOptions);
  }

  getFeedback(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
    };
    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/average/feedback`, httpOptions);
  }

  getRequestForAnOperator(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
    };
    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/requestsForAnOperator`, httpOptions);
  }

  getAgentChannels(startDate: number, endDate:number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    }

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/percent/agentChannel`, httpOptions);
  }
  
  getPercentagePopularTags(startDate: number, endDate:number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())

    }

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/percent/tags`, httpOptions);
  }

  getPrecentageLang(startDate: number, endDate:number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())

    }

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/percent/lang`, httpOptions);
  }

  getPercentagePrecision(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
    }
    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/percent/precision`, httpOptions);
  }  
  
  getPercentageRecall(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
    }
    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/percent/recall`, httpOptions);
  }

  getPrecisionAndRecall(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/precisionAndRecall`);
  }

  getPrecisionAndRecallForTable(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('pageIndex', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    };

    if (Number.isInteger(params.startPrecision)) httpOptions.params = httpOptions.params.append('startPrecision', params.startPrecision);
    if (Number.isInteger(params.endPrecision)) httpOptions.params = httpOptions.params.append('endPrecision', params.endPrecision);
    if (Number.isInteger(params.startRecall)) httpOptions.params = httpOptions.params.append('startRecall', params.startRecall);
    if (Number.isInteger(params.endRecall)) httpOptions.params = httpOptions.params.append('endRecall', params.endRecall);
    if (params.intentName) httpOptions.params = httpOptions.params.append('intentName', params.intentName);
    if (params.status) httpOptions.params = httpOptions.params.append('status', params.status);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/precisionAndRecallForTable`, httpOptions);
  }

  exportConversationalHistory(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('journeyApiKey', params.journeyApiKey),
      responseType: 'blob' as 'blob'
    };
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/exportDataAnalytics`, httpOptions).pipe(
      tap((response) => {
        this.commonService.downloadFile(response, 'blob', 'Export History.zip');
      })
    );
  }

  getAllReport() {
    return this.http.get(`${environment.API.orchestrator_analysis}/customkpi/getAllReport`);
  }

  createReport(data: any) {
    return this.http.post(`${environment.API.orchestrator_analysis}/customkpi/createReport`, data);
  }

  deleteReport(reportId: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('reportId', reportId)
    }
    return this.http.delete(`${environment.API.orchestrator_analysis}/customkpi/deleteReport`, httpOptions);
  }

  getQueryResult(reportName: string, params: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('reportName', reportName)
    }
    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.onlyTable != null) httpOptions.params = httpOptions.params.append('onlyTable', params.onlyTable);

    return this.http.get(`${environment.API.orchestrator_analysis}/customkpi/getQueryResult`, httpOptions);
  }

  createChart(data: any) {
    return this.http.post(`${environment.API.orchestrator_analysis}/customkpi/createChart`, data);
  }

  updateChart(data: any) {
    return this.http.put(`${environment.API.orchestrator_analysis}/customkpi/updateChart`, data);
  }

  deleteChart(chartId: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('chartId', chartId)
    }
    return this.http.delete(`${environment.API.orchestrator_analysis}/customkpi/deleteChart`, httpOptions);
  }

  exportAnalytics(params: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', params.startDate)
        .set('endDate', params.endDate),
      responseType: 'blob' as 'blob'
    }
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/exportAnalytics`, httpOptions).pipe(
      tap((response) => {
        this.commonService.downloadFile(response, 'blob', 'Analytics Data.xlsx')
      })
    );
  }

  getHealthCheckIntent(params: any, pageNumber: number, pageSize: number) {
    const httpOptions = {
      params: new HttpParams()
        .set('pageSize', pageSize.toString())
        .set('pageNumber', pageNumber.toString())
    };

    if (params.severity) {
      params.severity.forEach(item => {
        httpOptions.params = httpOptions.params.append('severity', item);
      })
    }
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    if (params.intentName) httpOptions.params = httpOptions.params.append('intentName', params.intentName);
    if (params.errorMessage) httpOptions.params = httpOptions.params.append('errorMessage', params.errorMessage);

    return this.http.get(`${environment.API.orchestrator_analysis}/health/intent`, httpOptions);
  }

  getHealthCheckEntity(params: any, pageNumber: number, pageSize: number) {
    const httpOptions = {
      params: new HttpParams()
        .set('pageSize', pageSize.toString())
        .set('pageNumber', pageNumber.toString())
    };

    if (params.severity) {
      params.severity.forEach(item => {
        httpOptions.params = httpOptions.params.append('severity', item);
      })
    }
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    if (params.intentName) httpOptions.params = httpOptions.params.append('intentName', params.intentName);
    if (params.errorMessage) httpOptions.params = httpOptions.params.append('errorMessage', params.errorMessage);

    return this.http.get(`${environment.API.orchestrator_analysis}/health/entity`, httpOptions);
  }

  getHealthCheckAgent(params: any, pageNumber: number, pageSize: number) {
    const httpOptions = {
      params: new HttpParams()
        .set('pageSize', pageSize.toString())
        .set('pageNumber', pageNumber.toString())
    };
    if (params.severity) {
      params.severity.forEach(item => {
        httpOptions.params = httpOptions.params.append('severity', item);
      })
    }
    if (params.errorMessage) httpOptions.params = httpOptions.params.append('errorMessage', params.errorMessage);

    return this.http.get(`${environment.API.orchestrator_analysis}/health/agent`, httpOptions);
  }
}
