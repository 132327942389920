import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../../../services/common.service';
import { NotificationService } from '../../../../services/notification.service';
import { DesignService } from '../../../../services/design.service';

@Component({
  selector: 'app-chat-widget-item-modal',
  templateUrl: './chat-widget-item-modal.component.html',
  styleUrls: ['./chat-widget-item-modal.component.scss']
})
export class ChatWidgetItemModalComponent implements OnInit, OnDestroy {
  @ViewChild('widgetForm', { static: true }) private widgetForm: NgForm;

  subscriptions: Object = {};

  currentJounrey: Object;
  configuration: any = {};

  get embeddedCode() {
    return `
    <script type="module">
        import embedded_chatbot from '${environment.BASE_PATH}/chat-widget/libs/js/bot-widget.js';

        (function () {
            let chatbot = new embedded_chatbot({
                apiKey: "${this.designService.sessionJourney.journey.apiKey}",
                outputAudio: ${this.configuration.outputAudio},
                translateResult: ${this.configuration.translateResult},
                agentName: ${(this.configuration.agentName ? '"' + this.configuration.agentName + '"' : null)},
                agentIcon: ${(this.configuration.agentIcon ? '"' + this.configuration.agentIcon + '"' : null)},
                titleColor: ${(this.configuration.titleColor ? '"' + this.configuration.titleColor + '"' : null) },
                topbarColor: ${(this.configuration.topbarColor ? '"' + this.configuration.topbarColor + '"' : null) },
                ballonsFont: ${(this.configuration.ballonsFont ? '"' + this.configuration.ballonsFont + '"' : null) },
                ballonsBackground: ${(this.configuration.ballonsBackground ? '"' + this.configuration.ballonsBackground + '"' : null) }
            });
        })();
    </script>`;
  }

  constructor(public activeModal: NgbActiveModal, private designService: DesignService, public commonService: CommonService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.subscriptions['SessionJourney'] = this.designService.getSessionJourney().subscribe((response) => {
      const configuration = response.journey.embeddedConfiguration || {};
      this.configuration = {...configuration,
        outputAudio: configuration.outputAudio || false,
        translateResult: configuration.translateResult || false,
        agentIcon: configuration.agentIcon || 'widget_toggle-1.png'
      };
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  saveConfiguration() {
    if (this.widgetForm.invalid) return;

    this.subscriptions['SaveConfiguration'] = this.designService.updateEmbeddedConfiguration(this.configuration).subscribe(() => {
      this.notificationService.showToast('Embedded configuration correctly saved', { type: 'success' });

      this.subscriptions['UpdateSessionJourney'] = this.designService.getJourney(this.designService.sessionJourney.journey.id).subscribe((response) => {
        this.designService.setSessionJourney(response);
      });
    });
  }

}
