import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { ResizedEvent } from 'angular-resize-event';
import { Options } from 'ng5-slider';
import { Subscription } from 'rxjs';

import { Pagination } from 'src/app/classes/pagination';
import { AnalyticsService } from '../../../services/analytics.service';
import { DesignService } from '../../../services/design.service';

declare let google: any;

@Component({
  selector: 'analytic-proficiency',
  templateUrl: './analytic-proficiency.component.html',
  styleUrls: ['./analytic-proficiency.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnalyticProficiencyComponent implements OnInit, OnDestroy {
  @Input() filter?: any = {};
  @Input() processChartData: any;

  subscriptions: Object = {};
  agentAutonomy: any = {};
  averageScore: number;
  percentagePrecision: number;
  percentageRecall: number;
  trustedIntents: any = {};
  proficiencyStatistics: any;
  precisionAndRecallForTable: any;

  chartDistribution: 'precision' | 'recall' = 'precision';
  precisionAndRecall: any;
  precisionAndRecallPagination: Pagination = new Pagination();
  intentFilter: any = {
    startPrecision: 0,
    endPrecision: 100,
    startRecall: 0,
    endRecall: 100,
    status: "",
    intentName: ""
  };

  sliderOptions: Options = {
    floor: 0,
    ceil: 100,
    hideLimitLabels: true
  };

  toolTipsProficiency: any = {
    virtualAgentAutonomy: "Percentage of conversations handled independently by the agent compared to the ones that needed human action.",
    trustedIntents: "Number of trusted intents and percentage compared to the total amount of intents.",
    precision: "Average Weighted Precision: Average percentage of actually correct positive identifications weighted by the number of occurrences, i.e. the ratio between true positives and true positives added to false positives, weighted by the number of occurrences of the intent.",
    recall: "Average Weighted Recall: Average percentage of actual positives correctly identified weighted by the number of occurrences, i.e. the ratio between true positives and true positives added to false negatives, weighted by the number of occurrences of the intent.",
    confidence: "Average virtual agent confidence in intent detection. Values range from 0.0 (completely uncertain) to 1.0 (completely certain).",
    feedback: "Average of the marks assigned by the operators to the phrase-intent associations proposed by the virtual agent."
  };

  scoreRange: number = 5;
  get distribution(): Array<any> {
    let rangeDistribution: Array<any> = [];
    let distributionIndex: number = 0;

    while (distributionIndex < 100) {
      rangeDistribution.push({
        min: distributionIndex,
        max: (distributionIndex === 95) ? distributionIndex + this.scoreRange : distributionIndex + (this.scoreRange - 1)
      });
      distributionIndex += this.scoreRange;
    }

    return rangeDistribution;
  }

  constructor(private analyticsService: AnalyticsService, private designService: DesignService) { }

  ngOnInit() {
    google.charts.load('current', { 'packages': ['corechart'] });
    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      if (this.subscriptions['AgentLanguage'] instanceof Subscription) this.subscriptions['AgentLanguage'].unsubscribe();
      
      this.subscriptions['AgentLanguage'] = this.designService.getSessionAgentLang().subscribe(() => {
        this.filter.intentTag = '';
        this.getStatistics();
      })
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }
  
  getStatistics() {
    const params = {
      startDate: this.filter.period.fromDate ? new Date(this.filter.period.fromDate.year, this.filter.period.fromDate.month - 1, this.filter.period.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.period.toDate ? new Date(this.filter.period.toDate.year, this.filter.period.toDate.month - 1, this.filter.period.toDate.day, 23, 59, 59).getTime() : null,
      tags: this.filter.intentTag || null
    };
    this.getAgentAutonomy(params);
    this.getTrustedIntents(params);
    this.getAverageScore(params);
    this.getPercentagePrecision(params);
    this.getPercentageRecall(params);
    this.getPrecisionAndRecall();
    this.searchPrecisionAndRecall(1);
  }

  updateDate($event) {
    if ($event.type === 'from') this.filter.period.fromDate = $event.date;
    if ($event.type === 'to') this.filter.period.toDate = $event.date;
  }

  parseBarValue(value: number): number {
    if (isNaN(value)) return 0;
    return Number(value.toFixed(2));
  }

  onResized(event: ResizedEvent) {
    if (!this.precisionAndRecall) return;
    this.drawProficiencyChart();
  }

  getAgentAutonomy(params: any) {
    this.subscriptions['AgentAutonomy'] = this.analyticsService.getAgentAutonomy(params).subscribe((response: any) => {
      this.agentAutonomy = response.singleResult;
    });
  }
  
  getTrustedIntents(params: any) {
    this.subscriptions['TrustedIntents'] = this.analyticsService.getTrustedIntents(params).subscribe((response: any) => {
      this.trustedIntents = response.singleResult;
    });
  }

  getPercentagePrecision(params: any) {
    this.subscriptions['PercentagePrecision'] = this.analyticsService.getPercentagePrecision(params).subscribe((response: any) => {
      this.percentagePrecision = response.singleResult;
    });
  }
  
  getPercentageRecall(params: any) {
    this.subscriptions['PercentageRecall'] = this.analyticsService.getPercentageRecall(params).subscribe((response: any) => {
      this.percentageRecall = response.singleResult;
    });
  }

  getAverageScore(params: any) {
    this.subscriptions['AverageScore'] = this.analyticsService.getAverageScore(params).subscribe((response: any) => {
      this.averageScore = response.singleResult.value;
    });
  }

  getPrecisionAndRecall() {
    this.subscriptions['PrecisionAndRecall'] = this.analyticsService.getPrecisionAndRecall().subscribe((response: any) => {
      this.precisionAndRecall = response.singleResult;
      this.drawProficiencyChart();
    });
  }

  drawProficiencyChart() {
    google.charts.setOnLoadCallback(() => {
      let scoreDistribution = this.processScoreDistribution(this.precisionAndRecall, this.chartDistribution);
      let data = google.visualization.arrayToDataTable(scoreDistribution);
      
      let options = {
        legend: { position: 'none'},
        titlePosition: 'none',
        orientation: 'horizontal',
        chartArea: { width: '94%', height: '68%' },
        hAxis: {
          textStyle: { fontSize: 12 }
        },
        vAxis: {
          viewWindow: this.analyticsService.getAxisScale(scoreDistribution)
        }
      };
      let chart = new google.visualization.ColumnChart(document.getElementById('proficiency_chart'));
      
      google.visualization.events.addListener(chart, 'ready', changeBorderRadius);
      google.visualization.events.addListener(chart, 'select', changeBorderRadius);
      google.visualization.events.addListener(chart, 'onmouseover', changeBorderRadius);
      google.visualization.events.addListener(chart, 'onmouseout', changeBorderRadius);

      var colors = ['#3366cc'];

      function changeBorderRadius() {
        var chartColumns = document.getElementById('proficiency_chart').getElementsByTagName('rect');
        Array.prototype.forEach.call(chartColumns, function(column) {
          if ((colors.indexOf(column.getAttribute('fill')) > -1) ||
              (column.getAttribute('fill') === 'none') ||
              (column.getAttribute('stroke') === '#ffffff')) {
            column.setAttribute('rx', 3);
            column.setAttribute('ry', 3);
          }
        });
      }
      chart.draw(data, options);
    });
  }

  processScoreDistribution(data: any, target: 'precision' | 'recall'): Array<Array<any>> {
    let chartData: Array<any> = [['Interval', 'Intents', { role: 'style' }]];
    let intervalValues: any = {};

    data.forEach((intent: any) => {
      if (isNaN(intent[target])) return;

      if (intervalValues[this.getInterval(intent[target])]) intervalValues[this.getInterval(intent[target])] += 1;
      else intervalValues[this.getInterval(intent[target])] = 1;
    });

    this.distribution.forEach((interval: any) => {
      if (!intervalValues[`${interval.min}-${interval.max}`]) intervalValues[`${interval.min}-${interval.max}`] = 0;
    });

    for (const key in intervalValues) {
      if (intervalValues.hasOwnProperty(key)) {
        chartData.push([key, intervalValues[key], this.getRangeColor(key)]);
      }
    }

    return this.sortInterval(chartData);
  }

  getInterval(value: number): string {
    for (const interval of this.distribution) {
      if (Math.round(value * 100) <= interval.max && Math.round(value * 100) >= interval.min) {
        return `${interval.min}-${interval.max}`;
      }
    }
  }

  sortInterval(chartData: Array<Array<any>>): Array<Array<any>> {
    return chartData.sort((a, b) => {
      if (a[0] === 'Interval') return -1;

      let minIntervalA = parseInt(a[0].split('-')[0]);
      let minIntervalB = parseInt(b[0].split('-')[0]);

      if (minIntervalA < minIntervalB) return -1;
      if (minIntervalA > minIntervalB) return 1;
      return 0;
    });
  }

  getRangeColor(interval: string): string {  
    let minRange = parseInt(interval.split('-')[0]);
    let maxRange = parseInt(interval.split('-')[1]);

    if (maxRange <= 39) {
      return '#dc3545';
    } else if (minRange > 39 && maxRange <= 79) {
      return '#ffc107';
    } else if (maxRange > 79) {
      return '#28a745';
    }
  }

  searchPrecisionAndRecall(pageSelected: number) {
    this.precisionAndRecallPagination.onSelectPage(pageSelected);

    this.subscriptions['PrecisionAndRecallForTable'] = this.analyticsService.getPrecisionAndRecallForTable(this.intentFilter, this.precisionAndRecallPagination.getPageIndex(), this.precisionAndRecallPagination.pageSize).subscribe((response: any) => {
      this.precisionAndRecallForTable = response.singleResult.content;
      this.precisionAndRecallPagination.updateTotals(response.singleResult.totalElements);
    });
  }

}