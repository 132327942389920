import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class IntentParametersService {
  responseForm: FormGroup;

  get graphics() { return this.responseForm.get('graphics') as FormArray }
  get textResponses() { return this.responseForm.get('response') as FormArray }
  get context() { return this.responseForm.get('context') as FormArray }

  constructor(private fb: FormBuilder) { }

  initForm() {
    this.responseForm = this.getItemGroup('responseForm');
  }

  fillForm(intentResponse: any) {
    this.fillArrayGroup(intentResponse);
    this.responseForm.patchValue({
      intentId: intentResponse.intentId,
      param1Name: intentResponse.param1Name, param1Value: intentResponse.param1Value,
      param2Name: intentResponse.param2Name, param2Value: intentResponse.param2Value,
      param3Name: intentResponse.param3Name, param3Value: intentResponse.param3Value,
      event: intentResponse.event,
      language: intentResponse.language,
      response: intentResponse.response || [],
      payload: intentResponse.payload,
      graphics: intentResponse.graphics || [],
      context: intentResponse.context || []
    });
  }

  addGraphicElement(payloadType: 'suggestions' | 'card' | 'link' | 'list') {
    this.graphics.push(
      this.fb.group({
        type: payloadType,
        iterable: false,
        items: this.fb.array([this.getItemGroup(payloadType)])
      })
    )
  }
  removeGraphicElement(elementIndex: number) {
    this.graphics.removeAt(elementIndex);
  }

  addGraphicItem(elementIndex: number, payloadType: 'suggestions' | 'card' | 'link' | 'list') {
    let payloadElement = this.graphics.at(elementIndex).get('items') as FormArray;
    payloadElement.push(this.getItemGroup(payloadType));
  }

  fillArrayGroup(intentResponse: any) {
    if (Array.isArray(intentResponse.graphics)) {
      intentResponse.graphics.forEach(element => {
        this.graphics.push(
          this.fb.group({
            type: element.type,
            iterable: element.iterable,
            items: this.fillArrayItems(element.items, element.type)
          })
        )
      })
    }
    if (Array.isArray(intentResponse.context)) intentResponse.context.forEach(element => this.fillContextGroup((element.lifespan)));
    if (Array.isArray(intentResponse.response)) intentResponse.response.forEach(element => this.addTextResponse());
  }
  fillArrayItems(items, type) {
    let arrayGroup: FormArray = this.fb.array([]);
    items.forEach(item => arrayGroup.push(this.getItemGroup(type)));
    return arrayGroup;
  }

  /* TEXT RESPONSES */
  addTextResponse() {
    this.textResponses.push(this.getItemGroup('text'));
  }
  removeTextResponse(textIndex: number) {
    this.textResponses.removeAt(textIndex);
  }

  /* CONTEXTS */
  fillContextGroup(outputContext: boolean) {
    if (outputContext) {
      this.context.push(this.fb.group({ lifespan: [''], name: [''] }));
    } else {
      this.context.push(this.fb.group({ name: [''] }));
    }
  }
  addContext($event, responseType: 'response' | 'event') {
    $event.preventDefault();

    if (responseType === 'response') {
      this.context.push(this.fb.group({ lifespan: 1, name: $event.target.value }));
    } else if (responseType === 'event') {
      this.context.push(this.fb.group({ name: $event.target.value }));
    }
    $event.target.value = '';
  }
  removeContext(contextIndex: number) {
    this.context.removeAt(contextIndex);
  }

  getItemGroup(payloadType: 'text' | 'suggestions' | 'card' | 'link' | 'list' | 'button' | 'responseForm'): FormGroup {
    switch (payloadType) {
      case 'responseForm':
        return this.fb.group({
          intentId: ['', Validators.required],
          param1Name: [''], param1Value: [''],
          param2Name: [''], param2Value: [''],
          param3Name: [''], param3Value: [''],
          event: ['', Validators.required],
          response: this.fb.array([]),
          payload: [''],
          language: [''],
          graphics: this.fb.array([]),
          context: this.fb.array([])
        });
      case 'text':
        return this.fb.group({ iterable: false, text: '' })
      case 'suggestions':
        return this.fb.group({ title: ['', Validators.required], value: ['', Validators.required] })
      case 'card':
        return this.fb.group({
          title: ['', Validators.required],
          subtitle: [''],
          body: [''],
          image: ['', Validators.required],
          button: this.getItemGroup('button')
        });
      case 'link':
        return this.fb.group({ title: ['', Validators.required], url: ['', Validators.required] });
      case 'list':
        return this.fb.group({ title: ['', Validators.required], subtitle: [''], image: [''], button: this.getItemGroup('button') });
      case 'button':
        return this.fb.group({ type: ['', Validators.required], value: [''], url: [''], target: [''] });
    }
  }

}
