import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { ExitButton, NextButton, BackButton } from 'src/app/classes/tutorials/buttons.tutorial';
import { Step } from 'src/app/classes/tutorials/step.tutorial';
import { IntentModalComponent } from 'src/app/components/modals/intent-modal/intent-modal.component';
import { CREATE_INTENTS_TUTORIAL_SEQUENCE } from './create-intents-tutorial.conversation-flow.service';

@Injectable({
  providedIn: 'root',
})
export class CreateIntentsTutorialIntentModalService {
  private component: IntentModalComponent;
  exitCallback;

  constructor(private shepherdService: ShepherdService, private router: Router) {
    this.shepherdService.modal = true;
    this.exitCallback = () => {
      this.router.navigate(['/conversation-flow']);
      this.shepherdService.cancel();
    };
  }

  startTutorial(component) {
    this.component = component;
    if (this.shepherdService.tourName === CREATE_INTENTS_TUTORIAL_SEQUENCE.FIRST) {
      this.createIntentTutorial();
    } else {
      this.editIntentTutorial();
    }
  }
  createIntentTutorial() {
    if (this.shepherdService.isActive) {
      this.shepherdService.complete();
    }
    this.shepherdService.tourName = CREATE_INTENTS_TUTORIAL_SEQUENCE.SECOND;

    this.shepherdService.addSteps([
      new Step({
        attachTo: {
          element: '#createIntentRadio',
          on: 'bottom',
        },
        padding: 5,
        title: 'Create intent',
        text: 'By choosing "Create Intent" you can assign it a name and input and output contexts to link them to other intents and create a flow.',
        buttons: [new ExitButton(this.exitCallback), new NextButton()],
      }),
      new Step({
        attachTo: {
          element: '#createIntentNameWrapper',
          on: 'bottom',
        },
        padding: 5,
        title: `Intent's name`,
        text: 'Name the intent, choose a unique one that allows you to recognize the intent easily.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton()],
      }),
      new Step({
        attachTo: {
          element: '#saveIntentButton',
          on: 'bottom',
        },
        padding: 5,
        title: `Save intent`,
        text: 'Click on "Save" to save the intent.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton(() => this.saveIntentAndContinue())],
      }),
    ]);
    this.shepherdService.start();
  }

  editIntentTutorial() {
    if (this.shepherdService.isActive) {
      this.shepherdService.complete();
    }
    this.shepherdService.tourName = CREATE_INTENTS_TUTORIAL_SEQUENCE.FOURTH;

    this.shepherdService.addSteps([
      new Step({
        attachTo: {
          element: '#newTrainngPhrase',
          on: 'bottom',
        },
        padding: 5,
        title: 'Edit intent',
        text: 'Enter a training phrase and press "enter" on your keyboard to save it.',
        buttons: [
          new ExitButton(this.exitCallback),
          new BackButton(() => {
            if (this.component.intentModalForm.valid) {
              this.shepherdService.tourName = CREATE_INTENTS_TUTORIAL_SEQUENCE.SECOND;
              this.component.saveIntent();
              this.shepherdService.cancel();
            }
          }),
          new NextButton(() => {
            if ((this.component.intentModalForm.get('phrases') as FormArray).length > 0) {
              this.shepherdService.next();
            }
          }),
        ],
      }),
      new Step({
        attachTo: {
          element: '#saveIntentButton',
          on: 'top',
        },
        padding: 5,
        title: 'Save intents',
        text: 'Click on "save" to save the newly created intent.',
        buttons: [new ExitButton(this.exitCallback), new BackButton(), new NextButton(() => this.saveIntentAndContinue())],
      }),
    ]);
    this.shepherdService.start();
  }

  private saveIntentAndContinue() {
    this.component.saveIntent();
    if (this.component.intentModalForm.valid) {
      this.shepherdService.complete();
    }
  }
}
