import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, EventEmitter, Output } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Training } from '../../../classes/training';
import { PaginationSlide } from '../../../classes/pagination-slide';
import { NotificationService } from '../../../services/notification.service';
import { DialogService } from '../../../services/dialog.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { Languages } from 'src/app/enums/languages.enum';
import { Agents } from 'src/app/enums/agents.enum';

@Component({
  selector: 'app-training-modal',
  templateUrl: './training-modal.component.html',
  styleUrls: ['./training-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TrainingModalComponent implements OnInit, OnDestroy {
  @Input() conversation: any;
  @Input() conversationSlide: PaginationSlide;
  @Output() private onUpdateResults = new EventEmitter<any>();

  subscriptions: Object = {};

  interactions: Array<any> = [];
  intents: Array<any>;

  filtersToggle = false;
  viewFilters: any = {
    parameters: true,
    page: true,
    flow: true,
    contextOut: true,
    response: true,
    rating: false,
    editedInteraction: {},
    status: false
  };
  

  interactionsApproved: Training = new Training();
  
  get conversationId() {
    return this.conversation.idConversation || this.conversation.id;
  }

  trainableIntent(interaction) {
    return (this.conversation.agentBotType === Agents.ES && !interaction.dfEventName) || (this.conversation.agentBotType === Agents.CX && interaction.userQuery !== null);
  }

  
  constructor(public activeModal: NgbActiveModal, private dialogService: DialogService, private notificationService: NotificationService, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.getInteractions();
    this.getIntents();

    this.conversationSlide.onMoveSlide.subscribe(() => {
      this.conversation = this.conversationSlide.currentItem;
      this.getInteractions();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  getEditedStatus(interaction: any, index: number): string {
    if (interaction.edited === true) {
      if (typeof this.viewFilters.editedInteraction[index] !== 'boolean') return 'current';

      return (this.viewFilters.editedInteraction[index]) ? 'current' : 'original';
    } else if (interaction.edited === false) {
      return 'original';
    }
  }

  getInteractions() {
    this.subscriptions['Interactions'] = this.dialogService.getInteractions(this.conversation.sessionId).subscribe((response: any) => {
      this.interactions = this.processInteractionEntities(response.data);
    });
  }



  getIntents() {
    this.subscriptions['intents'] = this.dialogService.getIntents().subscribe((response: any) => {
      this.intents = response.data;
    });
  }

  processInteractionEntities(interactions: Array<any>): Array<any> {
    return interactions.reduce((accumulator, currentValue) => {
      if (currentValue.editedDfEntityJson) {
        for (const key in currentValue.editedDfEntityJson) {
          if (currentValue.editedDfEntityJson.hasOwnProperty(key) && !currentValue.editedDfEntityJson[key]) {
            delete currentValue.editedDfEntityJson[key];
          }
        }
      }
      accumulator.push(currentValue);
      return accumulator;
    }, []);
  }

  entitiesSort() {
    return 0;
  }

  approveInteraction(interaction: any, forwarding: boolean) {
    if (this.interactionsApproved.isEveryPaylodSet(interaction.id)) {
      if (interaction.forwardNlu !== forwarding) {
        this.interactionsApproved.nluPayload[interaction.id].forwardNlu = forwarding;
        interaction.forwardNlu = forwarding;
        return;
      }
      interaction.forwardNlu = null

      this.interactionsApproved.removeInteraction(interaction.id);
    } else if (!this.interactionsApproved.isEveryPaylodSet(interaction.id)) {
      if (interaction.forwardNlu === forwarding) {
        interaction.forwardNlu = null;
        return;
      }
      interaction.forwardNlu = forwarding;

      if (!interaction.nluPayloadIntent) interaction.nluPayloadIntent = this.intents.find((intent) => intent.name === interaction.editedDfIntentName);

      this.interactionsApproved.addInteraction(interaction);
    }
  }

  approveConversation() {
    const data = {
      dialogflowPayload: Object.values(this.interactionsApproved.nluPayload),
      orchestratorPayload: {
        idConversation: this.getConversationId(),
        archived: Object.values(this.interactionsApproved.orchestratorPayload).length === this.interactions.length,
        messages: Object.values(this.interactionsApproved.orchestratorPayload),
      },
    };
    this.subscriptions['ApproveConversation'] = this.dialogService.approveConversation(data).subscribe(() => {
      this.activeModal.dismiss();
      this.notificationService.showToast(`Conversation <strong>${this.conversation.sessionId}</strong> correcty approved`, { type: 'success' });
    });
  }


  fallbackCount(): number {
    return this.interactions.filter((interaction) => interaction.fallback).length;
  }

  isAssigned(): boolean {
    return !!(this.conversation.userEntity || {}).id;
  }


  isFallbackIntent(interaction: any, index: number): boolean {
    const intentName = (this.getEditedStatus(interaction, index) === 'original') ? interaction.dfIntentName : interaction.editedDfIntentName;
    return intentName === '' || (this.intents && this.intents.some((intent) => intent.name === intentName && intent.isFallback));
  }

  toggleAssignment() {
    if (this.isAssigned() && this.conversation.userEntity.id !== this.authenticationService.user.id) return;

    if (this.isAssigned()) {
      this.subscriptions['AssignConversation'] = this.dialogService.unassignConversation(this.getConversationId()).subscribe(() => {
        this.notificationService.showToast(`Conversation correcty unassigned`, { type: 'success' });
        this.conversation.userEntity = null;
        this.onUpdateResults.emit();
      });
    } else {
      this.subscriptions['AssignConversation'] = this.dialogService.assignConversation({}, this.getConversationId(), this.authenticationService.user.id).subscribe(() => {
        this.notificationService.showToast(`Conversation correcty assigned`, { type: 'success' });
        this.conversation.userEntity = { id: this.authenticationService.user.id, email: this.authenticationService.user.email };
        this.conversation.archived = false;
        this.onUpdateResults.emit();
      });
    }
  }

  toggleArchived() {
    if (this.conversation.archived) {
      this.subscriptions['ArchiveConversation'] = this.dialogService.unarchiveConversation([this.conversation.id]).subscribe(() => {
        this.notificationService.showToast(`Conversation correcty unarchived`, { type: 'success' });
        this.conversation.archived = false;
        this.onUpdateResults.emit();
      });
    } else {
      this.subscriptions['ArchiveConversation'] = this.dialogService.archiveConversation([this.conversation.id]).subscribe(() => {
        this.notificationService.showToast(`Conversation correcty archived`, { type: 'success' });
        this.conversation.archived = true;
        this.conversation.userEntity = null;
        this.onUpdateResults.emit();
      });
    }
  }


  private getConversationId() {
    return this.conversation.idConversation || this.conversation.id;
  }


}
