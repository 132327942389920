import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DesignService } from '../../../../services/design.service';

@Component({
  selector: 'app-nlu-connector-modal',
  templateUrl: './nlu-connector-modal.component.html',
  styleUrls: ['./nlu-connector-modal.component.scss']
})
export class NluConnectorModalComponent implements OnInit, OnDestroy {
  @Input() private journey: any;
  @Input() connector: any;
  @Output() private onSaveConnector = new EventEmitter<any>();
  @ViewChild('nluForm', { static: true }) nluForm: NgForm;

  connectorSubscription: Subscription;

  constructor(public activeModal: NgbActiveModal, private designService: DesignService) { }

  ngOnInit() {
    if (!this.connector) this.connector = {};
  }

  ngOnDestroy() {
    if (this.connectorSubscription instanceof Subscription) this.connectorSubscription.unsubscribe();
  }

  saveConnector() {
    if (this.nluForm.invalid) return;

    const data = { ...this.connector, journeyId: this.journey.id };

    if (this.connector.id) {
      this.connectorSubscription = this.designService.editConnector('naturalLanguage', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    } else {
      this.connectorSubscription = this.designService.addConnector('naturalLanguage', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    }
  }

}
