import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import { CommonService } from '../services/common.service';

export const WORD_BREAKERS = ['^', '\\s', '\\.', ',', ';', '$', "'", '"'];

@Directive({
  selector: '[phraseParts]',
})
export class PhrasePartsDirective implements OnInit, AfterViewInit {
  @Input('phraseParts') phrase: AbstractControl;
  @Input('parameters') entityParameters: FormArray;
  @Input() updateEntities: Subject<any>;
  @Output() onSelectEntity = new EventEmitter<any>();

  @HostListener('mouseup', ['$event'])
  onMouseup($event) {
    const selectedText = $event.view.getSelection().toString();
    const phrasePart = $event.view.getSelection().focusNode.nodeValue;
    if (selectedText && !phrasePart.includes(selectedText)) {
      $event.view.getSelection().empty();
      return;
    }

    if (selectedText) {
      const selectionRegex = new RegExp(`(?<=${WORD_BREAKERS.join('|')})[^${WORD_BREAKERS.join('')}]*${selectedText}[^${WORD_BREAKERS.join('')}]*(?=${WORD_BREAKERS.join('|')})`, 'g');
      const { anchorOffset: selectionStart, focusOffset: selectionEnd } = $event.view.getSelection();

      let match;
      while ((match = selectionRegex.exec(phrasePart)) != null) {
        if (match.index <= selectionStart && selectionEnd <= match.index + match[0].length) {
          break;
        }
      }

      const text = match[0];
      const range = document.createRange();
      range.setStart($event.view.getSelection().focusNode, match.index);
      range.setEnd($event.view.getSelection().focusNode, match.index + match[0].length);
      $event.view.getSelection().removeAllRanges();
      $event.view.getSelection().addRange(range);
      const relativeOffset = $event.view.getSelection().getRangeAt(0).getBoundingClientRect().left - this.el.nativeElement.getBoundingClientRect().left;

      this.onSelectEntity.emit({
        text,
        offset: relativeOffset + $event.view.getSelection().getRangeAt(0).getBoundingClientRect().width / 2,
      });
    }
  }

  @HostListener('blur')
  onBlur() {
    this.buildParts();
  }

  get parts() {
    return this.phrase.get('parts') as FormArray;
  }
  get entityElements() {
    return this.el.nativeElement.querySelectorAll('span.entity');
  }
  get textQuery() {
    return this.phrase.value.parts.reduce((query: string, part: any) => {
      query += part.text;
      return query;
    }, '');
  }

  subscriptions: Object = {};

  constructor(private el: ElementRef, private render: Renderer2, private fb: FormBuilder, private commonService: CommonService) {}

  ngOnInit() {
    this.subscriptions['UpdateEntities'] = this.updateEntities.asObservable().subscribe(($event) => {
      if ($event.alias) {
        this.unmarkEntity($event.alias, $event.text);
      }

      if ($event.entity.name !== $event.alias && this.entityParameters.value.find((parameter) => parameter.name === $event.entity.name)) {
        this.markEntities([{ alias: $event.entity.name, entityType: $event.entity.entityType, text: $event.text }]);
      }

      this.buildParts();
    });

    this.subscriptions['ParameterChanges'] = this.entityParameters.valueChanges.pipe(startWith(this.entityParameters.value), pairwise()).subscribe(([oldValue, newValue]) => {
      if (newValue.length !== oldValue.length) {
        oldValue.forEach((oldParameter) => {
          if (!newValue.some((newParameter) => newParameter.name === oldParameter.name)) {
            this.unmatchParameter(oldParameter);
          }
        });
      }

      this.updateAlias();
      this.render.setProperty(this.el.nativeElement, 'innerHTML', this.textQuery);

      this.markEntities(this.phrase.value.parts);
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.render.setProperty(this.el.nativeElement, 'innerHTML', this.textQuery);

    this.markEntities(this.phrase.value.parts);
  }

  markEntities(prhaseParts: Array<any>) {
    prhaseParts
      .filter((part) => part.alias)
      .forEach((part) => {
        const cleanPhrase = this.commonService.escapeRegExpChars(part.text as string) as string;
        const replaceReg = new RegExp(`((?<=${WORD_BREAKERS.join('|')})${cleanPhrase}(?=${WORD_BREAKERS.join('|')}))(?![^<]*>|[^<>]*<\/)`, 'gi');
        const replaceCode = this.el.nativeElement.innerHTML;

        this.render.setProperty(
          this.el.nativeElement,
          'innerHTML',
          replaceCode.replace(
            replaceReg,
            `<span class="entity matches_${this.getColorIndex(part.alias)}" data-alias="${part.alias}" data-type="${part.entityType}" data-match="${this.getColorIndex(part.alias)}">${
              part.text
            }</span>`
          )
        );
      });

    this.entityElements.forEach((element) => {
      this.render.listen(element, 'click', ($event: any) => {
        this.onSelectEntity.emit({
          text: $event.target.textContent,
          alias: $event.target.dataset.alias,
          entityType: $event.target.dataset.type,
          aliasIndex: this.getColorIndex($event.target.dataset.alias),
          offset: $event.target.offsetLeft + $event.target.offsetWidth / 2,
        });
      });
    });
  }

  unmarkEntity(entityAlias: string, entityValue: string) {
    const entityRemoved = this.el.nativeElement.querySelectorAll(`[data-alias="${entityAlias}"]`);

    entityRemoved.forEach((element) => {
      if (element.textContent === entityValue) {
        this.render.setProperty(element, 'outerHTML', element.textContent);
      }
    });
  }

  getColorIndex(entityName: string): number {
    let colorIndex: number;
    this.entityParameters.value.forEach((entity: any, index: number) => {
      if (entity.name === entityName) colorIndex = index;
    });
    return colorIndex;
  }

  buildParts() {
    const recognizedEntities = Array.prototype.map.call(this.entityElements, (element) => {
      return {
        alias: element.dataset.alias,
        entityType: element.dataset.type,
        text: element.innerText,
      };
    });
    this.parts.clear();

    if (recognizedEntities.length === 0) {
      this.parts.push(
        this.fb.group({
          text: this.el.nativeElement.textContent,
          entityType: '',
          alias: '',
        })
      );
    } else {
      const recognizedValues = recognizedEntities.map((item) => item.text);
      const escapedValues = this.commonService.escapeRegExpChars(recognizedValues as Array<string>) as Array<string>;

      const regEx = new RegExp(`(${escapedValues.join('|')})`, 'gi');
      const textParts = this.el.nativeElement.textContent.split(regEx);
      textParts.forEach((text) => {
        if (text.length > 0) {
          const entityPart = recognizedEntities.find((item) => item.text === text);

          this.parts.push(
            this.fb.group({
              text: text,
              entityType: (entityPart || {}).entityType || '',
              alias: (entityPart || {}).alias || '',
            })
          );
        }
      });
    }
  }

  unmatchParameter(parameter: any) {
    const parameterRemoved = this.el.nativeElement.querySelectorAll(`[data-alias="${parameter.name}"]`);

    parameterRemoved.forEach((element) => {
      this.render.setProperty(element, 'outerHTML', element.textContent);
    });

    this.buildParts();
  }

  updateAlias() {
    this.entityParameters.value.forEach((parameter: any, parameterIndex: number) => {
      this.entityElements.forEach((element) => {
        if (parameterIndex === parseInt(element.dataset.match) && element.dataset.alias !== parameter.name) {
          const partGroup = this.parts.controls.find((part: FormGroup) => part.value.alias === element.dataset.alias) as FormGroup;
          partGroup.get('alias').setValue(parameter.name);
        }
      });
    });
  }
}
