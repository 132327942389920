import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelsService {

  constructor(private http: HttpClient) { }

  getChannels(journeyId: string) {
    let httpOptions = {
      params: new HttpParams()
      .set('journeyId', journeyId)
    }
    return this.http.get(`${environment.API.orchestrator_configurator}/journey/channels`, httpOptions)
  }

  updateChannel(channelPath: string, item: any) {
    return this.http.put(`${environment.API.orchestrator_configurator}/${channelPath}`, item);
  }

  createChannel(channelPath: string, item: any) {
    return this.http.post(`${environment.API.orchestrator_configurator}/${channelPath}`, item);
  }


}
