
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService } from '../services/notification.service';
import { SupportModalComponent } from '../components/modals/support-modal/support-modal.component';
import { environment } from 'src/environments/environment';

const excludedApiStatus: Array<any> = [
    {
        url: `${environment.API.orchestrator_configurator}/liveperson`,
        method: 'POST',
        status: [502]
    },
    {
        url: `${environment.API.orchestrator_configurator}/liveperson`,
        method: 'PUT',
        status: [502]
    },
    {
        url: `${environment.API.orchestrator_chat}/actuator/health`,
        method: 'GET',
        status: [500, 404]
    },
    {
        url: `${environment.API.orchestrator_analysis_v2}/actuator/health`,
        method: 'GET',
        status: [500, 404]
    },
    {
        url: `${environment.API.orchestrator_configurator}/actuator/health`,
        method: 'GET',
        status: [500, 404]
    },
    {
        url: `${environment.API.channel_whatsapp}/health-check/health-check`,
        method: 'GET',
        status: [500, 404]
    },
    {
        url: `${environment.API.channel_vivocha}/health-check/health-check`,
        method: 'GET',
        status: [500, 404]
    },
    {
        url: `${environment.API.channel_timrcc}/health-check/health-check`,
        method: 'GET',
        status: [500, 404]
    },
    {
        url: `${environment.API.channel_liveperson}/health-check/health-check`,
        method: 'GET',
        status: [500, 404]
    },
    {
        url: `${environment.API.channel_facebook}/health-check/health-check`,
        method: 'GET',
        status: [500, 404]
    },
    {
        url: `${environment.API.orchestrator_configurator}/liveperson/checkAgentsStatus`,
        method: 'GET',
        status: []
    },
    {
        url: `/`,
        method: 'GET',
        status: [304, 200]
    }
];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private notificationService: NotificationService, private modalService: NgbModal) {  }
    

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (!this.excludeApi(req, error)) {
                    if ( error.status !== 403 && (error.status !== 401 && error.error !== 'SESSION') ) {
                        this.notificationService.openModal({
                            title: 'ERROR',
                            message: 'Ops... Something went wrong and it was impossible to complete the operation!',
                            type: 'danger',
                            choice: 'support',
                            serviceError: {
                                message: (error && error.error.message ? error.error.message  : 'Generic Error'),
                                code: error.status
                            },
                        }).subscribe(async (confirm: boolean) => {
                            if (!confirm) return;
                            
                            this.modalService.open(SupportModalComponent, {
                            size: 'lg'
                            });
                        })
                    }
                }
                return throwError(error);
            })
        );
    }

    private excludeApi(req: HttpRequest<any>, error: HttpErrorResponse) {
        return excludedApiStatus.find(api => {
            if ((req.url === api.url || req.url.substring(0, req.url.lastIndexOf('/')+ 1) === api.url.substring(0, api.url.lastIndexOf('/')+ 1)) &&
                req.method === api.method && (!api.status || api.status.length === 0 || api.status.indexOf(error.status) >= 0)
            ) {
                return true;
            } else {
                return false;
            }
        })

    }
}
