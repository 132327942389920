import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'market-connectors',
  templateUrl: './market-connectors.component.html',
  styleUrls: ['./market-connectors.component.scss']
})
export class MarketConnectorsComponent {
  @Input() connectors: Array<any>;
  @Output() onBuyProduct = new EventEmitter<any>();
  @Output() onDetailsProduct = new EventEmitter<any>();

  buyItem() {
    this.onBuyProduct.emit();
  }

  detailsItem(item: any) {
    this.onDetailsProduct.emit(item);
  }

}
