import { Test } from './test';
export enum TEST_STATUS_ENUM {
  SUCCESSFUL = 'SUCCESSFUL',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  RUNNING = 'RUNNING',
  NOT_RUNNING = 'NOT_RUNNING',
}

export interface TestCase {
  createdTime: string;
  id: number;
  journeyApiKey: string;
  lastRun: string;
  status: TEST_STATUS_ENUM;
  testName: string;
  regressionTest: Array<Test>;
  selected?: boolean;
  lang: string;
}
