
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

const interceptHandlers: Array<string> = [
    `${environment.API.orchestrator_configurator}/personality/result`
];

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

    constructor(private translateService: TranslateService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if ( interceptHandlers.indexOf(req.url) !== -1 && !req.params.has('lang') ) {
            return next.handle(
                req.clone({
                    params: req.params.set('lang', this.translateService.currentLang || this.translateService.getDefaultLang())
                })
            );
        }

        return next.handle(req);
    }

}
