import { BackButton, Button, ExitButton, NextButton } from './buttons.tutorial';

export class Step {
  popperOptions = {
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
  };
  canClickTarget = true;
  attachTo;
  beforeShowPromise: () => void = function () {
    return new Promise(function (resolve) {
      setTimeout(function () {
        window.scrollTo(0, 0);
        resolve(true);
      }, 500);
    });
  };
  scrollTo: false;
  title;
  text;
  buttons: Button[] = [new ExitButton(), new BackButton(), new NextButton()];
  modalOverlayOpeningPadding = 0;
  advanceOn;
  when;
  showOn;
  constructor({
    attachTo,
    title,
    text,
    buttons,
    canClickTarget,
    padding,
    advanceOn,
    beforeShowPromise,
    when,
    showOn,
  }: {
    attachTo?: { element: string; on: string };
    title: string;
    text: string;
    buttons?: Button[];
    canClickTarget?: boolean;
    padding?: number;
    advanceOn?: { selector: string; event: string };
    beforeShowPromise?: () => void;
    when?: any;
    showOn?: () => boolean;
  }) {
    this.attachTo = attachTo;
    this.title = title;
    this.text = text;
    this.advanceOn = advanceOn;
    this.when = when;
    this.showOn = showOn;
    if (beforeShowPromise) this.beforeShowPromise = beforeShowPromise;
    if (canClickTarget === false) this.canClickTarget = false;
    if (buttons) this.buttons = buttons;
    if (padding) {
      this.modalOverlayOpeningPadding = padding;
      this.popperOptions = {
        modifiers: [{ name: 'offset', options: { offset: [0, 10 + padding] } }],
      };
    }
  }
}
