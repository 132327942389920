import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { AdminService } from '../../../services/admin.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-tone-of-voice-support-modal',
  templateUrl: './tone-of-voice-support-modal.component.html',
  styleUrls: ['./tone-of-voice-support-modal.component.scss']
})
export class ToneOfVoiceSupportModalComponent implements OnDestroy {
  @ViewChild('supportForm', { static: true }) supportForm: NgForm;

  supportSubscription: Subscription;
  support: any = {
    title: 'Noovle support for TOV definition',
    requestType: 'Guidance'
  };

  constructor(private adminService: AdminService, public activeModal: NgbActiveModal, private notificationService: NotificationService) { }

  ngOnDestroy() {
    if (this.supportSubscription instanceof Subscription) this.supportSubscription.unsubscribe();
  }

  sendRequest() {
    if (this.supportForm.invalid) return;

    this.supportSubscription = this.adminService.sendSupportRequest(this.support).subscribe(() => {
      this.activeModal.close();
      this.notificationService.showToast('Request correctly sended', { type: 'success' });
    });
  }

}
