// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_PATH: 'https://av-bandi-test-tmp.tellya.ai',
  API: {
    orchestrator_analysis: '/analysis/api/v1',
    orchestrator_analysis_v2: '/analysis/api/v2',
    orchestrator_configurator: '/configurator/api/v2',
    orchestrator_chat: '/chat/api/v2',
    channel_whatsapp: '/bot/whatsapp',
    channel_vivocha: '/bot/vivocha',
    channel_liveperson: '/bot/liveperson',
    channel_timrcc: '/bot/timrcc',
    channel_facebook: '/bot/facebook',
  }
};
