import { Component } from '@angular/core';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { DashboardService } from '../../../services/dashboard.service';
import { DesignService } from '../../../services/design.service';
const MILLS_IN_A_DAY = 24 * 60 * 60 * 1000;
@Component({
  selector: 'analytics-card',
  templateUrl: './analytics-card.component.html',
  styleUrls: ['./analytics-card.component.scss'],
})
export class AnalyticsCardComponent {
  activeTab = 'today';
  startDate = new Date().setHours(0, 0, 0, 0);
  endDate = new Date().setHours(23, 59, 59, 999);
  statistics$ = {
    today: this.designService.getSessionJourney().pipe(
      switchMap(() =>
        this.dashboardService.getStatistics(this.startDate, this.endDate, false).pipe(
          map((res: any) => ({
            total: res.multiObject.total,
            avgMessages: res.multiObject.messagesPerSession,
            avgDuration: res.multiObject.sessionDuration * 60 * 1000,
          })),
          startWith(null)
        )
      ),
      shareReplay(1)
    ),
    yesterday: this.designService.getSessionJourney().pipe(
      switchMap(() =>
        this.dashboardService.getStatistics(this.startDate - MILLS_IN_A_DAY, this.endDate - MILLS_IN_A_DAY, false).pipe(
          map((res: any) => ({
            total: res.multiObject.total,
            avgMessages: res.multiObject.messagesPerSession,
            avgDuration: res.multiObject.sessionDuration * 60 * 1000,
          })),
          startWith(null)
        )
      ),
      shareReplay(1)
    ),
  };
  constructor(private dashboardService: DashboardService, private designService: DesignService) {}
}
