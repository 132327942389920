import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { ExitButton, NextButton, BackButton, CompleteButton } from 'src/app/classes/tutorials/buttons.tutorial';
import { Step } from 'src/app/classes/tutorials/step.tutorial';
import { ParametersResponseFormComponent } from 'src/app/components/intent-parameters/parameters-response-form/parameters-response-form.component';
import { CREATE_INTENTS_TUTORIAL_SEQUENCE } from './create-intents-tutorial.conversation-flow.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CreateIntnentsTutorialParametmersResponseFormService {
  private component: ParametersResponseFormComponent;
  exitCallback;
  constructor(private router: Router, private shepherdService: ShepherdService, @Inject(DOCUMENT) private document: Document) {
    this.exitCallback = () => {
      this.router.navigate(['/intent-settings']);
      this.shepherdService.cancel();
    };
  }
  startTutorial(component) {
    this.component = component;
    if (this.shepherdService.isActive) {
      this.shepherdService.complete();
    }
    this.shepherdService.tourName = CREATE_INTENTS_TUTORIAL_SEQUENCE.SIXTH;

    this.shepherdService.addSteps([
      new Step({
        padding: 5,
        attachTo: {
          element: '#intentNameForm',
          on: 'bottom',
        },
        title: `Select intent`,
        text: 'Write the name of the intent you just created.',
        buttons: [new ExitButton(this.exitCallback), new NextButton()],
      }),
      new Step({
        padding: 5,
        advanceOn: { selector: '#responseType', event: 'click' },
        attachTo: {
          element: '#responseType',
          on: 'left',
        },
        title: `Response type`,
        text: 'Select the type of response you want to configure.',
        buttons: [
          new ExitButton(this.exitCallback),
          new BackButton(),
          new NextButton(() => {
            this.component.responseDropdown.open();
            this.shepherdService.next();
          }),
        ],
      }),
      new Step({
        advanceOn: { selector: '#textResponseButton', event: 'click' },
        attachTo: {
          element: '#textResponseButton',
          on: 'left',
        },
        title: `Select response`,
        text: 'To get started set up a text response.',
        buttons: [
          new ExitButton(this.exitCallback),
          new BackButton(() => {
            this.component.responseDropdown.close();
            this.shepherdService.back();
          }),

          new NextButton(() => {
            this.component.intentParametersService.addTextResponse();
            this.shepherdService.next();
          }),
        ],
      }),

      new Step({
        padding: 5,
        attachTo: {
          element: '#textResponsesWrapper',
          on: 'top',
        },
        title: `Insert response`,
        text: 'Write the response your intent should return when called.',
        buttons: [
          new ExitButton(this.exitCallback),
          new BackButton(() => {
            this.component.intentParametersService.removeTextResponse(0);
            this.component.responseDropdown.open();
            this.shepherdService.back();
          }),
          new NextButton(),
        ],
      }),
      new Step({
        padding: 5,
        advanceOn: { selector: '#saveResponseButton', event: 'click' },

        attachTo: {
          element: '#saveResponseButton',
          on: 'left',
        },
        title: `Save response`,
        text: 'Click on "Save" to save the answer.',
        buttons: [
          new ExitButton(this.exitCallback),
          new BackButton(),
          new NextButton(() => {
            this.component.saveResponse();
            if (this.component.responseForm.valid) {
              this.shepherdService.next();
            }
          }),
        ],
      }),
      new Step({
        padding: 5,
        advanceOn: { selector: '#chatButton', event: 'click' },

        attachTo: {
          element: '#chatButton',
          on: 'bottom',
        },
        title: `Text chat`,
        text: 'Click on the chat icon to open the test chat and test the newly created intent.',
        buttons: [
          new ExitButton(this.exitCallback),
          new BackButton(),
          new NextButton(() => {
            this.document.querySelector<any>('#chatButton').click();
          }),
        ],
      }),
      new Step({
        padding: 5,

        attachTo: {
          element: '#chatWrapper',
          on: 'left',
        },
        title: `Test intent`,
        text: 'Write one of the training phrases that you have configured in the intent and press enter.',
        buttons: [new ExitButton(this.exitCallback), new CompleteButton()],
      }),
    ]);

    this.shepherdService.start();
  }
}
