import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of, Subscription, zip } from 'rxjs';

import { environment } from '../../environments/environment';
import { TestCase } from '../interfaces/testCase';
import { CommonService } from './common.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  public testCasesWs: Subscription;

  constructor(private http: HttpClient, private commonService: CommonService) { }

  getRequestStats(connectorName: string, days: number, startDate: number, endDate: number ) {
    const httpOptions = {
      params: new HttpParams()
        .set('days', days.toString())
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    return this.http.get(`${environment.API.orchestrator_analysis}/billing/${connectorName}`, httpOptions);
  }

  getBillingUsers(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/planning/billing`);
  }

  getNotifiedUser() {
    return this.http.get(`${environment.API.orchestrator_configurator}/planning/notifiedUser`);
  }

  updateNotifiedUser(data: any, params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('enabled', params.enabled.toString())
    };

    return this.http.put(`${environment.API.orchestrator_configurator}/planning/notifiedUser`, data, httpOptions);
  }

  getTestCases(params: { testName? : string }, pageNumber: number, pageSize: number) {
    const httpOptions = {
      params: new HttpParams()
        .set('pageSize', pageSize.toString())
        .set('pageNumber', pageNumber.toString())
        .set('sortBy', 'testName')
    };

    if (params.testName) httpOptions.params = httpOptions.params.append('testName', params.testName);

    return this.http.get(`${environment.API.orchestrator_configurator}/regression`, httpOptions);
  }
  saveOrUpdateTestCase(data: any){
    return this.http.post<number>(`${environment.API.orchestrator_configurator}/regression`, data);
  }
  
  deleteTestCase(testId: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('id', testId.toString())
    };
    return this.http.delete(`${environment.API.orchestrator_configurator}/regression`, httpOptions);
  }

  getTestCase(testId: number){
    return this.http.get<TestCase>(`${environment.API.orchestrator_configurator}/regression/testByRegression/${testId}`);
  }

  exportTestCases(testIds: Array<number>){
    const httpOptions = {
      responseType: 'blob' as 'blob',
    };
    return this.http.post(`${environment.API.orchestrator_configurator}/regression/export`, testIds, httpOptions).pipe(
      tap((response) => {
        this.commonService.downloadFile(response, 'blob', 'Export Regression Test Data.json');
      })
    );
  }

  importTestCases(files: FileList, params: any){
    const httpOptions = {
      params: new HttpParams()
        .set('journeyApiKey', params.journeyApiKey),
      responseType: 'text' as 'text'
    };
    const data = new FormData();
    data.append('file', files[0], files[0].name);

    return this.http.post(`${environment.API.orchestrator_configurator}/regression/import`, data, httpOptions);
  }

  getChatStatus(){
    return this.http.get<any>(`${environment.API.orchestrator_chat}/actuator/health`).pipe(
      map((res) => {
        if (res.components && res.components.redis) {
          res.components.cache = { ...res.components.redis };
          delete res.components.redis;
        }
        return res;
      })
    );
  }
  getAnalysisStatus(){
    return this.http.get(`${environment.API.orchestrator_analysis_v2}/actuator/health`);
  }
  getConfiguratorStatus(){
    return this.http.get(`${environment.API.orchestrator_configurator}/actuator/health`);
  }
  getWhatsAppStatus(){
    return this.http.get(`${environment.API.channel_whatsapp}/health-check/health-check`);
  }
  getVivochaStatus(){
    const body = {
      event: 'continue',
      settings: {
        engine: {
          type: 'tellya',
        },
      },
    };
    return this.http.post(`${environment.API.channel_vivocha}`, body).pipe(map(() => ({ status: 'UP' })));
  }
  getTimrccStatus(){
    return this.http.get(`${environment.API.channel_timrcc}/health-check/health-check`);
  }

  restartLivepersonAgent(agentNames: string[]) {
    let httpOptions;
    if (agentNames && agentNames.length > 0) {
      httpOptions = {
        params: {
          'agent': agentNames
        }
      }
    }
    return this.http.post(`${environment.API.orchestrator_configurator}/liveperson/restartAgents`, null, httpOptions)
  }

  getAgentHealth(agentNames?: string[]): Observable<any> {
    let httpOptions;
    if (agentNames && agentNames.length > 0) {
      httpOptions = {
        params: {
          'agent': agentNames
        }
      }
    }
    return this.http.get(`${environment.API.orchestrator_configurator}/liveperson/checkAgentsStatus`, httpOptions).pipe(map((res: any) => {
      let retVal = {status: res.status, agents: {}};
      res.agents.forEach(agent => {
        retVal.agents[agent.agent] = {status: agent.status}
      })
      return retVal;
    }),
    catchError(() => {
      return of({status: 'UP', agents: {}})
    })
    );
  }

  getLivepersonStatus(){
    const healthCheck = this.http.get(`${environment.API.channel_liveperson}/health-check/health-check`);
    const agentHealth = this.getAgentHealth();
    
    return zip(healthCheck, agentHealth).pipe(map(([healthCheckRes, agentHealthRes]: any) => {
      healthCheckRes.status = (healthCheckRes.status === 'UP' && agentHealthRes.status === 'UP') ? 'UP' : 'DOWN';
      healthCheckRes.agents = agentHealthRes.agents;
      return healthCheckRes;
    }));
  }
  getFacebookStatus(){
    return this.http.get(`${environment.API.channel_facebook}/health-check/health-check`);
  }
  getConsoleStatus(){
    const httpOptions = {
      responseType: 'text' as any,
    };

    return this.http.get(`/`, httpOptions).pipe(map(() => ({ status: 'UP' })));
  }

  getLogsConnectors(){
    return this.http.get<Array<string>>(`${environment.API.orchestrator_analysis}/gcplogging/getConnectors`);
  }
  getLogs(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('pageSize', pageSize.toString())
        .set('pageIndex', pageNumber.toString())
    };

    const allowedParams = ['connectors', 'startDate', 'endDate', 'severity', 'textLog'];
    for (const key of allowedParams) {
      if (params[key] !== null && params[key] !== undefined) {
        if (key === 'connectors' || key === 'severity') {
          params[key].forEach(c => {
            httpOptions.params = httpOptions.params.append(key, `${c.toString()}`);
          });
        }else{
          httpOptions.params = httpOptions.params.append(key, params[key].toString());

        }
      }
    }
    return this.http.get(`${environment.API.orchestrator_analysis}/gcplogging`, httpOptions);
  }

  exportLogsOnStorage(data: FormData, params: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('journeyApiKey', params.journeyApiKey)
        .set('bucketName', params.bucketName)
        .set('projectId', params.projectId)
      };

      if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
      if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
      if (params.connectors && params.connectors.length) httpOptions.params = httpOptions.params.append('connectors', params.connectors);
      if (params.severity && params.severity.length) httpOptions.params = httpOptions.params.append('severity', params.severity);
      if (params.textLog) httpOptions.params = httpOptions.params.append('textLog', params.textLog);

    return this.http.post(`${environment.API.orchestrator_analysis}/gcplogging/executeExportLogForGCP`, data, httpOptions);
  }

}
