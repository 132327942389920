import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { Pagination } from '../../../classes/pagination';
import { AdminService } from '../../../services/admin.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TableSortDirective } from 'src/app/directives/table-sort.directive';
import { DesignService } from 'src/app/services/design.service';
@Component({
  selector: 'audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuditLogComponent implements OnInit, OnDestroy {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;

  subscriptions: Object = {};

  auditLogPagination: Pagination = new Pagination();
  filter: any = { ...this.defaultFilters };
  auditLog: Array<any> = [];
  auditTypes: Array<string> = [];
  activityTypes: Array<string> = [];

  componentsGroupTypes: Array<string> = [];

  get journeys() {
    return this.designService.accountJourneys;
  }

  get defaultFilters() {
    return {
      eventType: '',
      activityType: '',
      journey: '',
      component: [],
      sortBy: 'eventDate:desc',
    };
  }
  constructor(private designService: DesignService, private adminService: AdminService, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.getComponentsTypes();
    this.getAuditTypes();
    this.getActivityTypes();

    this.searchAuditLog(1);

    this.subscriptions['accountSubscription'] = this.authenticationService.onChangeSessionAccount().subscribe(() => {
      this.searchAuditLog(1);
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  updateDate($event) {
    if ($event.type === 'from') this.filter.fromDate = $event.date;
    if ($event.type === 'to') this.filter.toDate = $event.date;
  }

  getComponentsTypes() {
    this.subscriptions['componentTypesSubscription'] = this.adminService.getComponentsTypes().subscribe((response: any) => {
      this.componentsGroupTypes = response;
    });
  }

  getAuditTypes() {
    this.subscriptions['auditTypesSubscription'] = this.adminService.getAuditTypes().subscribe((response: any) => {
      this.auditTypes = response;
    });
  }

  getActivityTypes() {
    this.subscriptions['activityTypesSubscription'] = this.adminService.getActivityTypes().subscribe((response: any) => {
      this.activityTypes = response;
    });
  }

  searchAuditLog(pageSelected: number) {
    this.auditLogPagination.onSelectPage(pageSelected);
    const params = {
      component: this.filter.component.length > 0 ? this.filter.component : null,
      eventType: this.filter.eventType || null,
      principal: this.filter.principal || null,
      journeyName: this.filter.journey || null,
      activityType: this.filter.activityType || null,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      sortBy: this.filter.sortBy || null,
    };

    this.subscriptions['auditLogSubscription'] = this.adminService
      .getAuditLog(params, this.auditLogPagination.getPageIndex(), this.auditLogPagination.pageSize)
      .subscribe((response: any) => {
        this.auditLog = response.content;
        this.auditLogPagination.updateTotals(response.totalElements);
      });
  }

  resetFilters() {
    this.filter = { ...this.defaultFilters };
    this.searchAuditLog(1);
  }

  onSort({ column, direction }: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = direction ? `${column}:${direction}` : null;
    this.searchAuditLog(this.auditLogPagination.currentPage);
  }

  selectAllComponents(componentGroup) {
    const items = Object.values(this.componentsGroupTypes[componentGroup]);

    if (this.getCheckedComponents(componentGroup)) {
      this.filter.component = this.filter.component.filter((c) => !items.includes(c));
    } else {
      this.filter.component.push(...items.filter((c) => !this.filter.component.includes(c)));
    }
  }
  getCheckedComponents(componentGroup) {
    const items = Object.values(this.componentsGroupTypes[componentGroup]);

    return items.every((c) => this.filter.component.includes(c));
  }

  getIndeterminateComponents(componentGroup) {
    if (this.getCheckedComponents(componentGroup)) {
      return false;
    }
    const items = Object.values(this.componentsGroupTypes[componentGroup]);

    return items.some((c) => this.filter.component.includes(c));
  }
  isSelected(component: string): boolean {
    return this.filter.component.indexOf(component) !== -1;
  }
  selectComponent(component) {
    if (this.isSelected(component)) {
      this.filter.component = [...this.filter.component.filter((c) => c !== component)];
    } else {
      this.filter.component.push(component);
    }
  }
}
