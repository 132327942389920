import { Component } from '@angular/core';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DesignService } from 'src/app/services/design.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  statistics$ = this.designService.getSessionJourney().pipe(
    switchMap(() =>
      this.dashboardService.getStatistics(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0).getTime(), new Date().setHours(23, 59, 59, 999), true).pipe(
        map((res: any) => ({
          totalBookmarked: res.multiObject.totalBookmarked,
          totalFallback: res.multiObject.totalFallback,
          totalNotAnalyzed: res.multiObject.totalNotAnalyzed,
          total: res.multiObject.total,
          avgMessages: res.multiObject.messagesPerSession,
          avgDuration: res.multiObject.sessionDuration * 60 * 1000,
        })),
        startWith(null)
      )
    ),
    shareReplay(1)
  );
  constructor(private dashboardService: DashboardService, private designService: DesignService) {}
}
