import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AnalyticsFilterComponent } from '../analytics-filter/analytics-filter.component';
import { TableSortDirective } from 'src/app/directives/table-sort.directive';
import { Pagination } from 'src/app/classes/pagination';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-analytic-engagement',
  templateUrl: './analytic-engagement.component.html',
  styleUrls: ['./analytic-engagement.component.scss']
})
export class AnalyticEngagementComponent implements OnDestroy, OnInit {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;
  
  @Input() filter?: any = {};
  subscriptions: object = {};
  resultsPagination: Pagination = new Pagination();
  
  filtersAddOn: any = {
    sortBy: null,
    pageSelected: this.resultsPagination.currentPage,
  };

  resultResolved: boolean;
  resultTrend: Array<any> = [];
  fallbackPercentage: number;
  tags: Array<any> = [];
  idResearchTab:  'fallback' | 'intent'  = 'intent';
  manageVisibility = {
     inputSearch: true,
  };
  toolTipsIntents: any = {
    mostTriggeredIntents: 'Top 5 of the most used intents among all sessions.',
    mostTriggeredFallbacks: "When the virtual agent doesn't recognize an end-user expression.",
    fallbackPercentage: 'How often the virtual agent could not understand the users’ requests.',
    operatorRequest: 'How many times the user has expressed an explicit desire to talk to an operator.',
  };
  
  constructor(private analyticsService: AnalyticsService) { }
  ngOnInit(): void {
    this.filter.elementLike = '';
    this.filter.intentTag = ''; 
    this.getStatistics();  
  }
  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  switchIdResearchTab() {
    this.resetFilters();
  }

  resetFilters() {
    const today = new Date();
    this.filter = {
      period: {
        fromDate: {
          day: today.getDate(),
          month: today.getMonth(),
          year: today.getFullYear()
        },
        toDate: {
          day: today.getDate(),
          month: today.getMonth() + 1,
          year: today.getFullYear()
        },
      },
      intentTag: '',
      elementLike: '',
    }
    this.filtersAddOn = { sortBy: null, pageSelected: 1 };
    this.thead.forEach((th: any) => {
        th.direction = '';
    });
    this.getStatistics();
  }

  onSort({ column, direction }: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filtersAddOn = { ...this.filtersAddOn, sortBy: direction ? `${column}:${direction}` : null };
    this.getStatistics();
  }

  changePage(pageNumber: number) {
    this.filtersAddOn = { ...this.filtersAddOn, pageSelected: pageNumber };
    this.getStatistics();
  }

  getStatistics() {
     this.resultResolved = false;
     this.resultsPagination.onSelectPage(this.filtersAddOn.pageSelected);

    const params = {
      startDate: this.filter.period.fromDate ? new Date(this.filter.period.fromDate.year, this.filter.period.fromDate.month - 1, this.filter.period.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.period.toDate ? new Date(this.filter.period.toDate.year, this.filter.period.toDate.month - 1, this.filter.period.toDate.day, 23, 59, 59).getTime() : null,
      tags: this.filter.intentTag || null,
      sortBy: this.filtersAddOn.sortBy || null,
      intentLike: this.filter.elementLike || null,
    };

    if (this.idResearchTab === 'fallback') {
      this.getTopFallback(params);
      this.getFallbackPercentage(params);
    }

    if (this.idResearchTab === 'intent'){
      this.getTopIntent(params);
    } 
  }

  processResultData(response) {
    this.resultTrend = response.singleResult.content;
    this.resultsPagination.updateTotals(response.singleResult.totalElements);
    this.resultResolved = true;
  }

  getFallbackPercentage(params: any) {
    this.subscriptions['FallbackPercentage'] = this.analyticsService.getFallbackPercentage(params).subscribe((response: any) => {
      this.fallbackPercentage = response.singleResult.value;
    });
  }

  getTopFallback(params: any) {
    this.subscriptions['TopFallback'] = this.analyticsService
      .getTopFallbackEngagement(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize)
      .subscribe((response: any) => {
        this.processResultData(response);
      });
  }

  getTopIntent(params: any) {
    this.subscriptions['TopIntent'] = this.analyticsService
      .getTopIntentEngagement(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize)
      .subscribe((response: any) => {
        this.processResultData(response);
      });
  }

}
