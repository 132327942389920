import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Subscription } from 'rxjs';
import { Pagination } from 'src/app/classes/pagination';
import { TestCase, TEST_STATUS_ENUM } from 'src/app/interfaces/testCase';
import { DesignService } from 'src/app/services/design.service';
import { MonitoringService } from 'src/app/services/monitoring.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TestCaseModalComponent } from '../../modals/test-case-modal/test-case-modal.component';

@Component({
  selector: 'app-test-processes',
  templateUrl: './test-processes.component.html',
  styleUrls: ['./test-processes.component.scss'],
})
export class TestProcessesComponent implements OnInit {
  subscriptions: Object = {};
  @ViewChild('testCasesToast', { static: false }) testCasesToast: TemplateRef<any>;

  testCases: Array<TestCase> = [];
  statusEnum = TEST_STATUS_ENUM;
  filter: any = {
    testName: '',
  };
  editTestCase: TestCase = null;
  resultsPagination: Pagination = new Pagination();
  updateTestList = true;

  importedFile: FileList;

  constructor(private designService: DesignService, private monitoringService: MonitoringService, private modalService: NgbModal, private fb: FormBuilder, private rxStompService: RxStompService, private notificationService: NotificationService) {}

  ngOnInit() {
    this.subscriptions['Journey'] = this.designService.getSessionJourney().subscribe(() => {
      if (this.subscriptions['AgentLanguage'] instanceof Subscription) this.subscriptions['AgentLanguage'].unsubscribe();
      this.subscriptions['AgentLanguage'] = this.designService.getSessionAgentLang().subscribe(() => {
        if (this.editTestCase !== null) {
          this.editTestCase = null;
        }
        this.searchTests(1);
      });
    });
    this.subscribeToWebsocket();
  }

  private subscribeToWebsocket() {
    if (this.monitoringService.testCasesWs instanceof Subscription) {
      this.monitoringService.testCasesWs.unsubscribe();
    }
    this.updateTestList = true;
    this.monitoringService.testCasesWs = this.rxStompService.watch('/user/resultTest').subscribe(() => {
      this.notificationService.showToast(this.testCasesToast, { type: 'success', autohide: false });
      if (this.updateTestList && this.editTestCase === null) {
        this.searchTests(this.resultsPagination.currentPage);
      } else if (this.updateTestList && this.editTestCase !== null) {
        this.updateEditTestCase();
      }
    });
  }

  ngOnDestroy() {
    this.updateTestList = false;
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  searchTests(pageSelected: number) {
    this.resultsPagination.onSelectPage(pageSelected);

    const params = {
      testName: this.filter.testName,
    };
    this.subscriptions['searchTests'] = this.monitoringService.getTestCases(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize).subscribe((response: any) => {
      this.resultsPagination.updateTotals(response.totalElements);
      this.testCases = response.content;
    });
  }

  testModal() {
    const modalRef = this.modalService.open(TestCaseModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.onSaveTest.subscribe(({ testToRun }) => {
      this.searchTests(this.resultsPagination.currentPage);
      if (testToRun) {
        this.runTests([testToRun]);
      }
    });
  }

  checkUncheckAll(event) {
    if (event.target.checked) {
      this.testCases.forEach((t) => (t.selected = true));
    } else {
      this.testCases.forEach((t) => (t.selected = false));
    }
  }

  get indeterminate() {
    const selectedTestCases = this.testCases.filter((t) => t.selected).length;
    return selectedTestCases > 0 && selectedTestCases !== this.testCases.length;
  }
  get checked() {
    return this.testCases.length > 0 && this.testCases.filter((t) => t.selected).length === this.testCases.length;
  }

  openTestCase(test) {
    this.editTestCase = test;
  }
  closeTestCase() {
    this.editTestCase = null;
    this.searchTests(this.resultsPagination.currentPage);
  }

  runTestCase() {
    this.runTests([this.editTestCase]);
  }

  deleteTest(id) {
    this.notificationService
      .openModal({
        title: 'Confirm delete regression test',
        message: 'Are you sure to remove the regression test?',
        choice: 'multi',
      })
      .subscribe((confirm: boolean) => {
        if (!confirm) return;
        this.subscriptions['DeleteTestCase'] = this.monitoringService.deleteTestCase(id).subscribe(() => this.searchTests(this.resultsPagination.currentPage));
      });
  }
  runSelectedTests() {
    const selectedTests = this.testCases.filter((t) => t.selected);
    if (selectedTests.length > 0) {
      this.runTests(selectedTests);
    }
  }

  runTests(tests: Array<TestCase>) {
    const body = {
      journeyApiKey: this.designService.sessionJourney.journey.apiKey,
      ids: tests.map((t) => t.id),
    };
    this.rxStompService.publish({ destination: '/app/executeTest', body: JSON.stringify(body) });
    tests.forEach((t) => {
      t.status = TEST_STATUS_ENUM.RUNNING;
    });
  }

  updateEditTestCase() {
    this.subscriptions['TestCase'] = this.monitoringService.getTestCase(this.editTestCase.id).subscribe((testCase) => {
      this.editTestCase = testCase;
    });
  }

  uploadTestFile(data: FileList) {
    const params = {
      journeyApiKey: this.designService.sessionJourney.journey.apiKey,
    };
    this.subscriptions['ImportTestCases'] = this.monitoringService.importTestCases(data, params).subscribe(() => this.searchTests(1));
  }

  exportTests() {
    this.subscriptions['ExportTestCases'] = this.monitoringService.exportTestCases(this.testCases.filter((t) => t.selected).map((t) => t.id)).subscribe();
  }
}
