import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IntentService } from '../../../../services/intent.service';
import { DesignService } from '../../../../services/design.service';
import { AnalyticsService } from '../../../../services/analytics.service';

@Component({
  selector: 'app-delete-dialogflow-connector-modal',
  templateUrl: './delete-dialogflow-connector-modal.component.html',
  styleUrls: ['./delete-dialogflow-connector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteDialogflowConnectorModalComponent implements  OnDestroy {
  @Output() private onDeleteConnector =  new EventEmitter<any>();
  @Input() private journeyApiKey: number;
  @Input() private connectorId: number;

  subscriptions: Object = {};

  constructor(public activeModal: NgbActiveModal, private designService: DesignService, private intentService: IntentService, private analyticsService: AnalyticsService) { }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  deleteConnector() {
    this.subscriptions['DeleteConnector'] = this.designService.removeConnector('dialogFlow', this.connectorId).subscribe(() => {
      this.activeModal.close();
      this.onDeleteConnector.emit();
    });
  }

  exportIntents() {
    const params = {
      journeyApiKey: this.journeyApiKey
    };
    this.subscriptions['ExportIntents'] = this.intentService.exportIntentsData(params).subscribe();
  }

  exportHistory() {
    const params = {
      journeyApiKey: this.journeyApiKey
    };
    this.subscriptions['ExportHistory'] = this.analyticsService.exportConversationalHistory(params).subscribe();
  }

}
