import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

@Component({
  selector: 'list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {
  @Input() element: FormGroup;
  @Input() formSubmitted: boolean;
  @Output() onAddList = new EventEmitter<any>();

  iterableChanges: Subscription;
  
  get itemArray() { return this.element.get('items') as FormArray }

  ngOnInit() {
    this.iterableChanges = this.element.get('iterable').valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.itemArray.controls.forEach((control: AbstractControl, index: number) => {
          if (index > 0) this.removeList(index);
        })
      }
    });
  }

  ngOnDestroy() {
    this.iterableChanges.unsubscribe();
  }

  addList() {
    this.onAddList.emit();
  }

  removeList(listIndex: number) {
    if (this.itemArray.length === 1) return;
    this.itemArray.removeAt(listIndex);
  }

}
