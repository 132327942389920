import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'market-modules',
  templateUrl: './market-modules.component.html',
  styleUrls: ['./market-modules.component.scss']
})
export class MarketModulesComponent {
  @Input() modules: Array<any>;
  @Output() onBuyProduct = new EventEmitter<any>();
  @Output() onDetailsProduct = new EventEmitter<any>();

  buyItem() {
    this.onBuyProduct.emit();
  }

  detailsItem(item: any) {
    this.onDetailsProduct.emit(item);
  }

}
