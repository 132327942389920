import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChannelsService } from 'src/app/services/channels.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-rcc-item-modal',
  templateUrl: './rcc-item-modal.component.html',
  styleUrls: ['./rcc-item-modal.component.scss']
})
export class RccItemModalComponent implements OnInit {

  @Input() channel: any = {};
  @Output() onSuccessSaved = new EventEmitter<any>();

  rccForm = this.fb.group({
    callbackUrl: this.fb.control('', Validators.required),
    authToken: this.fb.control('', Validators.required),
    transferToAgent: this.fb.control(false),
    changeAgentAction: this.fb.control(''),
    defaultAgentName: this.fb.control(''),
    topicAgentArray: this.fb.array([])
  }, {
    validators: this.duplicateAgents
  });
  
  get callbackUrl() {return this.rccForm.get('callbackUrl') as FormControl}
  get authToken() {return this.rccForm.get('authToken') as FormControl}
  get transferToAgent() {return this.rccForm.get('transferToAgent') as FormControl}
  get changeAgentAction() {return this.rccForm.get('changeAgentAction') as FormControl}
  get topicAgentArray() {return this.rccForm.get('topicAgentArray') as FormArray}
  get defaultAgentName() {return this.rccForm.get('defaultAgentName') as FormArray}
  get topicAgentField() {
    return this.fb.group({
      agent: this.fb.control('', Validators.required),
      topic: this.fb.control('', Validators.required)
    })
  }

  formSubmitted = false;
  subscriptions = {};

  constructor(private activeModal: NgbActiveModal, public commonService: CommonService, private channelsService: ChannelsService, private fb: FormBuilder) { }

  ngOnInit() {
    this.rccForm.patchValue(this.channel);
    if (this.channel.agentsMapping) {
      Object.keys(this.channel.agentsMapping).forEach(agent => {
        const topicAgentField = this.topicAgentField;
        topicAgentField.patchValue({
          agent,
          topic: this.channel.agentsMapping[agent].join(',')
        })
        this.topicAgentArray.push(topicAgentField);
      })
    }
    this.transferToAgent.patchValue(this.changeAgentAction.value != null);
  }

  closeModal(): void {
    this.activeModal.close(false);
  }

  saveChanges() {
    this.formSubmitted = true;
    if (this.rccForm.invalid) return;

    if (!this.channel.id) {
      this.subscriptions['RccChannel'] = this.channelsService.createChannel('timrcc', this.generateRequest()).subscribe((data) => {
        this.onSuccessSaved.emit(data);
        this.closeModal();
      })
    } else {
      this.subscriptions['RccChannel'] = this.channelsService.updateChannel('timrcc', this.generateRequest()).subscribe((data) => {
        this.onSuccessSaved.emit(data);
        this.closeModal();
      })
    }
  }

  addTopicAgentField() {
    this.topicAgentArray.push(this.topicAgentField);
  }

  removeTopicAgent(index) {
    this.topicAgentArray.removeAt(index);
  }

  drop(event: CdkDragDrop<string[]>) {
    let actionsArray = this.topicAgentArray.value;
    moveItemInArray(actionsArray, event.previousIndex, event.currentIndex);
    this.topicAgentArray.patchValue(actionsArray);
  }

  private generateRequest() {
    const request = {...this.channel,
      ...{
        callbackUrl: this.callbackUrl.value,
        authToken: this.authToken.value,
        changeAgentAction: this.changeAgentAction.value || null,
        defaultAgentName: this.defaultAgentName.value,
        agentsMapping: {},
        enabled: true
      },
    }
    this.topicAgentArray.value.forEach(el => {
      request.agentsMapping[el.agent] = el.topic.split(',').map(topic => topic.trim());
    })
    return request;
  }

  
  private duplicateAgents(control: AbstractControl): ValidationErrors | null {
    const countObj = {};
    (control.get('topicAgentArray') as FormArray).controls.forEach(
      topicAgentField => {
        let countAgent = countObj[topicAgentField.get('agent').value];
        countObj[topicAgentField.get('agent').value] = countAgent ? countAgent + 1 : 1;
      }
    )
    const findDuplicate = Object.keys(countObj).find(key => countObj[key] > 1);
    return findDuplicate ? {duplicateAgents: true} : null
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach(key => {
      this.subscriptions[key].unsubscribe();
    })
  }

}
