import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { Pagination } from '../../classes/pagination';
import { IntentService } from '../../services/intent.service';
import { DesignService } from '../../services/design.service';
import { ActivatedRoute } from '@angular/router';
import { tutorials } from 'src/app/enums/tutorial.enum';
import { CreateIntnentsTutorialIntentParametersService } from 'src/app/services/tutorials/create-intnents-tutorial.intent-parameters.service';

@Component({
  selector: 'intent-parameters',
  templateUrl: './intent-parameters.component.html',
  styleUrls: ['./intent-parameters.component.scss']
})
export class IntentParametersComponent implements OnInit, OnDestroy {
  subscriptions: Object = {};
  intentsPagination: Pagination = new Pagination();

  filter: any = {};
  intents: Array<any> = [];
  intentResponses: Array<any>;

  constructor(private tutorial: CreateIntnentsTutorialIntentParametersService, private intentService: IntentService, private designService: DesignService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.subscriptions['tutorialSubscription'] = this.route.queryParams.subscribe(({ tutorial }) => {
      if (tutorial === tutorials.CREATE_INTENTS) {
        this.tutorial.startTutorial(this);
      }
    })

    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      if (this.subscriptions['AgentLanguage'] instanceof Subscription) this.subscriptions['AgentLanguage'].unsubscribe();

      this.subscriptions['AgentLanguage'] = this.designService.getSessionAgentLang().subscribe(() => {
        if (this.intentResponses) this.closeResponseSetting();
        this.searchIntents(1);
      });
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  searchIntents(pageSelected: number) {
    this.intentsPagination.onSelectPage(pageSelected);

    const params = {
      intentName: this.filter.intentName || null
    };

    this.subscriptions['Intents'] = this.intentService.getIntentsParameters(params, this.intentsPagination.getPageIndex(), this.intentsPagination.pageSize).subscribe((response: any) => {
      this.intents = response.content;
      this.intentsPagination.updateTotals(response.totalElements);
    });
  }

  responsesSetting(intentResponses?: Array<any>) {
    this.intentResponses = intentResponses || [];
  }

  closeResponseSetting() {
    this.intentResponses = null;
    this.searchIntents(this.intentsPagination.currentPage);
  }

}
