import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, ViewChildren, QueryList, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Test } from 'src/app/interfaces/test';
import { TestCase } from 'src/app/interfaces/testCase';
import { DesignService } from 'src/app/services/design.service';
import { DialogService } from 'src/app/services/dialog.service';
import { MonitoringService } from 'src/app/services/monitoring.service';
const DEFAULT_WELCOME_INTENT = 'Default Welcome Intent';
@Component({
  selector: 'app-test-case-modal',
  templateUrl: './test-case-modal.component.html',
  styleUrls: ['./test-case-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TestCaseModalComponent implements OnInit, OnDestroy {
  @Input() testCase?: Partial<TestCase> = null;
  @Output() onSaveTest = new EventEmitter<{ testToRun: Partial<TestCase> }>();
  @ViewChildren('popIntent') popIntent: QueryList<NgbPopover>;

  subscriptions: Object = {};

  intents: Array<any>;
  defaultIntentName: string;

  testCaseForm: FormGroup = this.fb.group({
    testName: ['', Validators.required],
    regressionTest: this.fb.array([]),
    journeyApiKey: ['', Validators.required],
    lang: ['', Validators.required],
  });
  testSubmitted = false;

  get regressionTestsValues() {
    return this.testCaseForm.get('regressionTest') as FormArray;
  }

  private popInteractionIndex: number;

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private dialogService: DialogService, private monitoringService: MonitoringService, private designService: DesignService) {}

  ngOnInit() {    
    this.getIntents().then(() => {
      if (this.testCase) {
        this.testCaseForm.addControl('id', this.fb.control(''));
        this.testCaseForm.get('testName').disable();
        this.testCaseForm.patchValue(this.testCase);
        this.processTestsArray(this.regressionTestsValues, this.testCase.regressionTest);
      } else {
        this.testCaseForm.get('journeyApiKey').setValue(this.designService.sessionJourney.journey.apiKey);
        this.testCaseForm.get('lang').setValue(this.designService.sessionAgentLang);

        this.addregressionTest();
      }
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  setIntent(intent: any) {
    this.testCaseForm.get(['regressionTest', this.popInteractionIndex, 'intent']).setValue(intent.name);
    this.popIntent.toArray()[this.popInteractionIndex].close();
  }

  addregressionTest() {
    this.regressionTestsValues.push(
      this.fb.group({
        userInput: ['', Validators.required],
        intent: [this.defaultIntentName, Validators.required],
      })
    );
  }

  togglePopIntent(interactionIndex: number) {
    this.popInteractionIndex = interactionIndex;

    if (this.popIntent.toArray()[interactionIndex].isOpen()) {
      this.popIntent.toArray()[interactionIndex].close();
    } else {
      this.popIntent.toArray()[interactionIndex].open();
    }
  }

  getIntents() {
    return new Promise<boolean>((resolve, reject) => {
      this.subscriptions['intents'] = this.dialogService.getIntents().subscribe(
        (response: any) => {
          this.intents = response.data;
          const defaultIntent = this.intents.find((i) => i.name === DEFAULT_WELCOME_INTENT) || this.intents[0];
          this.defaultIntentName = defaultIntent.name;
          resolve(true);
        },
        (onError) => reject(onError)
      );
    });
  }

  processTestsArray(formArray: FormArray, arrayData: Array<Test>): void {
    if (arrayData.length) {
      arrayData.forEach((element) => {
        formArray.push(
          this.fb.group({
            id: [element.id],
            userInput: [element.userQuery, Validators.required],
            intent: [element.expectedIntent, Validators.required],
          })
        );
      });
    }
  }

  removeTest(index) {
    if (this.regressionTestsValues.length > 1) {
      this.regressionTestsValues.removeAt(index);
    }
  }
  saveTest(run) {
    this.testSubmitted = true;
    if (this.testCaseForm.invalid) return;

    const regressionTest = this.testCaseForm.value['regressionTest'].map((test) => ({ userQuery: test.userInput, expectedIntent: test.intent, expectedResponse: 'expectedResponse', id: test.id }));
    const data = { ...this.testCaseForm.getRawValue(), ...{ regressionTest } };

    this.subscriptions['TestCaseSubscription'] = this.monitoringService.saveOrUpdateTestCase(data).subscribe((id) => {
      this.activeModal.close();
      if (run) {
        data.id = id;
        this.onSaveTest.emit({ testToRun: data });
      } else {
        this.onSaveTest.emit({ testToRun: null });
      }
    });
  }
}
