export class Button {
  classes: string;
  text: string;
  type: string;
  action: () => void;
  constructor({ classes, text, action, type }: { classes; text; action?; disabled?; type? }) {
    this.classes = classes;
    this.text = text;
    this.type = type;
    this.action = action;
  }
}

export class NextButton extends Button {
  constructor(action?: () => void) {
    const buttonAction = action ? { action } : { type: 'next' };
    super({ classes: 'ml-2 btn brand_default', text: 'Next', ...buttonAction });
  }
}

export class ExitButton extends Button {
  constructor(action?: () => void) {
    const buttonAction = action ? { action } : { type: 'cancel' };

    super({ classes: 'btn brand_thin mr-auto', text: 'Exit', ...buttonAction });
  }
}
export class BackButton extends Button {
  constructor(action?: () => void) {
    const buttonAction = action ? { action } : { type: 'back' };

    super({ classes: 'btn brand_light', text: 'Back', ...buttonAction });
  }
}

export class CompleteButton extends Button {
  constructor() {

    super({ classes: 'ml-2 btn brand_default', text: 'Complete', type: 'next' });
  }
}
