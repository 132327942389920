import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService } from '../../services/notification.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnDestroy {
  @ViewChild('changePasswordForm', { static: true }) changePasswordForm: NgForm;

  changePasswordSubscription: Subscription;
  user: any = {};

  get regExPattern() { return this.authenticationService.passwordRequirements.regExPattern; }

  constructor(private authenticationService: AuthenticationService, private router: Router, private notificationService: NotificationService) { }

  ngOnDestroy() {
    if (this.changePasswordSubscription instanceof Subscription) this.changePasswordSubscription.unsubscribe();
  }

  changePassword() {
    if (this.changePasswordForm.invalid || this.user.newPassword !== this.user.confirmPassword) return;

    const data = {
      oldPassword: this.user.oldPassword,
      newPassword: this.user.newPassword
    };
    this.changePasswordSubscription = this.authenticationService.changePassword(data).subscribe((response: any) => {
      this.router.navigate(['']);
      this.notificationService.showToast('Password was correctly updated', { type: 'success' });
    });
  }

  onTypePassword(popPassword: NgbPopover) {
    if (!(this.changePasswordForm.controls.newPassword.errors || {}).pattern) {
      popPassword.close();
    }
  }

}
