import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DesignService } from 'src/app/services/design.service';
import { MonitoringService } from 'src/app/services/monitoring.service';
import { HealthStatusModalComponent } from '../../modals/health-status-modal/health-status-modal.component';

@Component({
  selector: 'app-health-status',
  templateUrl: './health-status.component.html',
  styleUrls: ['./health-status.component.scss'],
})
export class HealthStatusComponent implements OnInit {
  pods = [];
  channels = [];

  componentLoaded = false;
  constructor(private monitoringService: MonitoringService, private modalService: NgbModal, private designService: DesignService) {}

  ngOnInit() {
    this.designService.getSessionJourney().subscribe(() => {
      this.pods = [];
      this.channels = [];
      this.loadPodStatuses();
    })
  }

  async loadPodStatuses() {
    this.pods.push(await this.getComponentStatus('Chat'));
    this.pods.push(await this.getComponentStatus('Analysis'));
    this.pods.push(await this.getComponentStatus('Configurator'));
    this.pods.push(await this.getComponentStatus('Console'));

    // this.channels.push(await this.getComponentStatus('Waba'));
    // this.channels.push(await this.getComponentStatus('Vivocha'));
    // this.channels.push(await this.getComponentStatus('TIM RCC'));
    // this.channels.push(await this.getComponentStatus('Facebook'));
    // this.channels.push(await this.getComponentStatus('Liveperson'));

    this.componentLoaded = true;
  }
  private async getComponentStatus(name) {
    try {
      let status;
      switch (name) {
        case 'Chat':
          status = await this.monitoringService.getChatStatus().toPromise();
          break;
        case 'Analysis':
          status = await this.monitoringService.getAnalysisStatus().toPromise();
          break;
        case 'Configurator':
          status = await this.monitoringService.getConfiguratorStatus().toPromise();
          break;
        case 'Console':
          status = await this.monitoringService.getConsoleStatus().toPromise();
          break;
        case 'Waba':
          status = await this.monitoringService.getWhatsAppStatus().toPromise();
          break;
        case 'Vivocha':
          status = await this.monitoringService.getVivochaStatus().toPromise();
          break;
        case 'TIM RCC':
          status = await this.monitoringService.getTimrccStatus().toPromise();
          break;
        case 'Facebook':
          status = await this.monitoringService.getFacebookStatus().toPromise();
          break;
        case 'Liveperson':
          status = await this.monitoringService.getLivepersonStatus().toPromise();
          break;
      }
      return { ...status, name };
    } catch (error) {
      return {
        name,
        status: 'DOWN',
      };
    }
  }

  statusModal(statuses) {
    if (statuses.components) {
      const modalRef = this.modalService.open(HealthStatusModalComponent);
      modalRef.componentInstance.statuses = statuses;
    }
  }
}
