import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private http: HttpClient) {}

  getConversations(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('pageSize', pageSize.toString()).set('pageIndex', pageNumber.toString()),
    };

    const allowedParams = [
      'sessionId',
      'startDate',
      'endDate',
      'approved',
      'archived',
      'fallback',
      'minSentimentMedia',
      'maxSentimentMedia',
      'agentChannel',
      'userId',
      'userIdNotNull',
      'withoutTopics',
      'tags',
      'sortBy',
      'interactionGreater',
      'interactionLesser',
      'interactionEq',
    ];
    for (const key of allowedParams) {
      if (params[key] !== null && params[key] !== undefined) {
        httpOptions.params = httpOptions.params.append(key, params[key]);
      }
    }
    return this.http.get(`${environment.API.orchestrator_analysis}/conversations/view`, httpOptions);
  }

  archiveConversation(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_analysis}/conversations/archive`, data);
  }

  unarchiveConversation(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_analysis}/conversations/unarchive`, data);
  }

  assignConversation(data: any, idConversation: Array<number>, idUser: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('conversationIds', idConversation.toString()).set('idUser', idUser.toString()),
    };
    return this.http.post(`${environment.API.orchestrator_analysis}/assigned/conversations`, data, httpOptions);
  }

  unassignConversation(idConversation: Array<number>): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('conversationIds', idConversation.toString()),
    };
    return this.http.delete(`${environment.API.orchestrator_analysis}/assigned/conversations/deleteByConversationIds`, httpOptions);
  }

  getInteractions(sessionId: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('sessionId', sessionId),
    };

    return this.http.get(`${environment.API.orchestrator_analysis}/messages/view`, httpOptions);
  }

  getInteractionHistory(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('pageSize', pageSize.toString()).set('pageIndex', pageNumber.toString()),
    };

    if (params.startDate) httpOptions.params = httpOptions.params.append('startDate', params.startDate);
    if (params.endDate) httpOptions.params = httpOptions.params.append('endDate', params.endDate);
    if (params.sessionId) httpOptions.params = httpOptions.params.append('sessionId', params.sessionId);
    if (params.dfIntentName) httpOptions.params = httpOptions.params.append('dfIntentName', params.dfIntentName);
    if (params.userQuery) httpOptions.params = httpOptions.params.append('userQuery', params.userQuery);
    if (params.rating) httpOptions.params = httpOptions.params.append('operatorRatings', params.rating);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);

    return this.http
      .get<any>(`${environment.API.orchestrator_analysis}/messages/view/history`, httpOptions)
      .pipe(map((res) => ({ ...res, data: res.data.map((d) => ({ ...d, agentBotType: 'DialogFlow ES' })) })));
  }

  getEntities(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('intentName', params.intentName).set('agentLang', params.agentLang),
    };
    return this.http.get(`${environment.API.orchestrator_analysis}/nlu/integrations/intents/parameters`, httpOptions);
  }

  getIntents(params?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('onlyName', 'false'),
    };

    if (params && params.journeyApiKey) httpOptions.params = httpOptions.params.append('journeyApiKey', params.journeyApiKey);

    return this.http.get(`${environment.API.orchestrator_analysis}/nlu/integrations/intents/search`, httpOptions);
  }

  getIntent(name: string, lang: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('intentName', name).set('agentLang', lang).set('onlyName', 'false'),
    };
    return this.http.get(`${environment.API.orchestrator_analysis}/nlu/integrations/intents`, httpOptions);
  }

  approveConversation(conversation: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_analysis}/nlu/integrations/intents/training/phrases`, conversation);
  }

  getCsvExport(params: any): Observable<any> {
    const httpOptions = {
      responseType: 'text' as 'text',
      params: new HttpParams().set('startDate', params.startDate.toString()).set('endDate', params.endDate.toString()),
    };
    return this.http.get(`${environment.API.orchestrator_analysis}/operations/download/history`, httpOptions);
  }
}
