import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { DesignService } from '../../services/design.service';

import { FaqAnalysisIntentModalComponent } from '../../components/modals/faq-analysis-intent-modal/faq-analysis-intent-modal.component';
import { IntentModalComponent } from '../modals/intent-modal/intent-modal.component';

@Component({
  selector: 'app-faq-analysis',
  templateUrl: './faq-analysis.component.html',
  styleUrls: ['./faq-analysis.component.scss']
})
export class FaqAnalysisComponent implements OnInit, OnDestroy {
  analyzeSubscriptions: Subscription;

  predictions: any = {};
  multiSelection: Array<any> = [];
  searchQuery: string;

  constructor(private modalService: NgbModal, private route: ActivatedRoute, private designService: DesignService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.getAnalysis(params.processId);
    });
  }

  ngOnDestroy() {
    if (this.analyzeSubscriptions instanceof Subscription) this.analyzeSubscriptions.unsubscribe();
  }

  getAnalysis(processId: number) {
    this.analyzeSubscriptions = this.designService.getFaqAnalysis(processId).subscribe((response: any) => {
      this.predictions = response;
    });
  }

  filterPredictions(): any {
    const matchAnswers = (answer) => {
      return answer.toLowerCase().includes(this.searchQuery.toLowerCase());
    };

    return (this.predictions.faqs || []).filter((prediction: any) => {
      if (!this.searchQuery) return true;

      return prediction.question.toLowerCase().includes(this.searchQuery.toLocaleLowerCase()) || matchAnswers(prediction.answer);
    });
  }

  saveAsIntent(prediction: any) {
    const modalRef = this.modalService.open(FaqAnalysisIntentModalComponent, {
      size: (Array.isArray(prediction)) ? 'md' : 'lg'
    });
    modalRef.componentInstance.prediction = prediction;
  }

  intentModal() {
    const modalRef = this.modalService.open(IntentModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.createOption = 'importFaq';
  }

  selectPrediction(prediction: any) {
    if (this.isPredictionSelected(prediction)) {
      this.multiSelection = this.multiSelection.filter(selected => selected.id !== prediction.id);
    } else {
      this.multiSelection.push(prediction);
    }
  }

  isPredictionSelected(prediction: any): boolean {
    return this.multiSelection.some(selected => selected.id === prediction.id)
  }

  selectAll() {
    if (this.multiSelection.length === this.predictions.faqs.length) {
      this.multiSelection = [];
    } else {
      this.predictions.faqs.forEach((prediction: any) => {
        if (!this.isPredictionSelected(prediction)) this.multiSelection.push(prediction);
      });
    }
  }

}
