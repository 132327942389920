import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'market-templates',
  templateUrl: './market-templates.component.html',
  styleUrls: ['./market-templates.component.scss']
})
export class MarketTemplatesComponent {
  purchasedTemplates : Array<any>;
  notPurchasedTemplates: Array<any>;

  @Input() set templates( templates: Array<any> ){
    if( templates ){
      this.purchasedTemplates= templates.filter(template=>template.purchased)
      this.notPurchasedTemplates =  templates.filter(template=>!template.purchased)
    }
  }
  @Output() onBuyProduct = new EventEmitter<any>();
  @Output() onDetailsProduct = new EventEmitter<any>();

  buyItem() {
    this.onBuyProduct.emit();
  }

  detailsItem(item: any) {
    this.onDetailsProduct.emit(item);
  }

}
