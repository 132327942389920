import { Component, Input } from '@angular/core';

@Component({
  selector: 'training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss'],
})
export class TrainingCardComponent {
  @Input() stats;
}
