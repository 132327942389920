import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-health-status-modal',
  templateUrl: './health-status-modal.component.html',
  styleUrls: ['./health-status-modal.component.scss'],
})
export class HealthStatusModalComponent implements OnInit {
  @Input() statuses;

  DOWN = 'DOWN';
  UP = 'UP';


  constructor(public activeModal: NgbActiveModal) {}
  ngOnInit(): void {
    console.log(this.statuses)
  }

  changeLivepersonAgentStatus(agentName, status) {
    this.statuses.agents[agentName].status  = status;
    if (status === this.DOWN) {
      this.statuses.status = this.DOWN;
    } else if (this.statuses.status === this.DOWN){
      const isOneComponentDown = Object.values(this.statuses.components).some((el: any) => el.status === this.DOWN);
      if (!isOneComponentDown) {
        const isOneAgentDown = Object.values(this.statuses.agents).some((el: any) => el.status === this.DOWN);
        if (!isOneAgentDown) {
          this.statuses.status = this.UP;
        }
      }
    }
  }

  removeAgent(agentName) {
    delete this.statuses.agents[agentName];
  }
}
