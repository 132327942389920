import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { NotificationService } from '../../../services/notification.service';
import { DesignService } from '../../../services/design.service';
import { MonitoringService } from '../../../services/monitoring.service';

@Component({
  selector: 'app-export-logs-modal',
  templateUrl: './export-logs-modal.component.html',
  styleUrls: ['./export-logs-modal.component.scss']
})
export class ExportLogsModalComponent {
  @Input('exportFitler') exportFitler: any;
  @ViewChild('exportForm', { static: true }) exportForm: NgForm;

  exportSubscription: Subscription;
  storageInfo: any = {};
  uploadedFileData: FormData;
  uploadedFileName: string;

  constructor(public activeModal: NgbActiveModal, private designService: DesignService, private monitoringService: MonitoringService, private notificationService: NotificationService) { }

  uploadServiceAccount(files: FileList) {
    this.uploadedFileName = files[0].name;

    let data = new FormData();
    data.append('file', files[0], files[0].name);
    this.uploadedFileData = data;
  }

  exportLogs() {
    if (this.exportForm.invalid) return;

    const params = {...this.exportFitler, ...this.storageInfo,
      journeyApiKey: this.designService.sessionJourney.journey.apiKey
    };
    
    this.exportSubscription = this.monitoringService.exportLogsOnStorage(this.uploadedFileData, params).subscribe(() => {
      this.notificationService.showToast('The export has been completed successfully, the logs are been loaded on your storage.', { type: 'success' });

      this.activeModal.close();
    });
  }

}
