import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { NgbModal, NgbDate, NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { Pagination } from '../../classes/pagination';
import { PaginationSlide } from '../../classes/pagination-slide';
import { TableSortDirective } from '../../directives/table-sort.directive';
import { DialogService } from '../../services/dialog.service';
import { TrainingModalComponent } from '../modals/training-modal/training-modal.component';
import { AdminService } from '../../services/admin.service';
import { DesignService } from '../../services/design.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrainingComponent implements OnInit, OnDestroy {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;
  @ViewChild('popRequestFilter', {static: false}) popRequestFilter: NgbPopover;

  subscriptions: Object = {};
  resultResolved: boolean;
  resultsPagination: Pagination = new Pagination();
  resultSlide: PaginationSlide;

  results: Array<any> = [];
  tagFilters: any = {};

  yesterday = new Date( new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1 );
  filter: any = {
    fromDate: new NgbDate(this.yesterday.getFullYear(), this.yesterday.getMonth() + 1, this.yesterday.getDate()),
    toDate: new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()),
  };
  filterFor: string = 'sessionId';
  idResearchTab: 'conversations' | 'assigned' | 'archived' = 'conversations';
  sentimentMedia = '';
  topicsFilter = {
    labels: {
      allMine: 'Your topics',
      allOther: 'Other topics',
      without: 'Without topics',
    },
    typeSelected: null,
  };
  get defaultFilter() {
    return {
      agentChannel: '',
      filter: this.filter,
      fallback: null,
    };
  }
  filterData: any = this.defaultFilter;
  sidebarTags: { active: boolean, typeSelected?: string, label: string } = { active: false, label: 'All conversations' };
  requestFilterCondition = 'interactionGreater';
  requestFilterNumber;
  conversation: any;

  constructor(private dialogService: DialogService, private modalService: NgbModal, private adminService: AdminService, private designService: DesignService, private notificationService: NotificationService, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      if (this.subscriptions['AgentLanguage'] instanceof Subscription) this.subscriptions['AgentLanguage'].unsubscribe();
      
      this.subscriptions['AgentLanguage'] = this.designService.getSessionAgentLang().subscribe(() => {
        this.searchItems(1);
      })
    });
    this.getTagFilters();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
    if (this.designService.sessionAgentLang === 'all') this.designService.setSessionAgentLang(this.designService.sessionAgent.supportedLang[0]);
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchItems(this.resultsPagination.currentPage);
  }

  viewConversation(item: any) {
    this.resultSlide = new PaginationSlide(this.results, this.resultsPagination, item, 'sessionId');
    this.resultSlide.onMovePage.subscribe(async $event => {
      await this.searchItems($event.targetPage);
      this.resultSlide.changePage($event.direction, this.results);
    });

    const modalRef = this.modalService.open(TrainingModalComponent, {
      size: 'xl'
    });
    modalRef.componentInstance.conversation = item;
    modalRef.componentInstance.conversationSlide = this.resultSlide;
    modalRef.componentInstance.onUpdateResults.subscribe($event => this.searchItems(this.resultsPagination.currentPage));
  }

  async searchItems(pageSelected: number) {
    this.resultResolved = false;
    this.resultsPagination.onSelectPage(pageSelected);

    if (this.filterFor === 'sessionId') await this.searchConversations();
    if (this.filterFor === 'intent') await this.searchIntents();
    if (this.filterFor === 'phrase') await this.searchPhrase();

    this.resultResolved = true;
  }

  changeSentimentFilter() {
    switch (this.sentimentMedia) {
      case '':
        this.filter.minSentimentMedia = -1;
        this.filter.maxSentimentMedia = 1;
        break;
      case 'Positive':
        this.filter.minSentimentMedia = 0.25;
        this.filter.maxSentimentMedia = 1;
        break;
      case 'Neutral':
        this.filter.minSentimentMedia = -0.250001;
        this.filter.maxSentimentMedia = 0.249999;
        break;
      case 'Negative':
        this.filter.minSentimentMedia = -1;
        this.filter.maxSentimentMedia = -0.25;
        break;
    }
    this.searchItems(1);
  }
  get activeFilters(): boolean {
    return this.filterData.fallback || this.filterData[this.requestFilterCondition];
  }
  get indeterminate() {
    const selectedConversations = this.results.filter((conversation) => conversation.selected).length;
    return selectedConversations > 0 && selectedConversations !== this.results.length;
  }
  get checked() {
    return this.results.length > 0 && this.results.filter((conversation) => conversation.selected).length === this.results.length;
  }
  get conversationIdSelection(): Array<number> {
    const conversationsId: Array<number> = [];
    this.results.filter((conversation) => conversation.selected).forEach((selected) => conversationsId.push(selected.id));
    return conversationsId;
  }

  switchIdResearchTab() {
    this.filterData = {...this.filterData,
      archived: null,
      userIdNotNull: null
    };
    if (this.idResearchTab === 'archived') this.filterData.archived = 'true';
    else if (this.idResearchTab === 'assigned') this.filterData.userIdNotNull = 'true';
    this.searchItems(1);
  }

  selectAllToggle(checked: boolean) {
    if (checked) {
      this.results.forEach((conversation) => (conversation.selected = true));
    } else {
      this.results.forEach((conversation) => (conversation.selected = false));
    }
  }

  searchConversations(): Promise<void> {
    const params = {
      sessionId: this.filter.id || null,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month-1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month-1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      approved: this.filter.approved,
      archived: this.filterData.archived || 'false',
      fallback: this.filter.fallback,
      minSentimentMedia: this.filter.minSentimentMedia || -1,
      maxSentimentMedia: this.filter.maxSentimentMedia || 1,
      agentChannel: this.filter.agentChannel || null,
      userIdNotNull: this.filterData.userIdNotNull || 'false',
      withoutTopics: this.filter.withoutTopics || null,
      tags: this.filter.tags,
      sortBy: this.filter.sortBy || null,
      interactionGreater: this.filter.interactionGreater,
      interactionLesser: this.filter.interactionLesser,
      interactionEq: this.filter.interactionEq
    };

    return this.dialogService.getConversations(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize).toPromise().then((response: any) => {
      this.results = response.data;
      this.resultsPagination.updateTotals(response.totalElements);
    });
  }

  searchIntents(): Promise<void> {
    const params = {
      dfIntentName: this.filter.intent || null,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      agentChannel: this.filter.agentChannel || null,
      sortBy: this.filter.sortBy || null
    };

    return this.dialogService.getInteractionHistory(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize).toPromise().then((response: any) => {
      this.results = response.data;
      this.resultsPagination.updateTotals(response.totalElements);
    });
  }

  searchPhrase(): Promise<void> {
    const params = {
      userQuery: this.filter.phrase || null,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      agentChannel: this.filter.agentChannel || null,
      sortBy: this.filter.sortBy || null
    };

    return this.dialogService.getInteractionHistory(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize).toPromise().then((response: any) => {
      this.results = response.data;
      this.resultsPagination.updateTotals(response.totalElements);
    })
  }

  updateDate($event) {
    if ($event.type === 'from') {
      this.filter.fromDate = $event.date;
      if (this.filter.fromDate instanceof NgbDate) this.searchItems(1);
    }
    if ($event.type === 'to') {
      this.filter.toDate = $event.date;
      if (this.filter.toDate instanceof NgbDate) this.searchItems(1);
    }
  }

  isAssigned(conversation: any): boolean {
    return !!(conversation.userEntity || {}).id;
  }

  assignConversations(conversation?: any) {
    const conversationsId = conversation ? [conversation.id] : this.conversationIdSelection;
    console.log('assegnato')
    this.subscriptions['AssignConversation'] = this.dialogService
       .assignConversation({}, conversationsId, this.authenticationService.user.id)
       .subscribe(() => {
         this.notificationService.showToast(`Conversation correcty assigned`, { type: 'success' });
         this.searchItems(this.resultsPagination.currentPage);
       });
  }

  unassignConversations() {
    console.log('disassegnato');
     this.subscriptions['UnassignConversation'] = this.dialogService.unassignConversation(this.conversationIdSelection).subscribe(() => {
     this.notificationService.showToast(`Conversation correcty disassigned`, { type: 'success' });
     this.searchItems(this.resultsPagination.currentPage);
     });
  }

  archiveConversations(conversation?: any) {
    const conversationsId = conversation ? [conversation.id] : this.conversationIdSelection;
    console.log('archiviato');
    this.subscriptions['ArchiveConversation'] = this.dialogService.archiveConversation(conversationsId).subscribe(() => {
    this.notificationService.showToast(`Conversation correcty archived`, { type: 'success' });
    this.searchItems(this.resultsPagination.currentPage);
    });
  }

  unarchivedConversations(conversation?: any){
    const conversationsId = conversation ? [conversation.id] : this.conversationIdSelection;
    console.log('disarchiviato');
    this.subscriptions['ArchiveConversation'] = this.dialogService.unarchiveConversation(conversationsId).subscribe(() => {
    this.notificationService.showToast(`Conversation correcty unarchived`, { type: 'success' });
    this.searchItems(this.resultsPagination.currentPage);
    });
  }

  // archiveConversation(conversation: any) {
  //   const data = { id: conversation.id };
  //   this.subscriptions['ArchiveConversation'] = this.dialogService.archiveConversation(data).subscribe(async () => {
  //     if (!!(conversation.userEntity || {}).id) {
  //       await this.dialogService.unassignConversation(conversation.id).toPromise();
  //     }
  //     this.searchItems(this.resultsPagination.currentPage);
  //   });
  // }

  getTagFilters() {
    this.subscriptions['TagFilters'] = this.adminService.getTrainerTagsTree().subscribe((response: any) => {
      this.tagFilters = response;
    });
  }

  selectTagFilter(tagName: Array<string> | string, tagType?: 'mine' | 'other' | 'allMine' | 'allOther' | 'without') {
    this.filter.withoutTopics = (tagType === 'without') ? true : null;
    if (Array.isArray(tagName)) {
      this.filter.tags = [];
      tagName.forEach(tag => this.filter.tags.push(tag));
      this.sidebarTags.label = (tagType === 'allMine') ? 'Your topics' : 'Other topics'
    } else {
      this.filter.tags = tagName;
      if (tagName) {
        this.sidebarTags.label = tagName;
      } else {
        this.sidebarTags.label = tagType === 'without' ? 'Without topics' : 'All conversations';
      }
    }

    this.sidebarTags = {...this.sidebarTags,
      active: false,
      typeSelected: tagType || null
    };
    this.searchItems(1);
  }

  resetFilters() {
    this.filterData = this.defaultFilter;
    this.selectTopicFilter(null, null);
    this.resetFilterConditions();
    this.sidebarTags = { active: false, label: 'All conversations' };
    this.requestFilterCondition = 'interactionGreater';
    this.requestFilterNumber = null;
    this.searchItems(1);
  }

  filterRequests(clear = false) {
    this.clearFilterRequests();
    if (!clear) {
      this.filter[this.requestFilterCondition] = this.requestFilterNumber;
    } else {
      this.requestFilterCondition = 'interactionGreater';
      this.requestFilterNumber = null;
    }
    this.popRequestFilter.close();
    this.searchItems(1);
  }

  private clearFilterRequests() {
    this.filter.interactionGreater =  null;
    this.filter.interactionLesser = null;
    this.filter.interactionEq = null;
  }

  selectTopicFilter(topicTags: Array<string> | string | null, topicsType?: 'mine' | 'other' | 'allMine' | 'allOther' | 'without' | null) {
    this.filterData.withoutTopics = topicsType === 'without' ? true : false;

    if (['mine', 'other'].indexOf(topicsType) !== -1) {
      this.topicsFilter.typeSelected = topicTags;
      this.topicsFilter.labels[topicTags as string] = topicTags;
    } else {
      this.topicsFilter.typeSelected = topicsType;
    }

    this.filterData.tags = topicTags;

    this.searchItems(1);
  }

  resetFilterConditions() {
    this.requestFilterCondition = 'interactionGreater';
    this.filterData.interactionGreater = null;
    this.filterData.interactionLesser = null;
    this.filterData.interactionEq = null;
  }


}
