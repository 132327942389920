import { Component, OnInit, OnDestroy, Input, ViewChildren, ElementRef, QueryList } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MonitoringService } from '../../../services/monitoring.service';
import { SupportModalComponent } from '../../../components/modals/support-modal/support-modal.component';
import { UserSettingsModalComponent } from '../../modals/user-settings-modal/user-settings-modal.component';
import { DesignService } from '../../../services/design.service';
import { Connectors } from 'src/app/enums/connectors.enum';

declare let google: any;

const MILLIS_IN_YEAR = 1000 * 60 * 60 * 24 * 365;
@Component({
  selector: 'consumption-card',
  templateUrl: './consumption-card.component.html',
  styleUrls: ['./consumption-card.component.scss'],
})
export class ConsumptionCardComponent implements OnInit, OnDestroy {
  connectors = Connectors;
  @ViewChildren('billingSlide') private billingSlide: QueryList<ElementRef>;
  @Input() context: 'home' | 'billing';

  subscriptions: Object = {};
  enabledConnectors: Array<any> = [];
  promisesStatsClosed: boolean;

  cardCarouselOptions: OwlOptions = {
    dots: false,
    mouseDrag: false,
    items: 5,
  };

  constructor(private monitoringService: MonitoringService, private modalService: NgbModal, private designService: DesignService) {}

  ngOnInit() {
    google.charts.load('current', { packages: ['corechart'] });

    if (this.context === 'home') {
      this.cardCarouselOptions = {
        ...this.cardCarouselOptions,
        responsive: {
          0: { items: 1 },
          400: { items: 2 },
        },
      };
    } else if (this.context === 'billing') {
      this.cardCarouselOptions = { ...this.cardCarouselOptions, items: 5 };
    }

    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      this.enabledConnectors = [];
      this.getConsumptions();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.billingSlide.changes.subscribe(() => {
      if (!this.promisesStatsClosed) return;

      this.enabledConnectors.forEach((connector: any) => {
        switch (connector.chartId) {
          case `${Connectors.DIALOGFLOW}_chart`:
            this.drawDialogflowChart(connector);
            break;
          case `${Connectors.NATURAL_LANGUAGE}_chart`:
            this.drawNluChart(connector);
            break;
          case `${Connectors.DATA_LOSS_PREVENTION}_chart`:
            this.drawDlpChart(connector);
            break;
          case `${Connectors.TEXT_TO_SPEECH}_chart`:
            this.drawTtSChart(connector);
            break;
          case `${Connectors.TRANSLATE}_chart`:
            this.drawTranslateChart(connector);
            break;
        }
      });
    });
  }

  async getConsumptions() {
    this.promisesStatsClosed = false;

    const endDateSet = new Date().setHours(23, 59, 59, 999);
    const startDateSet = endDateSet - MILLIS_IN_YEAR;
    const params = {
      days: 0,
      startDate: startDateSet,
      endDate: endDateSet,
    };

    await this.getConnectorRequest('Google Dialogflow', Connectors.DIALOGFLOW, params);
    await this.getConnectorRequest('Data Loss Prevention', Connectors.DATA_LOSS_PREVENTION, params);
    await this.getConnectorRequest('Natural Language', Connectors.NATURAL_LANGUAGE, params);
    await this.getConnectorRequest('Text To Speech', Connectors.TEXT_TO_SPEECH, params);
    await this.getConnectorRequest('Translate', Connectors.TRANSLATE, params);

    this.promisesStatsClosed = true;
  }

  getConnectorRequest(name, connector, params): Promise<void> {
    return this.monitoringService
      .getRequestStats(connector, params.days, params.startDate, params.endDate)
      .toPromise()
      .then((response: any) => {
        if (!response.total) return;

        this.enabledConnectors.push({ ...response, chartId: `${connector}_chart`, name, connector });
      });
  }

  drawDialogflowChart(billingStats: any) {
    const totalPlaceholder = billingStats.total * (1 + 0.25);
    let remainingNegative: number;

    if (billingStats.remaining < 0) {
      remainingNegative = 0.01;
    }
    const data = google.visualization.arrayToDataTable([
      ['Connector', 'Requests'],
      ['', (totalPlaceholder / 100) * 25],
      ['Dialogflow', remainingNegative ? remainingNegative : billingStats.remaining || billingStats.total],
      ['Others', billingStats.total - (remainingNegative ? remainingNegative : billingStats.remaining)],
    ]);

    const options = {
      pieHole: 0.8,
      legend: 'none',
      pieStartAngle: 135,
      tooltip: { trigger: 'none' },
      enableInteractivity: false,
      slices: {
        0: { color: 'transparent' },
        1: { color: this.getFillerColor(billingStats) },
        2: { color: '#dee2e6' },
      },
      pieSliceText: 'none',
      height: 300,
    };

    const chart = new google.visualization.PieChart(document.getElementById(`${Connectors.DIALOGFLOW}_chart`));
    chart.draw(data, options);
  }

  drawNluChart(billingStats) {
    const totalPlaceholder = billingStats.total * (1 + 0.25);
    let remainingNegative: number;

    if (billingStats.remaining < 0) {
      remainingNegative = 0.01;
    }
    const data = google.visualization.arrayToDataTable([
      ['Connector', 'Requests'],
      ['', (totalPlaceholder / 100) * 25],
      ['Natural Language', remainingNegative ? remainingNegative : billingStats.remaining || billingStats.total],
      ['Others', billingStats.total - (remainingNegative ? remainingNegative : billingStats.remaining)],
    ]);

    const options = {
      pieHole: 0.8,
      legend: 'none',
      pieStartAngle: 135,
      tooltip: { trigger: 'none' },
      enableInteractivity: false,
      slices: {
        0: { color: 'transparent' },
        1: { color: this.getFillerColor(billingStats) },
        2: { color: '#dee2e6' },
      },
      pieSliceText: 'none',
      height: 300,
    };

    const chart = new google.visualization.PieChart(document.getElementById(`${Connectors.NATURAL_LANGUAGE}_chart`));
    chart.draw(data, options);
  }

  drawTtSChart(billingStats) {
    const totalPlaceholder = billingStats.total * (1 + 0.25);
    let remainingNegative: number;

    if (billingStats.remaining < 0) {
      remainingNegative = 0.01;
    }
    const data = google.visualization.arrayToDataTable([
      ['Connector', 'Requests'],
      ['', (totalPlaceholder / 100) * 25],
      ['Text to Speech', remainingNegative ? remainingNegative : billingStats.remaining || billingStats.total],
      ['Others', billingStats.total - (remainingNegative ? remainingNegative : billingStats.remaining)],
    ]);

    const options = {
      pieHole: 0.8,
      legend: 'none',
      pieStartAngle: 135,
      tooltip: { trigger: 'none' },
      enableInteractivity: false,
      slices: {
        0: { color: 'transparent' },
        1: { color: this.getFillerColor(billingStats) },
        2: { color: '#dee2e6' },
      },
      pieSliceText: 'none',
      height: 300,
    };

    const chart = new google.visualization.PieChart(document.getElementById(`${Connectors.TEXT_TO_SPEECH}_chart`));
    chart.draw(data, options);
  }

  drawDlpChart(billingStats) {
    const totalPlaceholder = billingStats.total * (1 + 0.25);
    let remainingNegative: number;

    if (billingStats.remaining < 0) {
      remainingNegative = 0.01;
    }

    const CONSUMED_TOOLTIP = `
      <ul class="list-unstyled m-2 text-nowrap">
        <li>Characters Inspected: <strong>${billingStats.characterInspected}</strong></li>
        <li>Characters Trasformed: <strong>${billingStats.characterTrasformed}</strong></li>
      </ul>
    `;
    const REMAINING_TOOLTIP = `
    <ul class="list-unstyled m-2 text-nowrap">
      <li>Remaining Characters: <strong>${billingStats.remaining}</strong></li>
    </ul>
  `;

    const data = new google.visualization.DataTable();
    data.addColumn('string', 'Connector');
    data.addColumn('number', 'Requests given');
    data.addColumn({ type: 'string', role: 'tooltip', p: { html: true } });
    data.addRows([
      ['', (totalPlaceholder / 100) * 25, CONSUMED_TOOLTIP],
      ['Data Loss Prevention', remainingNegative ? remainingNegative : billingStats.remaining || billingStats.total, REMAINING_TOOLTIP],
      ['Others', billingStats.total - (remainingNegative ? remainingNegative : billingStats.remaining), CONSUMED_TOOLTIP],
    ]);

    const options = {
      pieHole: 0.8,
      legend: 'none',
      pieStartAngle: 135,
      tooltip: {
        isHtml: true,
      },
      enableInteractivity: true,
      slices: {
        0: { color: 'transparent' },
        1: { color: this.getFillerColor(billingStats) },
        2: { color: '#dee2e6' },
      },
      pieSliceText: 'none',
      height: 300,
    };

    const chart = new google.visualization.PieChart(document.getElementById(`${Connectors.DATA_LOSS_PREVENTION}_chart`));
    chart.draw(data, options);
  }

  drawTranslateChart(billingStats) {
    const totalPlaceholder = billingStats.total * (1 + 0.25);
    let remainingNegative: number;

    if (billingStats.remaining < 0) {
      remainingNegative = 0.01;
    }
    const data = google.visualization.arrayToDataTable([
      ['Connector', 'Requests'],
      ['', (totalPlaceholder / 100) * 25],
      ['Translate', remainingNegative ? remainingNegative : billingStats.remaining || billingStats.total],
      ['Others', billingStats.total - (remainingNegative ? remainingNegative : billingStats.remaining)],
    ]);

    const options = {
      pieHole: 0.8,
      legend: 'none',
      pieStartAngle: 135,
      enableInteractivity: false,
      slices: {
        0: { color: 'transparent' },
        1: { color: this.getFillerColor(billingStats) },
        2: { color: '#dee2e6' },
      },
      pieSliceText: 'none',
      height: 300,
    };

    const chart = new google.visualization.PieChart(document.getElementById(`${Connectors.TRANSLATE}_chart`));
    chart.draw(data, options);
  }

  getFillerColor(billingStats: any): string {
    const lowRange = (billingStats.total / 100) * 33;
    const midRange = lowRange * 2;
    if (billingStats.remaining <= lowRange) return '#dc3545';
    else if (billingStats.remaining > lowRange && billingStats.remaining <= midRange) return '#ffc107';
    else return '#28a745';
  }

  modalSupport() {
    const modalRef = this.modalService.open(SupportModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.support = { requestType: 'Quota Increase' };
  }

  modalUserSettings() {
    const modalRef = this.modalService.open(UserSettingsModalComponent, {
      size: 'xl',
    });
    modalRef.componentInstance.settingsMenu = 'email';
  }
}
