import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DesignService } from 'src/app/services/design.service';

import { Chatbot } from '../../../classes/chatbot';

@Component({
  selector: 'app-chat-settings-modal',
  templateUrl: './chat-settings-modal.component.html',
  styleUrls: ['./chat-settings-modal.component.scss']
})
export class ChatSettingsModalComponent implements OnInit {
  @Input() botSession: Chatbot;

  settingsForm = this.fb.group({
    customData: this.fb.array([]),
    outputAudio: [false],
    translateResult: [false]
  });

  get sessionData() { return this.settingsForm.get('customData') as FormArray }

  settingsSubmitted: boolean = false;
  agentSubscription;
  activeAgent;
  selectedLanguage;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, public designService: DesignService) { }

  ngOnInit() {
    this.settingsForm.patchValue({
      outputAudio: this.botSession.outputAudio,
      translateResult: this.botSession.translateResult,
    });

    this.agentSubscription = this.designService.getSessionAgent().subscribe((response: any) => {
      this.activeAgent = response;
    });

    this.selectedLanguage = this.botSession.sessionLang;

    if (Object.keys(this.botSession.sessionUserData).length) {
      this.processFormArray(this.botSession.sessionUserData);
    }
  }

  changeAgentLang(language) {
    this.selectedLanguage = language;
  }

  processFormArray(formData: any) {
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        this.sessionData.push(this.fb.group(
          { key: key, value: formData[key] }
        ));
      }
    }
  }

  addCustomData() {
    this.sessionData.push(
      this.fb.group({
        key: ['', Validators.required],
        value: ['', Validators.required]
      })
    );
  }

  saveSettings() {
    this.settingsSubmitted = true;
    if (this.settingsForm.invalid) return;

    const userData = this.sessionData.value.reduce((acc, customSetting) => {
      acc[customSetting.key] = customSetting.value;
      return acc;
    }, {});

    const sessionSettings = {
      sessionUserData: userData,
      outputAudio: this.settingsForm.get('outputAudio').value,
      translateResult: this.settingsForm.get('translateResult').value,
      sessionLang: this.selectedLanguage,
    };

    this.activeModal.close();
    this.botSession.restoreChatbot(sessionSettings);
  }

}
