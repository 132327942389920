import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Pagination } from '../../../classes/pagination';
import { TableSortDirective } from '../../../directives/table-sort.directive';
import { DialogService } from '../../../services/dialog.service';
import { RatingModalComponent } from '../../../components/modals/rating-modal/rating-modal.component';

import { DesignService } from '../../../services/design.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CommonService } from 'src/app/services/common.service';
import { RxStompService } from '@stomp/ng2-stompjs';
import { first } from 'rxjs/operators';

declare let window: any;

@Component({
  selector: 'conversational-history',
  templateUrl: './conversational-history.component.html',
  styleUrls: ['./conversational-history.component.scss']
})
export class ConversationalHistoryComponent implements OnInit, OnDestroy {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;

  interactionsSubscription: Subscription;
  journeySubscription: Subscription;
  exportSubscription: Subscription;
  interactionsPagination: Pagination = new Pagination();

  pageTarget: number = 1;
  currentPage: number = 1;
  pageSize: number = 10;
  totalPages: number;
  filter: any = {};
  interactions: Array<any> = [];

  constructor(private modalService: NgbModal, private commonService: CommonService, private rxStompService: RxStompService, private dialogService: DialogService, private designService: DesignService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.journeySubscription = this.designService.getSessionJourney().subscribe(() => {
      this.searchInteractions(1);
    });
    this.initFilter();
  }

  ngOnDestroy() {
    this.interactionsSubscription.unsubscribe();
    this.journeySubscription.unsubscribe();
    if (this.exportSubscription instanceof Subscription) this.exportSubscription.unsubscribe();
  }

  private initFilter() {
    const today = new Date();
    this.filter = {
        fromDate: {
          day: today.getDate(),
          month: today.getMonth(),
          year: today.getFullYear()
        },
        toDate: {
          day: today.getDate(),
          month: today.getMonth() + 1,
          year: today.getFullYear()
        },
    }
  }

  private subscribeToWebsocket() {
    this.rxStompService.watch('/user/resultExportHistory').pipe(first()).subscribe((response: any) => {
      this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', {
        type: 'success',
      });

      const message = JSON.parse(response.body);
      
      const byteString = atob(message.payload);
      
      const arrayBuffer = new ArrayBuffer(byteString.length);
      let integersArray = new Uint8Array(arrayBuffer);

          for (var i = 0; i < byteString.length; i++) {
              integersArray[i] = byteString.charCodeAt(i);
          }

      this.commonService.downloadFile(arrayBuffer, 'blob', 'Export History.xlsx');
    });
  }

  exportHistory(){
    const startDate = this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null;
    const endDate = this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null;
     
    const data = {
       startDate,
       endDate,
       agentLang: this.designService.sessionAgentLang,
       journeyApiKey: this.designService.sessionJourney.journey.apiKey,
     };
    this.rxStompService.publish({ destination: '/app/exportHistory', body: JSON.stringify(data) });
    this.subscribeToWebsocket();
  }

  updateDate($event) {
    if ($event.type === 'from') this.filter.fromDate = $event.date;
    if ($event.type === 'to') this.filter.toDate = $event.date;
  }

  viewConversation(sessionId: string) {
    const modalRef = this.modalService.open(RatingModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.sessionId = sessionId;
  }

  searchInteractions(pageSelected: number) {
    this.interactionsPagination.onSelectPage(pageSelected);
    
    const params = {
      sessionId: this.filter.sessionId,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      dfIntentName: this.filter.intent || null,
      userQuery: this.filter.userQuery || null,
      sortBy: this.filter.sortBy || null
    };

    this.interactionsSubscription = this.dialogService.getInteractionHistory(params, this.interactionsPagination.getPageIndex(), this.interactionsPagination.pageSize).subscribe((response: any) => {
      this.interactions = response.data;
      this.interactionsPagination.updateTotals(response.totalElements);
    });
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchInteractions(this.interactionsPagination.currentPage);
  }

}
