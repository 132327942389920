import { Component, Input, EventEmitter } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-market-detail-modal',
  templateUrl: './market-detail-modal.component.html',
  styleUrls: ['./market-detail-modal.component.scss']
})
export class MarketDetailModalComponent {
  @Input() item: any;
  @Input() onBuyItem = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal) { }

  buyItem() {
    this.activeModal.close();
    this.onBuyItem.emit();
  }

  vendorLink(link: string) {
    window.open(link);
  }

}
