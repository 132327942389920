
import { Edge, Node, ClusterNode } from '@swimlane/ngx-graph';
import { Subject } from 'rxjs';

export class IntentFlow {
    items: Object;

    nodes: Array<Node> = [];
    links: Array<Edge> = [];
    clusters: Array<ClusterNode> = [];
    flowIds: Array<Array<string>> = [];
    layoutSettings = { orientation: 'TB' };

    zoomToFit$: Subject<boolean> = new Subject();
    update$: Subject<boolean> = new Subject();
    center$: Subject<boolean> = new Subject();

    constructor(data: Object) {
        this.items = data;
        this.initChart();
    }

    initChart() {
        Object.keys(this.items).forEach((flowKey: string, flowIndex: number) => {
            this.flowIds.push([]);
            this.drawFlow(this.items[flowKey], flowIndex);

            this.addCluster(`cluster_${this.items[flowKey]}`, `Flow id ${this.items[flowKey]}`, this.flowIds[flowIndex]);
        });

        this.updateChart();
    }

    drawFlow(items: Array<any>, flowIndex: number) {
        items.forEach((item: any, itemIndex: number) => {
            this.addNode(item, flowIndex, itemIndex);
        });
    }

    addNode(item: any, flowIndex: number, itemIndex: number) {
        this.nodes.push({
            id: item.id.toString(),
            label: item.name,
            data: item
        });
        this.flowIds[flowIndex].push(item.id.toString());

        if (item.childs && item.childs.length) {
            item.childs.forEach(childItem => {
                this.addLink(item.id.toString(), childItem.toString());
            });
        }
    }

    addLink(spurceId: string, targetId: string) {
        this.links.push({
            source: spurceId,
            target: targetId
        });
    }

    addCluster(clusterId: string, clusterLabel: string, childIds: Array<string>) {
        this.clusters.push({
            id: clusterId,
            label: clusterLabel,
            childNodeIds: childIds
        });
    }

    resetChart() {
        this.nodes = [];
        this.links = [];
        this.clusters = [];
        this.flowIds = [];

        this.initChart();
    }

    fitChart() {
        this.zoomToFit$.next(true);
    }

    updateChart() {
        this.update$.next(true);
    }

    centerChart() {
        this.center$.next(true);
    }

}
