import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportModalComponent } from 'src/app/components/modals/support-modal/support-modal.component';
import { ChannelsService } from 'src/app/services/channels.service';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-liveperson-item-modal',
  templateUrl: './liveperson-item-modal.component.html',
  styleUrls: ['./liveperson-item-modal.component.scss']
})
export class LivepersonItemModalComponent implements OnInit {
  @Input() channel: any = {};
  @Output() onSuccessSaved = new EventEmitter<any>();

  livepersonForm = this.fb.group({
    accountId: this.fb.control('', Validators.required),
    livepersonAgents: this.fb.array([]),
    transferToAgent: this.fb.control(false),
    changeAgentAction: this.fb.control(''),
    defaultAgentName: this.fb.control(''),
    topicAgentArray: this.fb.array([])
  }, {
    validators: this.duplicateAgents
  });
  
  get accountId() {return this.livepersonForm.get('accountId') as FormControl}
  get livepersonAgents() {return this.livepersonForm.get('livepersonAgents') as FormArray}
  get transferToAgent() {return this.livepersonForm.get('transferToAgent') as FormControl}
  get changeAgentAction() {return this.livepersonForm.get('changeAgentAction') as FormControl}
  get topicAgentArray() {return this.livepersonForm.get('topicAgentArray') as FormArray}
  get defaultAgentName() {return this.livepersonForm.get('defaultAgentName') as FormArray}
  get topicAgentField() {
    return this.fb.group({
      agent: this.fb.control('', Validators.required),
      topic: this.fb.control('', Validators.required)
    })
  }
  get livePersonAgentField() {
    return this.fb.group({
      username: this.fb.control('', Validators.required),
      appKey: this.fb.control('', Validators.required),
      secret: this.fb.control('', Validators.required),
      accessToken: this.fb.control('', Validators.required),
      accessTokenSecret: this.fb.control('', Validators.required),
    })
  }

  formSubmitted = false;
  subscriptions = {};

  constructor(private activeModal: NgbActiveModal, public commonService: CommonService, private channelsService: ChannelsService, private fb: FormBuilder, private notificationService: NotificationService, private modalService: NgbModal) { }

  ngOnInit() {
    this.livepersonForm.patchValue(this.channel);
    if (this.channel.agentsMapping) {
      Object.keys(this.channel.agentsMapping).forEach(agent => {
        const topicAgentField = this.topicAgentField;
        topicAgentField.patchValue({
          agent,
          topic: this.channel.agentsMapping[agent].join(',')
        })
        this.topicAgentArray.push(topicAgentField);
      })
    }
    this.transferToAgent.patchValue(this.changeAgentAction.value != null);
    if (this.channel.livePersonAgents && this.channel.livePersonAgents.length > 0) {
      this.channel.livePersonAgents.forEach(agent => {
        const agentField = this.livePersonAgentField;
        agentField.patchValue(agent);
        this.livepersonAgents.push(agentField);
      })
    }
  }

  closeModal(): void {
    this.activeModal.close(false);
  }

  saveChanges() {
    this.formSubmitted = true;
    if (this.livepersonForm.invalid) return;

    if (!this.channel.id) {
      this.subscriptions['LivepersonChannel'] = this.channelsService.createChannel('liveperson', this.generateRequest()).subscribe((data) => {
          this.onSuccessSaved.emit(data);
          this.closeModal();
        },
        (error) => this.manageAgentError(error)
      );
    } else {
      this.subscriptions['LivepersonChannel'] = this.channelsService.updateChannel('liveperson', this.generateRequest()).subscribe((data) => {
          this.onSuccessSaved.emit(data);
          this.closeModal();
        },
        (error) => this.manageAgentError(error)
      );
    }
  }

  addTopicAgentField() {
    this.topicAgentArray.push(this.topicAgentField);
  }

  removeTopicAgent(index) {
    this.topicAgentArray.removeAt(index);
  }

  drop(event: CdkDragDrop<string[]>) {
    const actionsArray = this.topicAgentArray.value;
    moveItemInArray(actionsArray, event.previousIndex, event.currentIndex);
    this.topicAgentArray.patchValue(actionsArray);
  }

  removeAgent(index) {
    this.livepersonAgents.removeAt(index);
  }
  addAgent() {
    this.livepersonAgents.push(this.livePersonAgentField);
  }

  private generateRequest() {
    const request = {...this.channel,
      ...{
        accountId: this.accountId.value,
        changeAgentAction: this.changeAgentAction.value || null,
        defaultAgentName: this.defaultAgentName.value,
        livePersonAgents: this.livepersonAgents.value,
        agentsMapping: {},
        enabled: true
      },
    }
    this.topicAgentArray.value.forEach(el => {
      request.agentsMapping[el.agent] = el.topic.split(',').map(topic => topic.trim());
    })
    return request;
  }

  
  private duplicateAgents(control: AbstractControl): ValidationErrors | null {
    const countObj = {};
    (control.get('topicAgentArray') as FormArray).controls.forEach(
      topicAgentField => {
        let countAgent = countObj[topicAgentField.get('agent').value];
        countObj[topicAgentField.get('agent').value] = countAgent ? countAgent + 1 : 1;
      }
    )
    const findDuplicate = Object.keys(countObj).find(key => countObj[key] > 1);
    return findDuplicate ? {duplicateAgents: true} : null
  }

  private manageAgentError({ status, error }) {
    if (status === 502) {
      this.notificationService
        .openModal({
          title: 'ERROR',
          message: 'The configuration is saved, but something went wrong during the start agents operation. Retry saving the configuration.',
          type: 'danger',
          choice: 'support',
        })
        .subscribe(async (confirm: boolean) => {
          if (!confirm) return;
          this.modalService.open(SupportModalComponent, {
            size: 'lg'
          });
        });
      this.onSuccessSaved.emit(error);
      this.closeModal();
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach(key => {
      this.subscriptions[key].unsubscribe();
    })
  }

}
