import { AbstractControl, FormArray, ValidationErrors } from '@angular/forms';

export function promptsRequiredValidator(formGroup: AbstractControl): ValidationErrors | null {
  if (formGroup.value.required) {
    return formGroup.value.prompts.length === 0
      ? {
          promptsRequired: true,
        }
      : null;
  }
  return null;
}

export function uniqueValidator(formControl: AbstractControl): ValidationErrors | null {
  if (!formControl.parent || !formControl.value) return null;
  const formControlName = Object.keys(formControl.parent.controls).find((key) => formControl.parent.controls[key] === formControl);
  const formArray = formControl.parent.parent as FormArray;
  const duplicates = formArray.controls.filter((formGroup) => formGroup !== formControl.parent && formGroup.value[formControlName] === formControl.value).length;

  const prevValue = formArray.controls.find((formGroup) => formGroup === formControl.parent).value[formControlName];
  const prevDuplicates = formArray.controls.filter((formGroup) => formGroup !== formControl.parent && formGroup.value[formControlName] === prevValue);
  if (prevDuplicates.length > 0) {
    setTimeoutFunc(prevDuplicates.map((formGroup) => formGroup.get(formControlName)));
  }
  return duplicates ? { duplicateName: true } : null;
}

const setTimeoutFunc = (invalidateControls: AbstractControl[]) => {
  const timeOut = setTimeout(() => {
    invalidateControls.forEach((t) => t.updateValueAndValidity());
    clearTimeout(timeOut);
  }, 200);
};
