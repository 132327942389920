import { Component, OnInit } from '@angular/core';

import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Subscription } from 'rxjs';

import { Pagination } from 'src/app/classes/pagination';
import { CommonService } from 'src/app/services/common.service';
import { DesignService } from 'src/app/services/design.service';
import { MonitoringService } from 'src/app/services/monitoring.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ExportLogsModalComponent } from '../../modals/export-logs-modal/export-logs-modal.component';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class LogsComponent implements OnInit {
  subscriptions: Object = {};
  pagination: Pagination = new Pagination();
  connectors: Array<string> = [];
  severities: Array<string> = ['DEBUG', 'INFO', 'WARNING', 'ERROR'];

  yesterday = new Date( new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1 );
  filter: any = {
    connectors: [],
    severity: [],
    startDate: new NgbDate(this.yesterday.getFullYear(), this.yesterday.getMonth() + 1, this.yesterday.getDate()),
    endDate: new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()),
  };

  logs: Array<any> = [];

  constructor(private designService: DesignService, private monitoringService: MonitoringService, private notificationService: NotificationService, private modalService: NgbModal, private rxStompService: RxStompService, private commonService: CommonService) {}

  ngOnInit() {
    this.subscriptions['Connectors'] = this.monitoringService.getLogsConnectors().subscribe((connectors: Array<any>) => {
      this.connectors = connectors;
    });

    this.subscriptions['Journey'] = this.designService.getSessionJourney().subscribe(() => {
      this.searchLogs(1);
    });

    if (!(this.subscriptions['ExportLogs'] instanceof Subscription)) {     
      this.subscriptions['ExportLogs'] = this.rxStompService.watch('/user/resultExportLog').subscribe((response: any) => {
        this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', { type: 'success' });
        
        const byteString = atob(response.body);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        let integersArray = new Uint8Array(arrayBuffer);

        for (var i = 0; i < byteString.length; i++) {
            integersArray[i] = byteString.charCodeAt(i);
        }

        this.commonService.downloadFile(arrayBuffer, 'blob', 'Logs.csv');
      });
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  searchLogs(pageSelected: number) {
    this.pagination.onSelectPage(pageSelected);

    const params = {...this.filter,
      startDate: this.filter.startDate ? new Date(this.filter.startDate.year, this.filter.startDate.month - 1, this.filter.startDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.endDate ? new Date(this.filter.endDate.year, this.filter.endDate.month - 1, this.filter.endDate.day, 23, 59, 59).getTime() : null
    };

    this.subscriptions['LogsSubscription'] = this.monitoringService.getLogs(params, this.pagination.getPageIndex(), this.pagination.pageSize).subscribe((response: any) => {
      this.logs = response;
    });
  }

  isSelected(itemName: string, type: 'connectors' | 'severity'): boolean {
    return (this.filter[type].indexOf(itemName) !== -1);
  }

  selectItem(itemName: string, type: 'connectors' | 'severity') {
    if (this.isSelected(itemName, type)) {
      this.filter[type] = [...this.filter[type].filter(item => item !== itemName)];
    } else {
      this.filter[type].push(itemName);
    }
  }

  selectAllItem(type: 'connectors' | 'severity') {
    const items = (type === 'connectors') ? this.connectors : this.severities;

    if (this.filter[type].length === items.length) {
      this.filter[type] = [];
    } else {
      this.filter[type] = [...items];
    }
  }

  updateDate($event) {
    if ($event.type === 'from') this.filter.startDate = $event.date;
    if ($event.type === 'to') this.filter.endDate = $event.date;
    if (this.filter.startDate && this.filter.endDate) this.searchLogs(1);
  }

  resetFilters() {
    this.filter = {
      connectors: [],
      severity: [],
      startDate: new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() - 1),
      endDate: new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate())
    };
    this.searchLogs(1);
  }

  nextPage() {
    this.searchLogs(this.pagination.currentPage + 1);
  }

  exportLogs() {
    this.notificationService.openModal({
      title: 'Export Log',
      message: 'You can export a maximum of 10000 logs in CSV format. When the export has finished, the download will start automatically and you will be notified.',
      type: 'warning',
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (confirm) {
        const data = {
          startDate: this.filter.startDate ? new Date(this.filter.startDate.year, this.filter.startDate.month - 1, this.filter.startDate.day, 0, 0, 0).getTime() : null,
          endDate: this.filter.endDate ? new Date(this.filter.endDate.year, this.filter.endDate.month - 1, this.filter.endDate.day, 23, 59, 59).getTime() : null,
          journeyApiKey: this.designService.sessionJourney.journey.apiKey
        };

        this.rxStompService.publish({
          destination: '/app/executeExportLog',
          body: JSON.stringify(data)
        });
      }
    })
  }

  exportModal() {
    const modalRef = this.modalService.open(ExportLogsModalComponent);

    modalRef.componentInstance.exportFitler = {...this.filter,
      startDate: this.filter.startDate ? new Date(this.filter.startDate.year, this.filter.startDate.month - 1, this.filter.startDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.endDate ? new Date(this.filter.endDate.year, this.filter.endDate.month - 1, this.filter.endDate.day, 23, 59, 59).getTime() : null
    };
  }

}
