import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { Subject, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IntentService } from '../../../services/intent.service';
import { DesignService } from '../../../services/design.service';
import { CommonService } from '../../../services/common.service';
import { IntentFlow } from '../../../classes/intent-flow';
import { IntentModalComponent } from '../../modals/intent-modal/intent-modal.component';
import { NotificationService } from '../../../services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { tutorials } from 'src/app/enums/tutorial.enum';
import { CreateIntentsTutorialConversationFlowService } from 'src/app/services/tutorials/create-intents-tutorial.conversation-flow.service';

@Component({
  selector: 'app-conversation-flow',
  templateUrl: './conversation-flow.component.html',
  styleUrls: ['./conversation-flow.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConversationFlowComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('intentName') intentName: QueryList<ElementRef>;
  @ViewChild('ngxGraph',{static:false}) ngxGraph: any;

  subscriptions: Object = {};
  activeSidebar: boolean = false;
  activeIntent: any;
  chartFlow: IntentFlow;
  panToNode$	: Subject<any> = new Subject();

  filter: any = {};
  autoZoom = true;
  intentForm: FormGroup = this.getFormGroups();
  intentSubmitted: boolean = false;
  zoomLevel = 1
  get contextsIn() { return this.intentForm.get('inputContexts') as FormArray }
  get contextsOut() { return this.intentForm.get('outputContexts') as FormArray }


  constructor(
    private tutorial: CreateIntentsTutorialConversationFlowService,
    private fb: FormBuilder,
    private commonService: CommonService,
    private designService: DesignService,
    private intentService: IntentService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) {}

  
  ngOnInit() {
    this.subscriptions['tutorialSubscription'] = this.route.queryParams.subscribe(({ tutorial }) => {
      if (tutorial === tutorials.CREATE_INTENTS) {
        this.tutorial.startTutorial(this);
      }
    })
  
    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      if (this.subscriptions['AgentLanguage'] instanceof Subscription) this.subscriptions['AgentLanguage'].unsubscribe();
      
      this.subscriptions['AgentLanguage'] = this.designService.getSessionAgentLang().subscribe(() => {
        this.getFlows();
      });
    });

    this.subscriptions['ToggleMenu'] = this.commonService.onStretchMenu().subscribe(() => {
      setTimeout(() => {
        this.chartFlow.resetChart();
      }, 250);
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.subscriptions['EllipsisName'] = this.intentName.changes.subscribe((elements: QueryList<ElementRef>) => {
      elements.forEach((element: ElementRef) => {
        if (element.nativeElement.offsetWidth < element.nativeElement.scrollWidth) {
          element.nativeElement.classList.add('ellipses');
        }
      })
    })
  }

  getFlows() {

      this.subscriptions['IntentFlows'] = this.intentService.getIntentFlows().subscribe((response: any) => {
        this.chartFlow = new IntentFlow(response.flow);
      });
   
  }

  getIntent(id: number) {
    this.subscriptions['IntentSubscription'] = this.intentService.getIntent(id).subscribe((response: any) => {
      this.activeIntent = response;
      this.intentForm.patchValue({
        id: response.id,
        dialogflowId: response.dialogflowId,
        name: response.name,
        language: response.language,
        action: response.action,
        events: response.events.toString()
      });
      this.fillContexts(response);
    });
  }

  preamblePrahase(intent: any): string {
    if (!intent.phrases[0]) return '';
    
    let preamble: string = '';
    intent.phrases[0].parts.forEach(part => preamble += part.text);
    if (preamble.length > 50) return `${preamble.slice(0, 50)}...`;
    else return preamble;
  }

  selectIntent(intent: any) {
    this.activeSidebar = true;

    this.intentForm = this.getFormGroups();
    this.getIntent(intent.id);
  }

  getFormGroups(): FormGroup {
    return this.fb.group({
      id: [''],
      dialogflowId: [''],
      name: [''],
      language: [''],
      action: [''],
      events: [''],
      inputContexts: this.fb.array([]),
      outputContexts: this.fb.array([]),
      phrases: this.fb.array([]),
      parameters: this.fb.array([]),
      webhookState: [false]
    });
  }

  fillContexts(intent: any) {
    intent.inputContexts.forEach((context: any) => {
      this.contextsIn.push(
        this.fb.group({ name: context.name })
      );
    });
    intent.outputContexts.forEach((context: any) => {
      this.contextsOut.push(
        this.fb.group({ name: context.name, lifespan: context.lifespan })
      );
    });
  }

  saveIntent() {
    this.intentSubmitted = true;
    if (this.intentForm.invalid) return;

    const data = {...this.intentForm.value,
      events: this.intentForm.value.events ? [this.intentForm.value.events] : []
    }
    this.subscriptions['IntentSubscription'] = this.intentService.updateIntent(data).subscribe(() => {
      this.getFlows();
    });
  }

  editIntent(intentId: number) {
    this.subscriptions['EditIntent'] = this.intentService.getIntent(intentId).subscribe((response: any) => {
      this.intentModal(response);
    });
  }

  deleteIntent(intent: any) {
    this.subscriptions['ConfirmDelete'] = this.notificationService.openModal({
      title: 'Confirm delete Intent',
      message: `Are you sure to delete Intent <strong>${intent.name}</strong>`,
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.subscriptions['IntentSubscription'] = this.intentService.deleteIntent(intent.id).subscribe(() => {
        this.activeSidebar = false;
        this.getFlows();
        this.notificationService.showToast('Intent correctly deleted', { type: 'success' });
      });
    })
  }

  intentModal(intent?: any) {
    const modalRef = this.modalService.open(IntentModalComponent, {
      size: 'xl'
    });
    if (intent) modalRef.componentInstance.intent = intent;

    modalRef.componentInstance.onSaveIntent.subscribe(async () => {
      this.getFlows();
      if (this.tutorial) this.tutorial.actions.next(true);
    });
  }

}



