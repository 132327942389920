import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { Subject } from 'rxjs';
import { ExitButton, NextButton, BackButton } from 'src/app/classes/tutorials/buttons.tutorial';
import { Step } from 'src/app/classes/tutorials/step.tutorial';
import { ConversationFlowComponent } from 'src/app/components/design/conversation-flow/conversation-flow.component';
import { tutorials } from 'src/app/enums/tutorial.enum';

export enum CREATE_INTENTS_TUTORIAL_SEQUENCE {
  FIRST = "creatIntentsFirst",
  SECOND = "creatIntentsSecond",
  THIRD = "creatIntentsThird",
  FOURTH = "creatIntentsFourth",
  FIFTH = "creatIntentsFifth",
  SIXTH = "creatIntentsSixth"
}

@Injectable({
  providedIn: 'root',
})
export class CreateIntentsTutorialConversationFlowService {
  public actions = new Subject();
  private component: ConversationFlowComponent;
  private exitCallback;

  constructor(private shepherdService: ShepherdService, private router: Router) {
    this.shepherdService.modal = true;
    this.exitCallback = () => {
      this.router.navigate(['/conversation-flow']);
      this.shepherdService.cancel();
    };

    this.actions.subscribe(() => {
      if (this.shepherdService.tourName === CREATE_INTENTS_TUTORIAL_SEQUENCE.SECOND) {
        const intervalRef = setInterval(() => {
          if (this.component.subscriptions['IntentFlows'].closed) {
            clearInterval(intervalRef);
            this.selectNodeTutorial();
          }
        }, 200);
        
      } else if(this.shepherdService.tourName === CREATE_INTENTS_TUTORIAL_SEQUENCE.FOURTH) {
        this.changeSectionTutorial();
      }
    });
  }

  startTutorial(component) {
    this.component = component;
    this.shepherdService.tourName = CREATE_INTENTS_TUTORIAL_SEQUENCE.FIRST;

    this.shepherdService.addSteps([
      new Step({
        attachTo: {
          element: '#intentsDesignMenu',
          on: 'right',
        },
        canClickTarget: false,
        title: 'Intents design',
        text: 'Welcome to the "intents design" section, here you can view the agent flow and define new intents.',
        buttons: [new ExitButton(this.exitCallback), new NextButton()],
      }),
      new Step({
        attachTo: {
          element: '#newIntentButton',
          on: 'top',
        },
        advanceOn: { selector: '#newIntentButton', event: 'click' },
        padding: 5,
        title: 'New Intent',
        text: 'Click on "new intent" to create a new intent.',
        buttons: [
          new ExitButton(this.exitCallback),
          new BackButton(),
          new NextButton(() => {
            this.component.intentModal();
            this.shepherdService.complete();
          }),
        ],
      }),
    ]);

    this.shepherdService.start();
  }

  selectNodeTutorial() {
    if (this.shepherdService.isActive) {
      this.shepherdService.complete();
    }
    this.shepherdService.tourName = CREATE_INTENTS_TUTORIAL_SEQUENCE.THIRD;

    const idNewNode: any = this.component.chartFlow.nodes.reduce((maxId, n) => (Number(n.id) > maxId ? n.id : maxId), 0);
    this.shepherdService.addSteps([
      new Step({
        attachTo: {
          element: `#node-${idNewNode}`,
          on: 'auto',
        },
        padding: 5,
        title: `Intent's preview`,
        text: 'Click on "edit" to open the intent configuration modal to insert the training phrases and connect the entities to them.',
        buttons: [
          new ExitButton(this.exitCallback),
          new NextButton(() => {
            this.component.editIntent(idNewNode);
            this.shepherdService.next();
          }),
        ],
      }),
    ]);

    this.shepherdService.start();
  }

  changeSectionTutorial() {
    if (this.shepherdService.isActive) {
      this.shepherdService.complete();
    }
    this.shepherdService.addSteps([
      new Step({
        attachTo: {
          element: `#intentsSettingsMenu`,
          on: 'right',
        },
        advanceOn: { selector: '#intentsSettingsMenu', event: 'click' },
        title: `Intents settings`,
        text: 'Go to the "Intents settings" section to add the response that the agent will have to give when the intent is triggered.',
        buttons: [new ExitButton(this.exitCallback), new NextButton()],
        when: {
          hide: () => {
            this.router.navigate(['intent-settings'], { queryParams: { tutorial: tutorials.CREATE_INTENTS, tab: 'parameters' } });
          },
        },
      }),

      new Step({
        attachTo: {
          element: `#intentsSettingsMenu`,
          on: 'right',
        },
        showOn: () => false,

        title: `Intents settings`,
        text: 'Go to the "Intents settings" section to add the response that the agent will have to give when the intent is triggered.',
        buttons: [],
      }),
    ]);
    this.shepherdService.start();
  }
}
