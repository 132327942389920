import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getStatistics(startDate: number, endDate: number, training) {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    if (training) httpOptions.params = httpOptions.params.append('training', training);

    return this.http.get(`${environment.API.orchestrator_analysis}/home/analytics/conversations`, httpOptions);
  }

  getConversationsStatistics(startDate: number, endDate: number, tags?: string) {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
        .set('fillEmptyDate', 'true')
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);

    return this.http.get(`${environment.API.orchestrator_analysis}/home/generic/conversations`, httpOptions);
  }

  getInteractionsAverageDuration(startDate: number, endDate: number, tags?: string) {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);

    return this.http.get(`${environment.API.orchestrator_analysis}/home/duration/conversations`, httpOptions);
  }

  getInteractionsAverageSession(startDate: number, endDate: number, tags?: string) {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);

    return this.http.get(`${environment.API.orchestrator_analysis}/home/conversations/messages`, httpOptions);
  }

  getTotalBookmarked(startDate: number, endDate: number) {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };

    return this.http.get(`${environment.API.orchestrator_analysis}/home/total/bookmarked`, httpOptions);
  }

  getTotalNotAnalyzed(startDate: number, endDate:number) {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    }

    return this.http.get(`${environment.API.orchestrator_analysis}/home/total/notAnalyzed`, httpOptions);
  }

  getTotalFallback(startDate: number, endDate:number) {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    }

    return this.http.get(`${environment.API.orchestrator_analysis}/home/total/fallbacks`, httpOptions);
  }

  getPercentageTrustedIntents(startDate: number, endDate:number) {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    }

    return this.http.get(`${environment.API.orchestrator_analysis}/home/percentage/trustedIntents`, httpOptions);
  }

  getPercentageAgentAutonomy(startDate: number, endDate:number) {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    }

    return this.http.get(`${environment.API.orchestrator_analysis}/home/percentage/agentAutonomy`, httpOptions);
  }

  getAgentChannels(startDate: number, endDate:number) {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    }

    return this.http.get(`${environment.API.orchestrator_analysis}/home/percent/agentChannel`, httpOptions);
  }

}
