import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Subscription } from 'rxjs';

import { DesignService } from '../../../../services/design.service';

@Component({
  selector: 'app-data-transfer-connector-modal',
  templateUrl: './data-transfer-connector-modal.component.html',
  styleUrls: ['./data-transfer-connector-modal.component.scss']
})
export class DataTransferConnectorModalComponent implements OnInit, OnDestroy {
  @Input() private journey: any;
  @Input() private connector: any;
  @Output() private onSaveConnector = new EventEmitter<any>();
  
  subscriptions: Object = {};

  datatransferForm = this.fb.group({
    elements: this.fb.array([])
  });
  transferSubmitted: boolean = false;

  get transferElements() { return this.datatransferForm.get('elements') as FormArray }

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private designService: DesignService) { }

  ngOnInit() {
    if (this.connector.id) {
      this.datatransferForm.patchValue({
        elements: this.processActionsArray(this.connector.elements)
      });
    } else {
      this.addAction();
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  addAction() {
    this.transferElements.push(
      this.fb.group({
        path: ['', Validators.required],
        action: ['', Validators.required],
        value: ['', Validators.required]
      })
    );

    if (this.subscriptions['ActionChanges'] instanceof Subscription) this.subscriptions['ActionChanges'].unsubscribe();

    this.transferElements.controls.forEach((element: FormControl, index: number) => {
      this.subscriptions['ActionChanges'] = element.get('action').valueChanges.subscribe((value) => {
        this.transferElements.at(index).get('value').clearValidators();
        if (value === 'context') {
          this.transferElements.at(index).get('value').setValidators([ Validators.required, Validators.pattern('([a-zA-Z0-9_-]+)([.]+)([a-zA-Z0-9_-]+)') ]);
        } else {
          this.transferElements.at(index).get('value').setValidators([Validators.required, Validators.pattern('[^ ]+')]);
        }
        this.transferElements.at(index).get('value').updateValueAndValidity();
      });
    });
  }

  processActionsArray(arrayData: Array<any>): Array<any> {
    let formArray: Array<any> = [];
    if (arrayData.length) {
      arrayData.forEach(element => {
        this.addAction();
        formArray.push({
          path: element.path, action: element.action, value: element.value
        })
      });
    }
    return formArray;
  }

  saveConnector() {
    this.transferSubmitted = true;
    if (this.datatransferForm.invalid) return;

    const data = { ...this.connector, ...this.datatransferForm.value, journeyId: this.journey.id };

    if (this.connector.id) {
      this.subscriptions['SaveConnector'] = this.designService.editConnector('dataTransfer', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    } else {
      this.subscriptions['SaveConnector'] = this.designService.addConnector('dataTransfer', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    }
  }

}
