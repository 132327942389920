import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';

import { Subscription } from 'rxjs';

import { Pagination } from '../../classes/pagination';
import { NotificationService } from '../../services/notification.service';
import { IntentService } from '../../services/intent.service';
import { DesignService } from '../../services/design.service';
import { TableSortDirective } from '../../directives/table-sort.directive';

@Component({
  selector: 'intents-reliability',
  templateUrl: './intents-reliability.component.html',
  styleUrls: ['./intents-reliability.component.scss']
})
export class IntentsReliabilityComponent implements OnInit, OnDestroy {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;
  
  subscriptions: Object = {};
  intentsPagination: Pagination = new Pagination();

  filter: any = {
    tag: '',
    sortBy: 'name:asc'
  };

  intents: Array<any> = [];

  currentRate = 2;

  constructor(private intentService: IntentService, private notificationService: NotificationService, private designService: DesignService) { }

  ngOnInit() {
    this.subscriptions['Journey'] = this.designService.getSessionJourney().subscribe(() => {
      if (this.subscriptions['AgentLanguage'] instanceof Subscription) this.subscriptions['AgentLanguage'].unsubscribe();

      this.subscriptions['AgentLanguage'] = this.designService.getSessionAgentLang().subscribe(() => {
        this.searchIntent(1);
      });
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchIntent(this.intentsPagination.currentPage);
  }

  searchIntent(pageSelected: number) {
    this.intentsPagination.onSelectPage(pageSelected);

    const params = {
      name: this.filter.intentName || null,
      tag: this.filter.tag || null,
      sortBy: this.filter.sortBy || null
    };

    this.subscriptions['Intents'] = this.intentService.getIntentsTags(params, this.intentsPagination.getPageIndex(), this.intentsPagination.pageSize).subscribe((response: any) => {
      this.intents = response.content;
      this.intentsPagination.updateTotals(response.totalElements);
    });
  }

  toggleReliability(event: any, intent: any) {
    event.preventDefault();

    const currentTrusted = this.isTrusted(intent.tags);

    this.notificationService.openModal({
      title: 'Status update',
      message: `Proceeding with this operation the intent will be marked as <strong class="text_brand_alternative">${currentTrusted ? 'UNTRUSTED' : 'TRUSTED'}</strong>, do you want to continue?`,
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;

      let data = {
        id: intent.id,
        tags: intent.tags
      };
      if (currentTrusted) {
        data.tags = intent.tags.filter(tag => tag.name !== 'trusted');
        data.tags.push({name: 'untrusted', type: 'STATUS'});
      } else {
        data.tags = intent.tags.filter(tag => tag.name !== 'untrusted');
        data.tags.push({name: 'trusted', type: 'STATUS'});
      }

      this.subscriptions['UpdateTags'] = this.intentService.changeStatusIntent(data).subscribe((response: any) => {
        this.searchIntent(this.intentsPagination.currentPage);
      });
    });
  }

  isTrusted(tags: Array<any>): boolean {
    return tags.some(tag => tag.name === 'trusted');
  }

  synchIntents() {
    this.notificationService.openModal({
      title: 'Data update',
      message: 'The data update may take few seconds, during which it will not be possible to use the application. Do you want to continue?',
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.subscriptions['SynchIntents'] = this.intentService.synchIntents({}).subscribe((response: any) => {
        this.searchIntent(1);
      });
    });
  }

  resetFilters() {
    this.filter = {
      tag: ''
    };
    this.searchIntent(1);
  }

  isRatingEnhanced(): boolean {
    return this.intents.some(intent => intent.rating > 0);
  }

}
