import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';

import { TagIcons } from '../../enums/tag-icons.enum';
import { Pagination } from '../../classes/pagination';
import { IntentService } from '../../services/intent.service';
import { DesignService } from '../../services/design.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'intent-tags',
  templateUrl: './intent-tags.component.html',
  styleUrls: ['./intent-tags.component.scss']
})
export class IntentTagsComponent implements OnInit, OnDestroy {
  @ViewChild('tagForm', { static: false }) private tagForm: NgForm;

  subscriptions: Object = {};
  intentsPagination: Pagination = new Pagination(15);
  tagIcons = TagIcons;
  sidebarOpened: boolean = false;

  filter: any = {};
  tags: Array<any> = [];
  tagsCategoryGroup: any;
  intents: Array<any> = [];
  intentSelected: any;
  newTag: any;

  tagSuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.intentService.getTagsSuggestions(term).pipe(
          catchError(() => {
            return of([]);
          })
        )
      )
    )

  tagSuggestionFormat = (value: any) => value.name;
  tagSuggestionSelect = (value: any) => value.name;

  chooseTag($event: any) {
    let data = this.intentSelected;
    data.tags.push($event.item);

    this.updateTagsIntent(data);
  }

  categorySuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.intentService.getCategorySuggestions(term).pipe(
          catchError(() => {
            return of([]);
          })
        )
      )
    )

  constructor(private intentService: IntentService, private notificationService: NotificationService, private designService: DesignService) { }

  ngOnInit() {
    this.getTags();

    this.subscriptions['Journey'] = this.designService.getSessionJourney().subscribe(() => {
      this.searchIntent(1);
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getTags() {
    this.subscriptions['Tags'] = this.intentService.getTags({isLogic: false}).subscribe((response: any) => {
      this.tags = response;
    });
  }

  synchIntents() {
    this.notificationService.openModal({
      title: 'Data update',
      message: 'The data update may take few seconds, during which it will not be possible to use the application. Do you want to continue?',
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.subscriptions['SynchIntents'] = this.intentService.synchIntents({}).subscribe(() => {
        this.searchIntent(1);
      });
    });
  }

  updateTagsIntent(intent: any) {
    this.subscriptions['UpdateTagsIntent'] = this.intentService.updateTagsIntent(intent).subscribe(() => {
      this.newTag = null;
      this.searchIntent(this.intentsPagination.currentPage);
    }, () => {
      this.searchIntent(this.intentsPagination.currentPage);
    });
  }

  searchIntent(pageSelected: number) {
    this.intentsPagination.onSelectPage(pageSelected);

    const params = {
      name: this.filter.intentName || null,
      tag: this.filter.tag || null
    };

    this.subscriptions['Intents'] = this.intentService.getIntentsTags(params, this.intentsPagination.getPageIndex(), this.intentsPagination.pageSize).subscribe((response: any) => {
      this.intents = response.content;
      this.intentsPagination.updateTotals(response.totalElements);
      if (this.intentSelected) {
        this.updateIntentSelected();
      }
    });
  }

  selectTag(intent: any) {
    this.sidebarOpened = true;
    this.intentSelected = {};
    Object.assign(this.intentSelected, intent);
    this.tagsCategoryGroup = this.groupCategoriesTag(intent.tags);
  }

  groupCategoriesTag(tags: Array<any>): any {
    const tagsGrouped = tags.reduce((a, b) => {
      if (a[b['type']] === undefined) a[b['type']] = [];
      a[b['type']].push(b);
      return a;
    }, {});

    for (const key in tagsGrouped) {
      if (tagsGrouped.hasOwnProperty(key)) {
        tagsGrouped[key] = tagsGrouped[key].filter(tag => tag.name !== 'untrusted');
        if (tagsGrouped[key].length === 0) delete tagsGrouped[key];
      }
    }
    return tagsGrouped;
  }

  createTag() {
    if (this.tagForm.invalid) return;

    let data = this.intentSelected;
    data.tags.push({...this.newTag,
      logicTag: false
    });

    this.updateTagsIntent(data);
  }

  removeTag(tagName: string) {
    let data = this.intentSelected;
    data.tags = data.tags.filter(tag => tag.name !== tagName);

    this.updateTagsIntent(data);
  }

  resetFilters() {
    this.filter = {};
    this.searchIntent(1);
  }

  closeSidebar() {
    this.sidebarOpened = false;
    this.intentSelected = {};
  }

  private updateIntentSelected() {
    const intentUpdated = this.intents.find(intent => intent.id === this.intentSelected.id)
    if (intentUpdated) {
      Object.assign(this.intentSelected, intentUpdated);
    }
    this.selectTag(this.intentSelected);
  }

  checkIconCategory() {
    if (this.newTag.type && this.newTag.type.toLowerCase() === 'topic') {
      this.newTag.iconId = 'bar_chart';
    }
  }

}
