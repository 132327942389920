import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { NotificationService } from '../../../services/notification.service';

import { IntentService } from '../../../services/intent.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { Languages } from 'src/app/enums/languages.enum';

@Component({
  selector: 'analytics-filter',
  templateUrl: './analytics-filter.component.html',
  styleUrls: ['./analytics-filter.component.scss']
})
export class AnalyticsFilterComponent implements OnInit {
  @Input() filter?: any = {};
  @Output() getStatistics = new EventEmitter<any>();
  @Input() manageVisibility?: { inputSearch: boolean } = { inputSearch: false };
  subscriptions: Object = {};
  tags: Array<any> = [];

  languages_emum = Languages;

  constructor(private intentService: IntentService, private analyticsService: AnalyticsService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.getTags();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getTags() {
    this.subscriptions['Tags'] = this.intentService.getTags({isLogic: false}).subscribe((response: any) => {
      this.tags = response;
    });
  }

  updateDate($event) {
    if ($event.type === 'from') this.filter.period.fromDate = $event.date;
    if ($event.type === 'to') this.filter.period.toDate = $event.date;
  }

  getStats() {
    this.getStatistics.emit();
  }

  exportAnalytics() {
    const params = {
      startDate: new Date(`${this.filter.period.fromDate.year}/${this.filter.period.fromDate.month}/${this.filter.period.fromDate.day}`).getTime(),
      endDate: new Date(`${this.filter.period.toDate.year}/${this.filter.period.toDate.month}/${this.filter.period.toDate.day}`).getTime()
    };
    const daysPeriod = Math.floor( (params.endDate - params.startDate) / 86400 ) / 1000;

    if (daysPeriod > 31) {
      this.notificationService.openModal({
        type: 'warning',
        title: ' Exceeded maximum period',
        message: 'You can select a maximum period of 31 days. Use datepicker at the top of the page to change the time interval.',
        choice: 'single'
      }).subscribe();
      return;
    }

    this.subscriptions['ExportAnalytics'] = this.analyticsService.exportAnalytics(params).subscribe();
  }

}
