import { Component, OnInit } from '@angular/core';
import { MonitoringService } from 'src/app/services/monitoring.service';

@Component({
  selector: 'monitoring-card',
  templateUrl: './monitoring-card.component.html',
  styleUrls: ['./monitoring-card.component.scss']
})
export class MonitoringCardComponent implements OnInit {
  pods = [];

  componentLoaded = false;
  constructor(private monitoringService: MonitoringService) {}

  ngOnInit() {
    this.loadPodStatuses();
  }

  async loadPodStatuses() {
    this.pods.push(await this.getComponentStatus('Chat'));
    this.pods.push(await this.getComponentStatus('Analysis'));
    this.pods.push(await this.getComponentStatus('Configurator'));
    this.pods.push(await this.getComponentStatus('Console'));

    this.componentLoaded = true;
  }
  private async getComponentStatus(name) {
    try {
      let status;
      switch (name) {
        case 'Chat':
          status = await this.monitoringService.getChatStatus().toPromise();
          break;
        case 'Analysis':
          status = await this.monitoringService.getAnalysisStatus().toPromise();
          break;
        case 'Configurator':
          status = await this.monitoringService.getConfiguratorStatus().toPromise();
          break;
        case 'Console':
          status = await this.monitoringService.getConsoleStatus().toPromise();
          break;
      }
      return { ...status, name };
    } catch (error) {
      return {
        name,
        status: 'DOWN',
      };
    }
  }
}