import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { IntentService } from '../../../services/intent.service';
import { DesignService } from '../../../services/design.service';

@Component({
  selector: 'app-faq-analysis-intent-modal',
  templateUrl: './faq-analysis-intent-modal.component.html',
  styleUrls: ['./faq-analysis-intent-modal.component.scss']
})
export class FaqAnalysisIntentModalComponent implements OnInit, OnDestroy {
  @Input('prediction') prediction: any;

  intentSubscription: Subscription;
  intent: any = { parameters: [] };
  multiIntents: Array<any> = [];

  responsesDialogflow: boolean = false;

  faqIntentForm = this.fb.group({
    name: ['', Validators.required],
    inputContexts: this.fb.array([]),
    outputContexts: this.fb.array([]),
    phrases: this.fb.array([]),
    language: [''],
    parameters: this.fb.array([]),
    parametricResponses: this.fb.array([
      this.fb.group({ response: this.fb.array([]) })
    ]),
    webhookState: [false]
  });
  intentSubmitted: boolean = false;

  get contextsIn() { return this.faqIntentForm.get('inputContexts') as FormArray }
  get contextsOut() { return this.faqIntentForm.get('outputContexts') as FormArray }
  get responses() { return this.faqIntentForm.get('parametricResponses') as FormArray }

  get multiSelection() { return Array.isArray(this.prediction) }

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private intentService: IntentService, private designService: DesignService) { }

  ngOnInit() {
    if (this.multiSelection) {
      this.prediction.forEach((prediction: any, index: number) => {
        this.multiIntents.push({
          name: `Faq ${index + 1} ${new Date().getTime()}`,
          inputContexts: [],
          outputContexts: [],
          phrases: [{
            parts: [{ alias: '', entityType: '', text: prediction.question }]
          }],
          language: this.designService.sessionAgentLang,
          parameters: [],
          parametricResponses: [
            { response: [{text: prediction.answer || ''}] }
          ],
          webhookState: false
        });
      });
    } else {
      this.intent = {...this.intent,
        phrases: [{
          parts: [{ alias: '', entityType: '', text: (this.prediction || {}).question }]
        }]
      };
      this.faqIntentForm.get('language').setValue(this.designService.sessionAgentLang);
      this.addTextResponse(0, (this.prediction || {}).answer);
    }
  }

  ngOnDestroy() {
    if (this.intentSubscription instanceof Subscription) this.intentSubscription.unsubscribe();
  }

  addTextResponse(indexResponse: number, text?: string) {
    const responseGroup = this.responses.at(indexResponse).get('response') as FormArray;
    responseGroup.push(
      this.fb.group( {text: [text || '', Validators.required]} )
    );
  }

  getNluPayload(parametricResponses: Array<any>): Array<string> {
    return parametricResponses.reduce((nluPayload: Array<any>, parametricPayload: any) => {
      nluPayload.push({ texts: [parametricPayload.text]});
      return nluPayload;
    }, [])
  }

  saveIntent() {
    if (this.multiSelection) {
      let data = this.multiIntents;
      if (this.responsesDialogflow) {
        data.forEach((intent: any) => {
          intent.messages = this.getNluPayload(intent.parametricResponses[0].response)
        });
      }

      this.intentSubscription = this.intentService.createMultiIntents(data).subscribe(() => {
        this.activeModal.close();
      });
    } else {
      this.intentSubmitted = true;
      if (this.faqIntentForm.invalid) return;
  
      let data = {...this.faqIntentForm.value};
      if (this.responsesDialogflow) {
        data = {...data,
          messages: this.getNluPayload(this.faqIntentForm.value.parametricResponses[0].response)
        }
      }

      this.intentSubscription = this.intentService.createIntent(data).subscribe(() => {
        this.activeModal.close();
      });
    }
  }

}
