import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  onToggleMenu: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  switchMenu(stretchStatus: boolean) {
    this.onToggleMenu.next(stretchStatus);
  }

  onStretchMenu(): Observable<boolean> {
    return this.onToggleMenu.asObservable();
  }

  groupBy(items: Array<any>, groupAttribute: string): any {
    return items.reduce((accumulator, currentValue) => {
      if (currentValue[groupAttribute]) {
        if ( !Array.isArray(accumulator[currentValue[groupAttribute]]) ) accumulator[currentValue[groupAttribute]] = [];
        accumulator[currentValue[groupAttribute]].push(currentValue);
      }
      return accumulator;
    }, {});
  }

  downloadFile(data: any, responseType: string, fileName: string) {
    const blob = new Blob([data], {type: responseType});
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      let link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  copyToClipboard(item: any) {
    item.select();
    document.execCommand('copy');
    item.setSelectionRange(0, 0);
  }

  escapeRegExpChars(string: string | Array<string>): string | Array<string> {
    if (Array.isArray(string)) {
        return string.reduce((accumulator, currentValue) => {
            accumulator.push(currentValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
            return accumulator;
        }, []);
    }
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}
