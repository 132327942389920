import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-report-details-modal',
  templateUrl: './custom-report-details-modal.component.html',
  styleUrls: ['./custom-report-details-modal.component.scss']
})
export class CustomReportDetailsModalComponent {

  @Input() reportId: string;

  constructor(public activeModal: NgbActiveModal) { }

}
