import { Component, Input, OnChanges, OnDestroy, QueryList, SimpleChanges, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

import { DesignService } from '../../../services/design.service';
import { promptsRequiredValidator, uniqueValidator } from './parameters.validator';

@Component({
  selector: 'training-prhases-field',
  templateUrl: './training-prhases-field.component.html',
  styleUrls: ['./training-prhases-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TrainingPrhasesFieldComponent implements OnDestroy, OnChanges {
  @ViewChildren('popEntity') popEntity: QueryList<NgbPopover>;
  @ViewChildren('popParameterEntity') popParameterEntity: QueryList<NgbPopover>;
  @Input('intent') intent: any;
  @Input('intentForm') intentForm: FormGroup;
  @Input() parametersManagement?: boolean = true;
  @Input() entitiesManagement?: boolean = true;
  @Input() intentSubmitted?: boolean = false;
  subscriptions: Object = {};

  entityPopOffset: string;
  private popPhraseIndex: number;
  private popParameterIndex: number;

  onUpdateEntities: Array<Subject<any>> = [];
  entities: Array<any> = [];
  patchedEntities: Array<any> = [];

  get entityParameters() {
    return this.intentForm.get('parameters') as FormArray;
  }
  get trainingPhrases() {
    return this.intentForm.get('phrases') as FormArray;
  }

  getParameterPrompts(index) {
    return this.intentForm.get(['parameters', index, 'prompts']) as FormArray;
  }

  constructor(private fb: FormBuilder, private designService: DesignService) {}

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.intent && changes.intent.currentValue) {
      if (this.entitiesManagement) this.getEntities(this.intent);
      this.fillArrayFields(this.intent);
    }
  }

  getEntities(intent: any) {
    const params = {
      agentLang: intent.language,
    };
    this.subscriptions['Entities'] = this.designService.getEntityNames(params).subscribe((response: any) => {
      this.entities = response;
    });
  }

  patchEntityParameters() {
    let entities = this.entities.reduce((accumulator, currentValue) => {
      (accumulator || []).push({
        entityType: currentValue
      });
      return accumulator;
    }, []);

    this.patchedEntities = [...entities, ...this.entityParameters.value].sort((a, b) => a.entityType.localeCompare(b.entityType));
  }

  fillArrayFields(intent: any) {
    this.entityParameters.clear();
    this.trainingPhrases.clear();

    const processPrompts = (prompts: Array<any>): FormArray => {
      const promptsArray = this.fb.array([]);
      (prompts || []).forEach((prompt) => {
        promptsArray.push(this.fb.control(prompt, Validators.required));
      });
      return promptsArray;
    };
    const processParts = (parts: Array<any>): FormArray => {
      const partsArray = this.fb.array([]);
      parts.forEach((part) => {
        partsArray.push(
          this.fb.group({
            alias: part.alias,
            entityType: part.entityType,
            text: part.text,
          })
        );
      });
      return partsArray;
    }

    intent.parameters.forEach((parameter: any) => {
      this.entityParameters.push(
        this.fb.group(
          {
            id: [parameter.id],
            name: [parameter.name, [Validators.required, uniqueValidator]],
            entityType: [parameter.entityType],
            required: [parameter.required],
            value: [parameter.value, Validators.required],
            prompts: processPrompts(parameter.prompts),
          },
          {
            validators: promptsRequiredValidator,
          }
        )
      );
    });
    intent.phrases.forEach((phrase) => {
      this.trainingPhrases.push(
        this.fb.group({
          id: phrase.id,
          parts: processParts(phrase.parts),
        })
      );
      this.onUpdateEntities.push(new Subject<any>());
    });
  }

  addTrainingPhrase($event) {
    $event.preventDefault();
    if (!$event.target.value) return;

    this.trainingPhrases.push(
      this.fb.group({
        parts: this.fb.array([{ alias: '', entityType: '', text: $event.target.value }]),
      })
    );
    this.onUpdateEntities.push(new Subject<any>());
    $event.target.value = '';
  }

  removeTrainingPhrases(indexPrhase: number) {
    this.trainingPhrases.removeAt(indexPrhase);
  }

  togglePopEntity($event: any, phraseIndex: number) {
    if (!this.entitiesManagement) return;

    this.popPhraseIndex = phraseIndex;
    this.entityPopOffset = `${$event.offset}px`;

    this.patchEntityParameters();

    if (this.popEntity.toArray()[phraseIndex].isOpen()) {
      this.popEntity.toArray()[phraseIndex].close();
    } else {
      this.popEntity.toArray()[phraseIndex].open({ $event });
    }
  }

  setEntity(entity: any | null, popSelection: any) {
    const entityParameter = this.joinParameters(entity);
    this.onUpdateEntities[this.popPhraseIndex].next({
      entity: entityParameter,
      text: popSelection.text,
      alias: popSelection.alias || null,
      entityType: popSelection.entityType || null,
    });

    this.popEntity.toArray()[this.popPhraseIndex].close();
  }

  joinParameters(entity: any): Object {
    if (!entity) return {};

    const obtainParameter = this.entityParameters.value.find((parameter) => parameter.name === entity.name);

    if (!obtainParameter) {
      return this.addParameter(entity.entityType);
    } else {
      return obtainParameter;
    }
  }

  addParameter(entityType = '') {
    const alias: string = this.parseAliasRestriction(entityType);

    const newFormGroup = this.fb.group(
      {
        name: [alias, [Validators.required, uniqueValidator]],
        entityType: [entityType, Validators.required],
        required: [false],
        value: [entityType ? `$${entityType.replace('@', '')}` : '', Validators.required],
        prompts: this.fb.array([]),
      },
      {
        validators: promptsRequiredValidator,
      }
    );
    this.entityParameters.push(newFormGroup);

    return newFormGroup.value;
  }

  parseAliasRestriction(entityType: string): string {
    const replaceRestriction = (text: string): string => {
      return text.replace('@', '').replace('.', '-');
    }

    if (this.entityParameters.value.some((parameter) => parameter.name === replaceRestriction(entityType))) {
      return replaceRestriction(entityType) + new Date().getTime();
    } else {
      return replaceRestriction(entityType);
    }
  }

  togglePopParameterEntity(parameterIndex: number, parameter: FormGroup) {
    if (!this.parametersManagement) return;

    this.popParameterIndex = parameterIndex;

    if (this.popParameterEntity.toArray()[parameterIndex].isOpen()) {
      this.popParameterEntity.toArray()[parameterIndex].close();
    } else {
      this.popParameterEntity.toArray()[parameterIndex].open({ parameter });
    }
  }

  updateEntityParameter(entityType: string, parameter: FormGroup) {
    const alias = this.parseAliasRestriction(entityType);

    if (!parameter.get('name').value) {
      parameter.patchValue({
        name: alias,
        entityType: entityType,
        value: `$${entityType.replace('@', '')}`
      });
    } else {
      parameter.get('entityType').setValue(entityType);
    }

    this.popParameterEntity.toArray()[this.popParameterIndex].close();
  }

  addPrompt(parameterGroup: FormGroup, $event) {
    $event.preventDefault();

    if (!$event.target.value) return;

    (parameterGroup.get('prompts') as FormArray).push(this.fb.control($event.target.value, Validators.required));
    parameterGroup.get('prompts').markAsDirty();
    $event.target.value = '';
  }
}
