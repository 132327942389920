import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../../environments/environment';
import { DesignService } from '../../../services/design.service';
import { NotificationService } from '../../../services/notification.service';
import { IntentService } from '../../../services/intent.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'app-journey-modal',
  templateUrl: './journey-modal.component.html',
  styleUrls: ['./journey-modal.component.scss']
})
export class JourneyModalComponent implements OnInit, OnDestroy {
  @Input() journey?: any;
  @Input() activeTab?: string = 'general';
  @Output() private onSaveJourney = new EventEmitter<any>();
  @Output() private onDeleteJourney = new EventEmitter<any>();
  @ViewChild('fulfillmentForm', { static: false }) private fulfillmentForm: NgForm;

  subscriptions: Object = {};
  journeyForm = this.fb.group({
    id: [''],
    name: ['', Validators.required],
    description: [''],
    status: ['PROCESSING'],
    fulfillment: [false],
    embedded: [''],
    createdDate: [''],
    createdBy: [''],
    lastModifiedDate: ['']
  });
  journeySubmitted: boolean = false;

  agents: Array<any>;

  fulfillmentEditMode: 'import' | 'export' = 'export';
  importedFile: FileList;

  environment: any = environment;
  embeddedBasePath: string = window.location.hostname;
  restCode: string;
  agentUnset = false;

  get agentChanged() {
    return this.fulfillmentForm && this.fulfillmentForm.controls['fulfilmentAgent'] && this.fulfillmentForm.controls['fulfilmentAgent'].dirty && !this.agentUnset;
  }

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private designService: DesignService, private notificationService: NotificationService, private intentService: IntentService, private analyticsService: AnalyticsService, public commonService: CommonService) { }

  ngOnInit() {
    if (this.journey) {
      this.agentUnset = !this.journey.fulfillmentAgent;
      this.subscriptions['Journeys'] = this.designService.getJourney(this.journey.id).subscribe((response: any) => {
        this.journeyForm.patchValue(response);
        this.createEmbeddedCode();
      });
    }

    this.getAgentsBot();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getAgentsBot() {
    this.subscriptions['AgentsBot'] = this.designService.getAgentsBot().subscribe((response: any) => {
      this.agents = response;
    });
  }

  compareFulfilmentAgent(a: any, b: any): boolean {
    return (a && b) ? a.id === b.id : a === b;
  }

  changeStatus($event: any) {
    if ($event.target.checked) this.journeyForm.get('status').setValue('DEPLOYED');
    else this.journeyForm.get('status').setValue('PROCESSING');
  }

  saveJourney() {
    if (this.activeTab === 'general') {
      this.journeySubmitted = true;
      if (this.journeyForm.invalid) return;

      if (this.journey) {
        this.subscriptions['SaveJourney'] = this.designService.editJourney(this.journeyForm.value).subscribe(() => {
          this.activeModal.close();
          this.onSaveJourney.emit();
        });
      } else {
        this.subscriptions['SaveJourney'] = this.designService.createJourney(this.journeyForm.value).subscribe(() => {
          this.activeModal.close();
          this.onSaveJourney.emit();
          this.designService.changesJourneys();
        });
      }
    } else if (this.activeTab === 'fulfillment') {
      if (this.fulfillmentEditMode === 'export') {
        if (this.fulfillmentForm.invalid) return;

        const params = {
          agentId: this.journey.fulfillmentAgent.id,
          journeyId: this.journey.id
        };
        this.subscriptions['SaveJourney'] = this.designService.changeFulfilmentAgent({}, params).subscribe(() => {
          this.activeModal.close();
          this.onSaveJourney.emit();
        });
      } else if (this.fulfillmentEditMode === 'import') {
        this.importIntents();
      }
    }
  }

  deleteJourney() {
    if (this.journeyForm.value.id === this.designService.sessionJourney.journey.id) {
      this.notificationService.openModal({
        title: 'Delete forbidden',
        message: '<div class="alert alert-warning" role="alert">You can\'t delete current active session Journey, switch to another one before delete it.</div>',
        choice: 'single'
      });
      return;
    }

    this.notificationService.openModal({
      title: 'Confirm delete Journey',
      message: 'Are you sure to delete Journey?',
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.subscriptions['DeleteJourney'] = this.designService.deleteJourney(this.journey.id).subscribe(() => {
        this.activeModal.close();
        this.onDeleteJourney.emit();
        this.designService.changesJourneys();
      });
    })
  }

  createEmbeddedCode() {
    this.restCode = `
      {
        "lang": "it",
        "sessionId": UNIQUE_TIMESTAMP,
        "event": { "name": "Welcome" },
        "userData": null
      }`;
  }

  exportIntents() {
    const params = {
      journeyApiKey: this.journey.apiKey
    };
    this.subscriptions['ExportIntents'] = this.intentService.exportIntentsData(params).subscribe();
  }

  uploadIntentsFile(data: FileList) {
    this.importedFile = data;
  }

  importIntents() {
    const params = {
      journeyApiKey: this.journey.apiKey
    };
    this.subscriptions['ImportIntents'] = this.intentService.importIntentsData(this.importedFile, params).subscribe();
  }

  exportHistory() {
    const params = {
      journeyApiKey: this.journey.apiKey
    };
    this.subscriptions['ExportHistory'] = this.analyticsService.exportConversationalHistory(params).subscribe();
  }

}
