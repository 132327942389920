import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DesignService } from '../../services/design.service';
import { ToneOfVoiceSupportModalComponent } from '../modals/tone-of-voice-support-modal/tone-of-voice-support-modal.component';

const skillsValidation: ValidatorFn = (skills: FormArray): ValidationErrors | null => {
  const totalValues = skills.controls.reduce((sumValues, skillGroup: FormGroup) => sumValues + skillGroup.get('value').value, 0);
  if (totalValues > 20) {
    return {
      exceededMaximum: true,
    }
  } else {
    return null
  }
};

@Component({
  selector: 'app-tone-of-voice-survey',
  templateUrl: './tone-of-voice-survey.component.html',
  styleUrls: ['./tone-of-voice-survey.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToneOfVoiceSurveyComponent implements OnInit {
  @ViewChild('chartWrapper', { static: false }) chartSurvey: ElementRef;

  surveyForm: FormGroup = this.getSurveyGroup();
  stepSubmitted: boolean = false;

  subscriptions: Object = {};

  currentStep: number = 0;
  currentQuestion: number = 0;

  tovLanguages: Array<string> = ['it', 'en'];
  tovQuestions: Array<any>;
  tovResults: any = {};
  tovChart: Chart;

  get surveyTypology() { return this.surveyForm.get('typology') as FormGroup; }
  get surveySkills() { return this.surveyForm.get('skills') as FormArray; }
  get surveyQuestions() { return this.surveyForm.get('personality') as FormArray; }

  get completeProgress() {
    const totalQuery = Object.keys(this.surveyTypology.controls).length + this.surveySkills.length + this.surveyQuestions.length;
    const getCompletedQuery = (items: FormGroup | FormArray): number => {
      let validFields = 0;
      if (items instanceof FormGroup) {
        Object.keys(items.controls).forEach((key: string) => {
          if (items.get(key).valid) validFields++;
        });
      } else if (items instanceof FormArray) {
        items.controls.forEach((group: FormGroup) => {
          if (group.get('value').valid) validFields++;
        });
      }
      return validFields;
    }
    const completedQuery = getCompletedQuery(this.surveyTypology) + getCompletedQuery(this.surveySkills) + getCompletedQuery(this.surveyQuestions);
    return (completedQuery * 100) / totalQuery;
  }

  constructor(private fb: FormBuilder, private designService: DesignService, public translateService: TranslateService, private modalService: NgbModal) { }
  
  ngOnInit() {
    this.compileQuestions();
  }

  ngOnDestroy() {
    if (this.tovChart instanceof Chart) this.tovChart.destroy();
    
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  changeLanguage(lang: string) {
    this.translateService.use(lang);
    this.compileQuestions();
  }

  getSurveyGroup(): FormGroup {
    return this.fb.group({
      typology: this.fb.group({
        character: ['', Validators.required],
        personality: ['', Validators.required],
        jobTitle: ['', Validators.required],
        jobDescription: ['', Validators.required],
        age: ['', Validators.required],
        gender: ['', Validators.required]
      }),
      skills: this.fb.array([
        this.fb.group({ key: ['INFORM'], value: ['', Validators.required] }),
        this.fb.group({ key: ['BE_OPERATIONAL'], value: ['', Validators.required] }),
        this.fb.group({ key: ['BE_PROACTIVE'], value: ['', Validators.required] }),
        this.fb.group({ key: ['ENTERTAIN'], value: ['', Validators.required] }),
        this.fb.group({ key: ['USER_INVOLVED'], value: ['', Validators.required] }),
        this.fb.group({ key: ['BRAND_AMBASSADOR'], value: ['', Validators.required] })
      ], { validators: skillsValidation } ),
      personality: this.fb.array([])
    });
  }

  compileQuestions() {
    const lang = this.translateService.currentLang || this.translateService.getDefaultLang();

    this.subscriptions['QuestionsLanguage'] = this.designService.getToneOfVoiceQuestions(lang).subscribe((response: any) => {
      this.tovQuestions = response;
      this.surveyQuestions.clear();

      this.tovQuestions.forEach((question: any) => {
        this.surveyQuestions.push(
          this.fb.group({ category: [question.category], value: ['', Validators.required] })
        )
      });
    });
  }

  nextStep() {
    this.stepSubmitted = true;
    switch (this.currentStep) {
      case 1:
        if (this.surveyTypology.invalid) return;
        break;
      case 2:
        if (this.surveySkills.invalid) return;
        break;
      case 3:
        if (this.surveyQuestions.invalid) return;
        break;
    }
    this.currentStep++;
    this.stepSubmitted = false;
  }

  prevStep() {
    if (this.currentStep === 0) return;
    this.currentStep--;
  }

  nextQuestion(questionNumber?: number) {
    this.isQuestionsFilled();

    if (Number.isInteger(questionNumber)) {
      this.currentQuestion = questionNumber;
    } else if ( (this.currentQuestion + 1) === this.surveyQuestions.length ) {
      this.stepSubmitted = true;
    } else {
      this.currentQuestion++;
    }
  }

  isQuestionsFilled() {
    if ( !this.surveyQuestions.controls.some((question: FormGroup) => question.get('value').invalid) ) {
      this.currentStep = 4;
      this.getResults();
    }
  }

  resetForm() {
    this.surveyForm = this.getSurveyGroup();
    this.compileQuestions();
    this.currentStep = 0;
    this.currentQuestion = 0;
  }

  getResults() {
    this.subscriptions['SurveyResults'] = this.designService.getToneOfVoiceResults({ answers: this.surveyForm.value.personality }).subscribe((response) => {
      this.tovResults = response;
    });

    setTimeout(() => {
      if (this.tovChart instanceof Chart) this.tovChart.destroy();
      this.drawChartSurvey();
    });
  }

  drawChartSurvey() {
    let data = {
      labels: [],
      datasets: [{
        data: [],
        fill: false,
        backgroundColor: '#D50037',
        borderColor: '#D50037'
      }]
    };

    this.surveyForm.value.skills.forEach((skill: any) => {
      data.labels.push(this.translateService.instant('TONE_OF_VOICE.STEP_2.SKILLS.' + skill.key));
      data.datasets[0].data.push(skill.value);
    });

    this.tovChart = new Chart(this.chartSurvey.nativeElement, {
      type: 'radar',
      data: data,
      options: {
        elements: {
          line: {
            borderWidth: 3
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        scales: {
          r: {
              grid: {
                circular: true
              },
              min: 0,
              max: 5,
              ticks: {
                stepSize: 1
              }
          }
        }
      }
    });
  }

  exportResults() {
    const data = {...this.tovResults,
      skills: this.surveySkills.value,
      typology: this.surveyTypology.value
    };
    this.subscriptions['ExportResults'] = this.designService.exportToneOfVoiceResults(data).subscribe();
  }

  importResults(file: FileList) {
    this.subscriptions['ImportResults'] = this.designService.importToneOfVoiceResults(file).subscribe((response) => {
      this.tovResults = response;
      this.surveySkills.patchValue(response.skills);
      this.surveyTypology.patchValue(response.typology);
      this.currentStep = 4;

      setTimeout(() => {
        this.drawChartSurvey();
      });
    });
  }

  modalSupport() {
    this.modalService.open(ToneOfVoiceSupportModalComponent);
  }

}
