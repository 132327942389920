import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { AuthenticationService } from '../../services/authentication.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  @ViewChild('loginForm', {static: false}) private loginForm: NgForm;
  @ViewChild('resetPasswordForm', { static: false }) private resetPasswordForm: NgForm;

  subscriptions: Object = {};

  user: any = {};
  formAction: 'login' | 'reset-password' = 'login';
  wrongCredentials: boolean = false;

  constructor(private authenticationService: AuthenticationService, private notificationService: NotificationService, private router: Router) { }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  signIn() {
    if (this.loginForm.invalid) return;

    this.subscriptions['LoginSubscription'] = this.authenticationService.login(this.user).subscribe(() => {

      this.subscriptions['UserSubscription'] = this.authenticationService.getCurrentUser().subscribe((response: any) => {
        this.router.navigate(['']);
      });
    }, (error) => {
      if (error.status === 401) this.wrongCredentials = true;
    });
  }

  resetPassword() {
    if (this.resetPasswordForm.invalid) return;

    const data = {
      email: this.user.username
    };
    this.subscriptions['ResetPasswordSubscription'] = this.authenticationService.resetPassword(data).subscribe(() => {
      this.notificationService.showToast('Follow link in yuor Mail box for reset password', { type: 'success' });
    });
  }

}
