import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChannelsService } from 'src/app/services/channels.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-facebook-messenger-item-modal',
  templateUrl: './facebook-messenger-item-modal.component.html',
  styleUrls: ['./facebook-messenger-item-modal.component.scss']
})
export class FacebookMessengerItemModalComponent implements OnDestroy {

  @Input() channel: any = {};
  @Output() onSuccessSaved = new EventEmitter<any>();

  subscriptions = {};

  constructor(private activeModal: NgbActiveModal, public commonService: CommonService, private channelsService: ChannelsService) { }

  closeModal(): void {
    this.activeModal.close(false);
  }

  saveChanges() {
    if (!this.channel.id) {
      this.subscriptions['FacebookChannel'] = this.channelsService.createChannel('messenger', {...this.channel, ...{enabled: true}}).subscribe((data) => {
        this.onSuccessSaved.emit(data);
        this.closeModal();
      })
    } else {
      this.subscriptions['FacebookChannel'] = this.channelsService.updateChannel('messenger', {...this.channel, ...{enabled: true}}).subscribe((data) => {
        this.onSuccessSaved.emit(data);
        this.closeModal();
      })
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach(key => {
      this.subscriptions[key].unsubscribe();
    })
  }

}
