import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormArray } from '@angular/forms';

import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DesignService } from '../../../../services/design.service';

@Component({
  selector: 'app-dlp-connector-modal',
  templateUrl: './dlp-connector-modal.component.html',
  styleUrls: ['./dlp-connector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DlpConnectorModalComponent implements OnInit, OnDestroy {
  @Input() private journey: any;
  @Input() private connector: any;
  @Output() private onSaveConnector = new EventEmitter<any>();

  subscriptions: Object = {};
  moveStep: Subject<any> = new Subject<any>();
  infoTypes: Array<any>;
  typesSelected: Array<any> = [];
  infoTypesInvalid: boolean;
  
  dlpForm = this.fb.group({
    steps: this.fb.array([
      this.fb.group({
        toBeEncrypt: ['', Validators.required],
        minChars: [''],
        maskChar: [''],
        skipChars: ['']
      }),
      this.fb.group({
      })
    ])
  });

  get stepsForm() { return this.dlpForm.get('steps') as FormArray }

  get stepOne() { return this.stepsForm.at(0) }
  get stepTwo() { return this.stepsForm.at(1) }

  dlpPhases: any = {
    steps: [
      { name: 'General settings', submitted: false },
      { name: 'Google infoTypes', submitted: false }
    ],
    current: 0
  };

  constructor(public activeModal: NgbActiveModal, private designService: DesignService, private fb: FormBuilder) { }

  ngOnInit() {
    if (this.connector) {
      this.stepsForm.patchValue([
        { toBeEncrypt: this.connector.toBeEncrypt }
      ]);
      if (this.connector.transformations && this.connector.transformations.length) {
        this.stepsForm.patchValue([
          {
            minChars: this.connector.transformations[0].minNumberToEncrypt,
            maskChar: this.connector.transformations[0].maskingChar,
            skipChars: this.connector.transformations[0].charToSkip
          }
        ]);
        this.connector.transformations.forEach(transformation => this.selectInfoType(transformation.infoType));
      }
    }

    this.getInfoTypes();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  nextStep() {
    this.moveStep.next('next');
    if (this.typesSelected.length) {
      this.typesSelected.forEach((transformation: any) => {
        transformation.charToSkip = this.stepOne.value.skipChars;
        transformation.maskingChar = this.stepOne.value.maskChar;
        transformation.minNumberToEncrypt = this.stepOne.value.minChars;
      });
    }
  }

  getInfoTypes() {
    this.subscriptions['InfoTypes'] = this.designService.getDlpInfoTypes().subscribe((response: any) => {
      this.infoTypes = response;
    });
  }

  selectInfoType(infoType: any) {
    if (this.isTypeSelected(infoType)) {
      this.typesSelected = this.typesSelected.filter(selected => selected.infoType.id !== infoType.id);
    } else {
      this.typesSelected.push({
        charToSkip: this.stepOne.value.skipChars,
        infoType: infoType,
        maskingChar: this.stepOne.value.maskChar,
        minNumberToEncrypt: this.stepOne.value.minChars
      });
      this.infoTypesInvalid = false;
    }
  }

  isTypeSelected(infoType: any) {
    return this.typesSelected.some(selected => selected.infoType.id === infoType.id);
  }

  saveConnector($event) {
    if (!this.typesSelected.length) {
      this.infoTypesInvalid = true;
      return;
    }

    delete $event.minChars;
    delete $event.maskChar;
    delete $event.skipChars;
    const data = { ...this.connector, ...$event, journeyId: this.journey.id, transformations: this.typesSelected };

    if (this.connector.id) {
      this.subscriptions['Connector'] = this.designService.editConnector('dlp', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    } else {
      this.subscriptions['Connector'] = this.designService.addConnector('dlp', data).subscribe(() => {
        this.activeModal.close();
        this.onSaveConnector.emit();
      });
    }
  }

}
