import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { DesignService } from '../services/design.service';

@Directive({
  selector: '[journeyRestriction]'
})
export class JourneyRestrictionDirective {
  @Input() journeyRestriction: string;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private designService: DesignService) { }

  ngOnInit() {
    this.designService.getSessionJourney().subscribe(() => {
      if (this.designService.isProductConfigured(this.journeyRestriction)) {
        if (!this.viewContainer.length) this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
