import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RxStompService } from '@stomp/ng2-stompjs';

import { IntentService } from '../../../services/intent.service';
import { DesignService } from '../../../services/design.service';
import { NotificationService } from '../../../services/notification.service';
import { tutorials } from 'src/app/enums/tutorial.enum';
import { CreateIntentsTutorialIntentModalService } from 'src/app/services/tutorials/create-intents-tutorial.intent-modal.service';

@Component({
  selector: 'app-intent-modal',
  templateUrl: './intent-modal.component.html',
  styleUrls: ['./intent-modal.component.scss']
})
export class IntentModalComponent implements OnInit, OnDestroy {
  @ViewChild('importFaqForm', { static: false }) importFaqForm: NgForm;
  @ViewChild('faqAnalysisToast', { static: false }) faqAnalysisToast: TemplateRef<any>;
  @Input() intent?: any;
  @Input() createOption?: 'newIntent' | 'importFaq' = 'newIntent';
  @Output() private onSaveIntent = new EventEmitter<any>();

  subscriptions: Object = {};

  importFaq: any = {};
  importFaqType: 'file' | 'webpage' = 'webpage';
  importedFile;

  intentModalForm = this.fb.group({
    name: ['', Validators.required],
    inputContexts: this.fb.array([]),
    outputContexts: this.fb.array([]),
    language: [''],
    parameters: this.fb.array([]),
    webhookState: [false]
  });
  intentSubmitted: boolean = false;

  get contextsIn() { return this.intentModalForm.get('inputContexts') as FormArray }
  get contextsOut() { return this.intentModalForm.get('outputContexts') as FormArray }

  constructor(private router: Router,private tutorial: CreateIntentsTutorialIntentModalService, public activeModal: NgbActiveModal, private fb: FormBuilder, private intentService: IntentService, private designService: DesignService, private rxStompService: RxStompService, private notificationService: NotificationService,private route: ActivatedRoute) { }

  ngOnInit() {

    this.subscriptions['tutorialSubscription'] = this.route.queryParams.subscribe(({ tutorial }) => {
      if (tutorial === tutorials.CREATE_INTENTS) {
        this.tutorial.startTutorial(this);
      }
    });

    if (!(this.intentService.faqAnalysis instanceof Subscription)) {
      this.intentService.faqAnalysis = this.rxStompService.watch('/user/topic/faq').subscribe(() => {
        this.notificationService.showToast(this.faqAnalysisToast, { type: 'success', autohide: false });
      });
    }

    if (this.intent) {
      this.intentModalForm.addControl('id', this.fb.control(this.intent.id, Validators.required));
      this.intentModalForm.addControl('dialogflowId', this.fb.control(this.intent.dialogflowId, Validators.required));
      this.intentModalForm.addControl('action', this.fb.control(this.intent.action));
      this.intentModalForm.addControl('events', this.fb.control(this.intent.events.toString()));
      this.intentModalForm.addControl('phrases', this.fb.array([]));

      this.intentModalForm.patchValue({
        name: this.intent.name,
        language: this.intent.language,
        webhookState: this.intent.webhookState
      })
      this.fillContexts(this.intent);
    } else {
      this.designService.getSessionAgent().pipe(first()).subscribe(() => {
        this.intentModalForm.patchValue({ language: this.designService.sessionAgent.supportedLang[0] })
      });
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  fillContexts(intent: any) {
    intent.inputContexts.forEach((context: any) => {
      this.contextsIn.push(
        this.fb.group({ name: context.name })
      );
    });
    intent.outputContexts.forEach((context: any) => {
      this.contextsOut.push(
        this.fb.group({ name: context.name, lifespan: context.lifespan })
      );
    });
  }

  saveIntent() {
    this.intentSubmitted = true;
    if (this.intentModalForm.invalid) return;

    const data = {...this.intentModalForm.value,
      events: (this.intentModalForm.value.events) ? [this.intentModalForm.value.events] : []
    };

    if (this.intentModalForm.value.id) {
      this.subscriptions['IntentSubscription'] = this.intentService.updateIntent(data).subscribe(() => {
        this.activeModal.close();
        this.onSaveIntent.emit();
      });
    } else {
      this.subscriptions['IntentSubscription'] = this.intentService.createIntent(data).subscribe(() => {
        this.activeModal.close();
        this.onSaveIntent.emit();
      });
    }
  }

  analyzeFaq() {
    if (this.importFaqForm.invalid || (this.importFaqType === 'file' && !this.importedFile)) return;

    if (this.importFaqType  === 'webpage') {
      this.rxStompService.publish({destination: '/app/faq-extract', body: this.importFaq.pageUrl});
      this.activeModal.close();
    } else if (this.importFaqType  === 'file') {
      this.subscriptions['ExtractFaquFromFile'] = this.intentService.extractFaqFromFile(this.importedFile).subscribe(data => {
        this.router.navigate([`/faq-analysis/${data.id}`]);
        this.activeModal.close();
      })
    }
  }

  previousAnalysis() {
    this.router.navigate(['/faq-analysis']);
    this.activeModal.close();
  }

  uploadIntentsFile(data: FileList) {
    this.importedFile = data;
  }

}
