import { Component, OnInit, OnDestroy, QueryList, ViewChildren, AfterViewInit, ElementRef } from '@angular/core';

import { MonitoringService } from '../../../services/monitoring.service';
import { DesignService } from '../../../services/design.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { Connectors } from 'src/app/enums/connectors.enum';

declare let google: any;
@Component({
  selector: 'requests-card',
  templateUrl: './requests-card.component.html',
  styleUrls: ['./requests-card.component.scss'],
})
export class RequestsCardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('requestsChart') private requestsChart: QueryList<ElementRef>;

  subscriptions: Object = {};
  connectors = Connectors;
  filter: any = {
    connector: Connectors.DIALOGFLOW,
    days: 7,
    startDate: 0,
    endDate: 0,
  };
  totalRequest: number;
  billingRequests: Array<any> = [];

  constructor(private monitoringservice: MonitoringService, private designService: DesignService, private analyticsService: AnalyticsService) {}

  ngOnInit() {
    google.charts.load('current', { packages: ['corechart'] });

    this.subscriptions['JourneySubscription'] = this.designService.getSessionJourney().subscribe(() => {
      this.getRequests();
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.requestsChart.changes.subscribe(() => {
      this.drawSessionsChart();
    });
  }

  onResized() {
    this.drawSessionsChart();
  }

  getTotalRequest(response): any {
    this.totalRequest = response.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.value;
    }, 0);
  }

  getRequests() {
    this.subscriptions['RequestSubscription'] = this.monitoringservice.getRequestStats(this.filter.connector, this.filter.days, this.filter.startDate, this.filter.endDate).subscribe((response: any) => {
      this.billingRequests = response.billingList || [];
      this.getTotalRequest(this.billingRequests);
      if (this.requestsChart.length) this.drawSessionsChart();
    });
  }

  drawSessionsChart() {
    if (this.billingRequests.length) {
      google.charts.setOnLoadCallback(() => {
        let chartData = this.processChartData(this.billingRequests);
        let data = google.visualization.arrayToDataTable(chartData);

        let options = {
          curveType: 'none',
          legend: { position: 'none' },
          hAxis: {
            format: 'dd MMM y',
          },
          vAxis: {
            viewWindow: this.analyticsService.getAxisScale(chartData),
          },
          chartArea: { width: '95%' },
        };

        let chart = new google.visualization.LineChart(document.getElementById('requests_chart'));

        chart.draw(data, options);
      });
    }
  }

  processChartData(data: any): Array<Array<any>> {
    let chartData: Array<Array<any>> = [];
    if (!data) return chartData;

    chartData.push(['Date', 'Sessions']);
    data.forEach((date: any) => {
      chartData.push([new Date(date.startDate), date.value]);
    });

    return chartData;
  }
}
